import React, { useEffect, useState } from "react";

import { Col, Row, Collapse, Table } from "reactstrap";

import Loader from "../../../Loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { utils } from "../../../../utils/utils";

import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import CustomCheckbox from "../../../CustomCheckbox";

const PAGE_NUMBER = 0;
const PAGE_SIZE = 99999;

const OrderAcknowledgmentsSelector = ({
  orderAcknowledgmentsSelected,
  setOrderAcknoledgmentsSelected,
  setContractAmount,
  setJobOrderAcknowledgments,
}) => {
  const [orderAcknowledgments, setOrderAcknoledgments] = useState([]);
  const [jobDetails] = useJobDetails();
  const [orderAcknowledgmentSelected, setOrderAcknoledgmentSelected] =
    useState();
  const [updateContractAmount, setUpdateContractAmount] = useState(false);
  const [loading, setLoading] = useState();
  const [orderTotal, setOrderTotal] = useState();

  const selectOrderAcknoledgment = (orderAcknowledgmentId) => {
    if (orderAcknowledgmentId === orderAcknowledgmentSelected) {
      orderAcknowledgmentId = null;
    }
    setOrderAcknoledgmentSelected(orderAcknowledgmentId);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const orderAcknowledgments = await orderAcknowledgmentApi.getAll({
        jobId: jobDetails.job.id,
        page: PAGE_NUMBER,
        pageSize: PAGE_SIZE,
      });
      const orderAcknowledgmentsFiltered = orderAcknowledgments.data.filter(
        (oa) => !oa.archived
      );
      setOrderAcknoledgments(orderAcknowledgmentsFiltered);
      setJobOrderAcknowledgments(orderAcknowledgmentsFiltered);
      setLoading(false);
    };
    if (jobDetails.job && !orderAcknowledgments.length) {
      fetchData();
    }
  }, [setOrderAcknoledgments, setJobOrderAcknowledgments, jobDetails.job, orderAcknowledgments.length]);

  useEffect(() => {
    const total = orderAcknowledgmentsSelected.reduce(
      (p, orderAcknowledgmentSelected) => {
        const orderAcknowledgmentFound = orderAcknowledgments.find(
          (orderAcknowledgment) =>
            orderAcknowledgment.id === orderAcknowledgmentSelected.id
        );
        return p + (orderAcknowledgmentFound?.amount || 0);
      },
      0
    );
    setOrderTotal(total);
    if (setContractAmount && updateContractAmount) {
      setContractAmount(total);
    }
  }, [
    orderAcknowledgmentsSelected,
    orderAcknowledgments,
    setContractAmount,
    updateContractAmount,
  ]);

  return (
    <Col className="col-12 p-0">
      <Row className="d-flex justify-content-between p-0 m-0 align-items-center">
        {loading ? (
          <div className="w-100 py-3">
            <Loader size="sm" />
          </div>
        ) : orderAcknowledgments && orderAcknowledgments.length ? (
          orderAcknowledgments.map((orderAcknowledgment, index) => (
            <div
              className="d-flex w-100 mt-2"
              key={`sequence-${orderAcknowledgment.id}`}
            >
              <Row className="mr-0 flex-grow-1 overflow-x-auto">
                <Col className="col-1">
                  <div className="d-flex h-100 align-items-center justify-content-center">
                    #{index + 1}
                  </div>
                </Col>
                <Col
                  onClick={() => {
                    selectOrderAcknoledgment(orderAcknowledgment?.id);
                  }}
                  className="col-10 d-flex justify-content-between align-middle bg-primary border border-primary rounded text-white py-2 px-3 m-0 cursor-pointer"
                >
                  <span>{`${jobDetails.job.id}.${orderAcknowledgment.number}`}</span>
                  <div className="px-0 col-3 d-flex align-items-center justify-content-end">
                    <FontAwesomeIcon
                      icon={
                        orderAcknowledgmentSelected === orderAcknowledgment.id
                          ? faChevronDown
                          : faChevronRight
                      }
                      fixedWidth
                      className="my-1"
                    />
                  </div>
                </Col>
                <Col className="col-1 p-0 col d-flex justify-content-end">
                  <div className="col col-8 h-100 d-flex align-items-center justify-content-center">
                    <CustomCheckbox
                      checked={
                        orderAcknowledgmentsSelected.filter(
                          (item) => orderAcknowledgment.id === item.id
                        ).length
                      }
                      onClick={() => {
                        let orderAcknowledgments = [
                          ...orderAcknowledgmentsSelected,
                        ];

                        if (
                          !orderAcknowledgments.find(
                            (item) => orderAcknowledgment.id === item.id
                          )
                        ) {
                          orderAcknowledgments.push(orderAcknowledgment);
                        } else {
                          orderAcknowledgments = orderAcknowledgments.filter(
                            (item) => item.id !== orderAcknowledgment.id
                          );
                        }
                        setUpdateContractAmount(true);
                        setOrderAcknoledgmentsSelected(orderAcknowledgments);
                      }}
                    />
                  </div>
                </Col>
                <Collapse
                  isOpen={
                    orderAcknowledgmentSelected === orderAcknowledgment.id
                  }
                  className="w-100 my-2 mb-0 col pr-0"
                >
                  <Table className="col-12 px-0 border rounded mb-3">
                    <thead className="small">
                      <tr className="text-muted bg-lighter">
                        <th className="align-middle px-3">Amount</th>
                        <th className="align-middle px-3">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-1 px-3">
                          {utils.formatCurrency(orderAcknowledgment.amount)}
                        </td>
                        <td className="py-1 px-3">{orderAcknowledgment.pO}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Collapse>
              </Row>
            </div>
          ))
        ) : (
          <p className="p-3 m-0 text-muted">
            There are not order aknowledgements
          </p>
        )}
      </Row>
      <Row className="border rounded mx-0 mt-3 mb-2">
        <Col className="d-flex justify-content-between py-2">
          <h5 className="m-0">Order total</h5>
          <h5 className="m-0">{utils.formatCurrency(orderTotal)}</h5>
        </Col>
      </Row>
    </Col>
  );
};

export default OrderAcknowledgmentsSelector;
