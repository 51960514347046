import React, { useCallback, useEffect, useState } from "react";
import { faExchangeAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";

import { ACTIONS, useSync } from "../../providers/syncProvider";

import { syncApi } from "../../services/syncServices";
import ConfirmationModal from "../../components/ConfirmationModal";
import TooltipItem from "../../components/TooltipItem";
import { utils } from "../../utils/utils";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";

const columns = [
  {
    accessor: "job.jobNumber",
    Header: "Job Number",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { job } = rowData.row.original;
      return job?.jobNumber || "-";
    },
  },
  {
    accessor: "category",
    Header: "Category",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { category } = rowData.row.original;
      return category || "-";
    },
  },
  {
    accessor: "quantity",
    Header: "Quantity",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { quantity } = rowData.row.original;
      return quantity || "-";
    },
  },
  {
    accessor: "weight",
    Header: "Weight",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { weight } = rowData.row.original;
      return weight ? weight.toFixed(2) : "-";
    },
  },
  {
    accessor: "cost",
    Header: "Cost",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { cost } = rowData.row.original;
      return cost ? cost.toFixed(2) : "-";
    },
  },
  {
    accessor: "updatedAt",
    Header: "Date/time",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { updatedAt } = rowData.row.original;
      return updatedAt ? utils.formatDateTime(updatedAt) : "-";
    },
  },
];

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const TeklaSync = () => {
  const [syncContext, setSyncContext] = useSync();

  const [loading, setLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          syncContext.sortBy === sortBy.id &&
          syncContext.direction === direction
        ) {
          return;
        }
        setSyncContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setSyncContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setSyncContext, syncContext.direction, syncContext.sortBy]
  );

  const onSync = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        try {
          setLoading(true);
          await syncApi.doTeklaSync();
          setLoading(false);
          setSyncContext({
            action: ACTIONS.REFRESH,
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Tekla Data Sync`,
            body:
              err.response.data.msg || "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Tekla Data Sync",
      body: `<span class="text-center">
          This will trigger a synchronization of Tekla reports data and new data will be added. Do you want to continue?
        </span>`,
      confirmColor: "warning",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const items = await syncApi.getTeklaData({
          page: syncContext.page - 1,
          pageSize: syncContext.sizePerPage,
        });
        setLoading(false);
        setSyncContext({
          action: ACTIONS.GET_SYNC_SUCCESS,
          payload: { items },
        });
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [
    setSyncContext,
    syncContext.sortBy,
    syncContext.direction,
    syncContext.sizePerPage,
    syncContext.page,
    syncContext.refresh,
  ]);

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={syncContext.items?.data || []}
          pageSize={syncContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: syncContext.sortBy,
            direction: syncContext.direction,
          }}
        >
          <Card className="mb-3 w-100 overflow-x-auto">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Tekla Material Data</h3>
                <small className="text-muted ml-2 pt-1">
                  ({syncContext.items.count})
                </small>
              </div>
              <div className=" d-flex align-items-center justify-content-between">
                <div></div>
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex  mx-2"
                  color="primary"
                  onClick={() =>
                    setSyncContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
                <TooltipItem
                  id="sync-tooltip"
                  title="Sync data from Tekla Reports"
                >
                  <Button
                    size="sm"
                    className="rounded-circle custom-rounded-button d-flex rounded-circle custom-rounded-button-refresh"
                    color="primary"
                    onClick={onSync}
                  >
                    <FontAwesomeIcon
                      icon={faExchangeAlt}
                      className="text-white"
                    />
                  </Button>
                </TooltipItem>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={syncContext.items.count}
                pageCount={syncContext.items.totalPages}
                currentPage={syncContext.page - 1}
                onPageChange={(page) =>
                  setSyncContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={syncContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setSyncContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default TeklaSync;
