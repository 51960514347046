import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/work-order-items/`;

export const workOrderItemApi = {
  getWorkOrderItems: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`${_endpoint}${data.id ? `/${data.id}` : ""}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createWorkOrderItem: (data) => {
    return axios(`${_endpoint}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateWorkOrderItem: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteWorkOrderItem: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
