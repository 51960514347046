import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";
import QuoteTotalShippingHandlingsSectionTotal from "./QuoteTotalShippingHandlingsSectionTotal";

const QuoteTotalShippingHandlingsSection = () => {
  const [quoteRecapContext] = useQuoteRecap();
  const [quoteItemsContext] = useQuoteItems();
  const [quoteItemShippingHandlings, setQuoteItemShippingHandlings] = useState(
    []
  );

  useEffect(() => {
    const quoteItemShippingHandlings = utils.reduceQuoteItemShippingHandlings(
      quoteItemsContext.quoteItems
    );
    setQuoteItemShippingHandlings(quoteItemShippingHandlings);
  }, [quoteItemsContext]);

  return (
    <Table className="col-12 px-0 border mt-4 rounded mb-0">
      <tbody>
        {quoteItemShippingHandlings.map((quoteItemShippingHandling) => {
          const cost = quoteItemShippingHandling.cost || 0;
          const sale = cost * (1 + quoteRecapContext.markupPercentage / 100);
          return (
            <tr key={quoteItemShippingHandling.id}>
              <td className="py-0" width={"40%"}>
                {quoteItemShippingHandling.shippingHandlingOption.name}
              </td>
              <td className="py-0" width={"15%"} />
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(cost)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatPercent(quoteRecapContext.markupPercentage, 100)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(sale)}
                </div>
              </td>
            </tr>
          );
        })}
        <QuoteTotalShippingHandlingsSectionTotal
          quoteItemShippingHandlings={quoteItemShippingHandlings}
        />
      </tbody>
    </Table>
  );
};

export default QuoteTotalShippingHandlingsSection;
