import React, { useEffect, useState } from "react";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";

const QuoteTotalShippingHandlingsSectionTotal = ({
  quoteItemShippingHandlings,
}) => {
  const [quoteRecapContext] = useQuoteRecap();

  const [cost, setCost] = useState(0);
  const [sale, setSale] = useState(0);

  useEffect(() => {
    const cost = quoteItemShippingHandlings.reduce(
      (p, c) => p + parseFloat(c.cost),
      0
    );
    const sale = quoteItemShippingHandlings.reduce((p, c) => {
      const cost = c.cost || 0;
      const sale = cost * (1 + quoteRecapContext.markupPercentage / 100);
      return p + sale;
    }, 0);
    setCost(cost);
    setSale(sale);
  }, [quoteRecapContext.markupPercentage, quoteItemShippingHandlings]);

  return (
    <tr>
      <td className="font-weight-bold" width={"40%"}>
        Total Shipping and Handling
      </td>
      <td className="py-0" width={"15%"} />
      <td className="py-0" width={"15%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(cost)}
        </div>
      </td>
      <td className="py-0" width={"15%"} />
      <td className="py-0" width={"15%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(sale)}
        </div>
      </td>
    </tr>
  );
};

export default QuoteTotalShippingHandlingsSectionTotal;
