import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import brand from "../../assets/img/logo.png";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import { usersApi } from "../../services/userServices";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    usersApi
      .forgotPassword({ email })
      .then(() => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "Please check your inbox to continue.",
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.data[0]?.msg) {
          return setInformationModal({
            isOpen: true,
            title: "Alright!",
            body: err.response?.data[0]?.msg,
          });
        }
        setInformationModal({
          isOpen: true,
          title: "Alright!",
          body: "There was an error with your request.",
        });
      });
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">Enter your email to reset your password.</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={brand}
                className="rounded p-0 col-8 my-4"
                alt={"Ben Parker"}
              />
            </div>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  required={true}
                  maxLength="50"
                  bsSize="lg"
                  type={"email"}
                  name="email"
                  placeholder="Enter your email"
                  defaultValue={email}
                />
              </FormGroup>
              <div className="text-center mt-3">
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  <Button color="primary" size="lg" type="submit">
                    Next
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
      {informationModal?.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => navigate("/auth/sign-in")}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ResetPassword;
