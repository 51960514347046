import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Collapse,
  ListGroup,
  ListGroupItem,
  Table,
  Input,
} from "reactstrap";
import Loader from "../../../Loader";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../../utils/utils";
import { quoteItemsApi } from "../../../../services/quoteItemServices";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import CustomCheckbox from "../../../CustomCheckbox";

const QuoteItemsList = ({
  quoteItemsSelected,
  setQuoteItemsSelected,
  setContractAmount,
}) => {
  const [quoteItems, setQuoteItems] = useState([]);
  const [jobDetails] = useJobDetails();
  const [quoteItemSelected, setQuoteItemSelected] = useState();
  const [updateContractAmount, setUpdateContractAmount] = useState(false);
  const [loading, setLoading] = useState();
  const [orderTotal, setOrderTotal] = useState();

  const selectQuoteItem = (quoteItemId) => {
    if (quoteItemId === quoteItemSelected) {
      quoteItemId = null;
    }
    setQuoteItemSelected(quoteItemId);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const quoteItems = await quoteItemsApi.getQuoteItems({
        jobId: jobDetails.job.id,
        archived: "false",
      });

      setQuoteItems(
        quoteItems.map((quoteItem) => {
          const { salePrice, unitPrice, totalCost } =
            utils.doQuoteItemCalculations(quoteItem, jobDetails.job);
          const itemFound = quoteItemsSelected.find(
            (item) => item.quoteItemId === quoteItem.id
          );
          if (itemFound) {
            quoteItem.description = itemFound.description;
          }
          quoteItem.salePrice = salePrice;
          quoteItem.unitPrice = unitPrice;
          quoteItem.totalCost = totalCost;
          return quoteItem;
        })
      );
      setLoading(false);
    };
    if (jobDetails.job && !quoteItems.length) {
      fetchData();
    }
  }, [setQuoteItems, jobDetails.job, quoteItemsSelected, quoteItems.length]);

  useEffect(() => {
    let total = quoteItemsSelected.reduce((p, quoteItemSelected) => {
      const quoteItemFound = quoteItems.find(
        (quoteItem) => quoteItem.id === quoteItemSelected.id
      );
      return p + (quoteItemFound?.salePrice || 0);
    }, 0);
    total = utils.formatNumber(total);
    setOrderTotal(total);
    if (setContractAmount && updateContractAmount) {
      setContractAmount(total);
    }
  }, [quoteItemsSelected, quoteItems, setContractAmount, updateContractAmount]);

  return (
    <Col className="col-12 p-0">
      <Row className="d-flex justify-content-between p-0 m-0 align-items-center">
        {loading ? (
          <div className="w-100 py-3">
            <Loader size="sm" />
          </div>
        ) : (
          quoteItems.map((quoteItem, index) => (
            <div className="d-flex w-100 mt-2" key={`sequence-${quoteItem.id}`}>
              <Row className="mr-0 flex-grow-1 overflow-x-auto">
                <Col className="col-1">
                  <div className="d-flex h-100 align-items-center justify-content-center">
                    #{index + 1}
                  </div>
                </Col>
                <Col
                  onClick={() => {
                    selectQuoteItem(quoteItem?.id);
                  }}
                  className="col-10 d-flex justify-content-between align-middle bg-primary border border-primary rounded text-white py-2 px-3 m-0 cursor-pointer"
                >
                  <span>{quoteItem.description}</span>
                  <div className="px-0 col-3 d-flex align-items-center justify-content-end">
                    <FontAwesomeIcon
                      icon={
                        quoteItemSelected === quoteItem.id
                          ? faChevronDown
                          : faChevronRight
                      }
                      fixedWidth
                      className="my-1"
                    />
                  </div>
                </Col>
                <Col className="col-1 p-0 col d-flex justify-content-end">
                  <div className="col col-8 h-100 d-flex align-items-center justify-content-center">
                    <CustomCheckbox
                      checked={
                        quoteItemsSelected.filter(
                          (item) => quoteItem.id === item.id
                        ).length
                      }
                      onClick={() => {
                        let quoteItems = [...quoteItemsSelected];

                        if (
                          !quoteItems.find((item) => quoteItem.id === item.id)
                        ) {
                          quoteItems.push(quoteItem);
                        } else {
                          quoteItems = quoteItems.filter(
                            (item) => item.id !== quoteItem.id
                          );
                        }
                        setUpdateContractAmount(true);
                        setQuoteItemsSelected(quoteItems);
                      }}
                    />
                  </div>
                </Col>
                <Collapse
                  isOpen={quoteItemSelected === quoteItem.id}
                  className="w-100 my-2 mb-0 col pr-0"
                >
                  <Table className="col-12 px-0 border rounded mb-3">
                    <thead className="small">
                      <tr className="text-muted bg-lighter">
                        <th className="align-middle px-3">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-1 px-3">
                          <Input
                            className="border"
                            type="text"
                            maxLength="255"
                            name="description"
                            value={quoteItem.description || ""}
                            onChange={(e) => {
                              const quoteItemsCopy = [...quoteItems];
                              const quoteItemsSelectedCopy =
                                quoteItemsSelected.map((item) => {
                                  if (quoteItem.id === item.id) {
                                    item.description = e.target.value;
                                  }
                                  return item;
                                });
                              quoteItemsCopy[index].description =
                                e.target.value;
                              setUpdateContractAmount(true);
                              setQuoteItemsSelected(quoteItemsSelectedCopy);
                              setQuoteItems(quoteItemsCopy);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <ListGroup>
                    <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter m-0">
                      <small className="font-weight-bold text-muted">
                        Quote Items Quantity
                      </small>
                      <div className="d-flex align-items-center">
                        <Input
                          className="border width-150 text-center"
                          type="number"
                          min="0"
                          max="99999"
                          name="quantity"
                          readOnly={true}
                          value={quoteItem.quantity || ""}
                        />
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                  <ListGroup className="mt-3">
                    <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter m-0">
                      <Col className="col-6 pl-0">
                        <small className="font-weight-bold text-muted">
                          Line total
                        </small>
                      </Col>
                      <Col className="col-6">
                        <Row>
                          <Col className="col-6">
                            <div>
                              <span className="text-muted d-block text-center font-size-85">
                                Unit Price
                              </span>
                              <NumericFormat
                                maxLength={20}
                                readOnly={true}
                                allowNegative={false}
                                decimalScale={2}
                                className="form-control border text-right"
                                value={quoteItem.unitPrice || 0}
                                thousandSeparator={true}
                                prefix={"$"}
                                onValueChange={(e) => {}}
                              />
                            </div>
                          </Col>
                          <Col className="col-6 pr-0">
                            <div>
                              <span className="text-muted d-block text-center font-size-85">
                                Sale Price
                              </span>
                              <NumericFormat
                                maxLength={20}
                                allowNegative={false}
                                decimalScale={2}
                                className="form-control border text-right"
                                value={quoteItem.salePrice || 0}
                                thousandSeparator={true}
                                prefix={"$"}
                                readOnly={true}
                                onValueChange={(e) => {}}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </ListGroupItem>
                  </ListGroup>
                </Collapse>
              </Row>
            </div>
          ))
        )}
      </Row>
      <Row className="border rounded mx-0 mt-3 mb-2">
        <Col className="d-flex justify-content-between py-2">
          <h5 className="m-0">Order total</h5>
          <h5 className="m-0">{utils.formatCurrency(orderTotal)}</h5>
        </Col>
      </Row>
    </Col>
  );
};

export default QuoteItemsList;
