import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/common`;

export const commonApi = {
  getProductTypes: () => {
    return axios(`${_endpoint}/product-types`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => (result && result.data ? result.data : []))
      .catch((err) => apiHelper.handleError(err));
  },
  getFinishes: () => {
    return axios(`${_endpoint}/finishes`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getOpBys: () => {
    return axios(`${_endpoint}/opbys`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getMarkings: () => {
    return axios(`${_endpoint}/markings`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getFreightCost: () => {
    return axios(`${_endpoint}/freight-costs`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getBundlings: () => {
    return axios(`${_endpoint}/bundlings`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWeldingCodes: () => {
    return axios(`${_endpoint}/welding-codes`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getShipVias: () => {
    return axios(`${_endpoint}/ship-vias`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getLaborCategories: () => {
    return axios(`${_endpoint}/labor-categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getQuoteItemTypes: () => {
    return axios(`${_endpoint}/quote-item-types`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getManagementRoles: () => {
    return axios(`${_endpoint}/management-roles`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getSubcontractorRoles: () => {
    return axios(`${_endpoint}/subcontractor-roles`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getShippingHandlingOptions: () => {
    return axios(`${_endpoint}/shipping-handling-options`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getMaterialRequirements: () => {
    return axios(`${_endpoint}/material-requirements`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getShopDrawings: () => {
    return axios(`${_endpoint}/shop-drawing`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getTerms: () => {
    return axios(`${_endpoint}/terms`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
