import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 9999;
const INIT_PAGE_SIZE = 25;

const initialState = {
  loading: false,
  recent: 0,
  inProgress: 0,
  wonThisMonth: 0,
  lostThisMonth: 0,
  inReadyForInvoice: 0,
  inPMApproved: 0,
  inExecApproved: 0,
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_DATA_LOADING: "GET_DATA_LOADING",
  GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_DATA_LOADING: {
      const { payload } = data;

      return {
        ...state,
        loading: payload,
      };
    }

    case ACTIONS.GET_DATA_SUCCESS: {
      const { payload } = data;
      const { kpis } = payload;

      return {
        ...state,
        loading: false,
        ...kpis,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const JobKPIsContext = createContext(initialState);

const JobKPIsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <JobKPIsContext.Provider value={stateAndDispatch}>
      {children}
    </JobKPIsContext.Provider>
  );
};

export const useJobKPIs = () => useContext(JobKPIsContext);

export {
  JobKPIsContext,
  JobKPIsProvider,
  ACTIONS,
  MAX_PAGE_SIZE,
  INIT_PAGE_SIZE,
};
