import React from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";

const QuoteRecapLaborSection = ({ quoteItem }) => {
  const fieldName = utils.getFieldNameToUseAsCostByHour(quoteItem);
  const itemsWithHours = quoteItem.quoteItemLaborCategories.filter(
    (quoteItemLaborCategory) =>
      !utils.laborCategoriesExcluded.includes(
        +quoteItemLaborCategory.laborCategory.code
      ) && quoteItemLaborCategory.hours > 0
  );

  return (
    <Table className="col-12 px-0 mt-4 border rounded mb-0">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th width={"35%"} className="align-middle">
            Shop Labor Code/Cat.
          </th>
          <th width={"13%"} className="text-center">
            Est. Hours
          </th>
          <th width={"13%"} className="text-center">
            Cost/Hr.
          </th>
          <th width={"13%"} className="text-center">
            Total Cost
          </th>
          <th width={"13%"} className="text-center">
            Sale/Hr.
          </th>
          <th width={"13%"} className="text-center">
            Total Sale
          </th>
        </tr>
      </thead>
      <tbody>
        {itemsWithHours
          .sort((x, y) =>
            parseInt(x.laborCategory.code) < parseInt(y.laborCategory.code)
              ? -1
              : 1
          )
          .map((quoteItemLaborCategory) => {
            const hours = quoteItemLaborCategory.hours || 0;
            const costByHours =
              quoteItemLaborCategory.laborCategory.costByHoursAndYear[
                fieldName
              ] || 0;
            const totalCost = hours * costByHours || 0;
            const saleHour = costByHours * (1 + quoteItem.margin / 100);
            const totalSale = hours * saleHour;

            return (
              <tr key={quoteItemLaborCategory.id}>
                <td className="py-0 text-truncate" width={"35%"}>
                  {`${quoteItemLaborCategory.laborCategory.code} - ${quoteItemLaborCategory.laborCategory.name}`}
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatHour(hours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(costByHours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalCost)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(saleHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalSale)}
                  </div>
                </td>
              </tr>
            );
          })}

        <tr>
          <td className="font-weight-bold py-0" width={"35%"}>
            Total Shop Labor
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatHour(
                itemsWithHours.reduce((p, c) => p + c.hours, 0)
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce((p, c) => p + c.hours, 0)
                  ? itemsWithHours.reduce(
                      (p, c) =>
                        p +
                        c.laborCategory.costByHoursAndYear[fieldName] * c.hours,
                      0
                    ) / itemsWithHours.reduce((p, c) => p + c.hours, 0)
                  : 0
              )}
            </div>
          </td>
          <td className="py-0">
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce(
                  (p, c) =>
                    p + c.laborCategory.costByHoursAndYear[fieldName] * c.hours,
                  0
                )
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce(
                  (p, c) =>
                    p +
                    c.laborCategory.costByHoursAndYear[fieldName] *
                      (1 + quoteItem.margin / 100),
                  0
                )
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce(
                  (p, c) =>
                    p +
                    c.hours *
                      c.laborCategory.costByHoursAndYear[fieldName] *
                      (1 + quoteItem.margin / 100),
                  0
                )
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteRecapLaborSection;
