import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    minWidth: "125px",
  }),
};

const animatedComponents = makeAnimated();

const TYPE_CUSTOM = "TYPE_CUSTOM";
const TYPE_ANY = "TYPE_ANY";

const PeriodTypeSelector = ({ periodType, setPeriodType, options }) => {
  return (
    <div className="d-flex ml-3">
      <Select
        styles={style}
        className="min-width-150 flex-grow-1 border rounded small"
        options= {options || [
          {
            label: "Date Range",
            value: TYPE_CUSTOM,
          },
          {
            label: "Any Date",
            value: TYPE_ANY,
          },
        ]}
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={periodType}
        onChange={setPeriodType}
      />
    </div>
  );
};

export default PeriodTypeSelector;
