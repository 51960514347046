import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import { useAuth } from "../../../providers/authProvider";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import { utils } from "../../../utils/utils";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const columns = {
  "Total Estimated Man Hour YTD": {
    type: "string",
  },
  "Average Hours Per Week": {
    type: "string",
  },
  "Man Hour Sold Pace": {
    type: "string",
  },
  "Total Sales YTD": {
    type: "string",
  },
  "Average Sales Per Week": {
    type: "string",
  },
  "Sale Pace": {
    type: "string",
  },
  "Top 10 Customer YTD": {
    type: "string",
  },
  "Capacity ST/Wk": {
    type: "string",
  },
  "Estimated Capacity for Year": {
    type: "string",
  },
  "Est. Capacity% to YTD": {
    type: "string",
  },
  "2023 Sales Goal": {
    type: "string",
  },
  "Man Hrs Sold Goal": {
    type: "string",
  },
  "Annual Capacity % Goal": {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [columns],
  },
  slice: {
    rows: [
      {
        uniqueName: "Total Estimated Man Hour YTD",
      },
      {
        uniqueName: "Average Hours Per Week",
      },
      {
        uniqueName: "Man Hour Sold Pace",
      },
      {
        uniqueName: "Total Sales YTD",
      },
      {
        uniqueName: "Average Sales Per Week",
      },
      {
        uniqueName: "Sale Pace",
      },
      {
        uniqueName: "Top 10 Customer YTD",
      },
      {
        uniqueName: "Capacity ST/Wk",
      },
      {
        uniqueName: "Estimated Capacity for Year",
      },
      {
        uniqueName: "Est. Capacity% to YTD",
      },
      {
        uniqueName: "2023 Sales Goal",
      },
      {
        uniqueName: "Man Hrs Sold Goal",
      },
      {
        uniqueName: "Annual Capacity % Goal",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Annual Sales Report",
    },
  },
};

const AnnualSalesReportSheet = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    reportsApi
      .annualSales()
      .then((result) => {
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, result],
          });
        }
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [pivot, authContext.currentUser, refresh]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Annual Sales Report</h3>
              <div className="d-flex align-items-center">
                {loading ? (
                  <div className="min-width-50">
                    <Loader size="sm" className="mr-3" />
                  </div>
                ) : null}
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex ml-3"
                  color="secondary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
                <Button
                  className="rounded ml-3"
                  color="primary"
                  size="sm"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utils.customizeToolbarReport(
                      toolbar,
                      pivot,
                      "annual_sales_report"
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default AnnualSalesReportSheet;
