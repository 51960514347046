import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Col, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";

import CustomCheckbox from "../../../../CustomCheckbox";

const QuoteItemModalShippingHandling = ({
  selectedShippingHandling,
  setSelectedShippingHandling,
}) => {
  const [commonDataContext] = useCommonData();
  const [openSection, setOpenSection] = useState();

  const onItemClick = (shippingHandling) => {
    setSelectedShippingHandling({
      ...selectedShippingHandling,
      [shippingHandling.id]: !selectedShippingHandling[shippingHandling.id],
    });
  };

  const onSelectAllClick = () => {
    const allChecked =
      Object.keys(selectedShippingHandling).filter(
        (sc) => selectedShippingHandling[sc]
      ).length === commonDataContext.quoteItemShippingHandlings.length;
    const newSelectedShippingHandling =
      commonDataContext.quoteItemShippingHandlings.reduce(
        (p, c) => ({
          ...p,
          [c.id]: !allChecked,
        }),
        {}
      );
    setSelectedShippingHandling({
      ...newSelectedShippingHandling,
    });
  };

  return commonDataContext.quoteItemShippingHandlings.length ? (
    <Col className="col-12 mt-3">
      <ListGroup>
        <ListGroupItem
          className={`d-flex justify-content-between align-items-center bg-lighter cursor-pointer border-bottom-${
            openSection ? 0 : 1
          }`}
          onClick={() => setOpenSection(!openSection)}
        >
          <div>
            <span>Shipping & Handling</span>
          </div>
          <FontAwesomeIcon
            icon={openSection ? faChevronDown : faChevronUp}
            className="text-muted"
          />
        </ListGroupItem>
        <Collapse isOpen={openSection}>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center cursor-pointer"
            onClick={() => onSelectAllClick()}
          >
            <div className="flex-shrink-0">Select all</div>
            <CustomCheckbox
              checked={
                Object.keys(selectedShippingHandling).filter(
                  (c) => selectedShippingHandling[c]
                ).length === commonDataContext.quoteItemShippingHandlings.length
              }
            />
          </ListGroupItem>
          {commonDataContext.quoteItemShippingHandlings.map(
            (shippingHandling) => (
              <ListGroupItem
                key={shippingHandling.id}
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => onItemClick(shippingHandling)}
              >
                <div className="flex-shrink-0">{shippingHandling.name}</div>
                <CustomCheckbox
                  checked={selectedShippingHandling[shippingHandling.id]}
                />
              </ListGroupItem>
            )
          )}
        </Collapse>
      </ListGroup>
    </Col>
  ) : null;
};

export default QuoteItemModalShippingHandling;
