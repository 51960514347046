import React from "react";

import QuoteRecapLaborSection from "./QuoteRecapLaborSection";
import QuoteRecapManagementSection from "./QuoteRecapManagementSection";
import QuoteRecapSubContractorsSection from "./QuoteRecapSubContractorsSection";
import QuoteRecapItemQuantity from "./QuoteRecapItemQuantity";
import QuoteRecapMaterialSection from "./QuoteRecapMaterialSection";
import QuoteRecapResumeSection from "./QuoteRecapResumeSection";
import QuoteRecapShippingHandlings from "./QuoteRecapShippingHandlings";

const QuoteRecapTable = ({ quoteItem }) => {
  return (
    <div>
      <QuoteRecapMaterialSection quoteItem={quoteItem} />
      <QuoteRecapSubContractorsSection quoteItem={quoteItem} />
      <QuoteRecapShippingHandlings quoteItem={quoteItem} />
      <QuoteRecapLaborSection quoteItem={quoteItem} />
      <QuoteRecapManagementSection quoteItem={quoteItem} />
      <QuoteRecapItemQuantity quoteItem={quoteItem} />
      <QuoteRecapResumeSection quoteItem={quoteItem} />
    </div>
  );
};

export default QuoteRecapTable;
