import React, { useState } from "react";
import { utils } from "../../../utils/utils";
import SequencesTable from "./SequencesTable";
import { Container, Button } from "reactstrap";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import SequenceDetailsModal from "./SequenceDetailsModal";
import { sequenceApi } from "../../../services/sequenceServices";

const WAITING_FOR_DETAILER_STATUS = 2;
const CHECKING_IN_PROGRESS_STATUS = 5;
const CHECKING_COMPLETE_STATUS = 6;
const WAITING_FOR_DETAILING_TAB = "WAITING_FOR_DETAILING_TAB";
const CHECKING_IN_PROGRESS_TAB = "CHECKING_IN_PROGRESS_TAB";

const CheckingInProgress = ({
  sequencesTotal,
  setSequencesTotal,
  reloadTabs,
  setReloadTabs,
  tabName,
}) => {
  const [detailModal, setDetailModal] = useState(false);
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [sequenceSelected, setSequenceSelected] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const columns = [
    {
      accessor: "sendToDetailerButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 130,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"warning"}
            onClick={() => {
              sendSequenceToDetailer(rowData.row.original);
            }}
          >
            Send to Detailer
          </Button>
        );
      },
    },
    {
      accessor: "sendToReviewButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 135,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"success"}
            onClick={() => {
              changeSequenceStatus(
                rowData.row.original,
                CHECKING_COMPLETE_STATUS
              );
            }}
          >
            Ready for Review
          </Button>
        );
      },
    },
    {
      accessor: "seeJobDetailsButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 130,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original;
              setSequenceSelected(sequence);
              setSequenceStatusId(sequence?.sequenceStatusId);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const sendSequenceToDetailer = async (sequence) => {
    try {
      onClear();
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId: WAITING_FOR_DETAILER_STATUS,
            });
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Send to Detailer",
              body: "Sequence sent to Detailer successfully.",
            });
            setReloadTabs([
              WAITING_FOR_DETAILING_TAB,
              CHECKING_IN_PROGRESS_TAB,
            ]);
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => onClear(),
        title: "Send to Detailer",
        body: `
          <div class="text-center">
          Are you sure you are ready to send this job to ${
            sequence?.detailer
              ? `${sequence?.detailer?.firstName} ${sequence?.detailer?.lastName}`
              : "the Detailer assigned"
          } for Detailing?
          </div>
        `,
        confirmColor: "warning",
      });
    } catch (error) {
      onClear();
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const changeSequenceStatus = async (sequence, sequenceStatusId) => {
    try {
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId,
            });
            setConfirmationModal(utils.initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Send to Review",
              body: `Sequence ${
                detailModal ? "updated" : "sent to Review"
              } successfully.`,
            });
            setReloadTabs([
              CHECKING_IN_PROGRESS_TAB,
              utils.detailingTabs[sequenceStatusId],
            ]);
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: onClear,
        title: "Send to Review",
        body: `<div class="text-center">
          ${
            detailModal
              ? "Do you confirm you want to update the sequence " +
                sequenceSelected?.job?.jobNumber
              : "Are you sure you are ready to send this job to the Detailing Manager for Review"
          }?
        </div>`,
      });
    } catch (error) {
      onClear();
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onClear = async () => {
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
    setSequenceStatusId();
    setSequenceSelected();
    setDetailModal(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    changeSequenceStatus(sequenceSelected, sequenceStatusId);
  };

  return (
    <Container fluid className="px-0">
      <SequencesTable
        columns={columns}
        sequenceStatusId={CHECKING_IN_PROGRESS_STATUS}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => onClear()}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClear}
          readOnly={true}
          enableUpdate={true}
          setInformationModal={setInformationModal}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      )}
    </Container>
  );
};

export default CheckingInProgress;
