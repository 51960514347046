import React, { useEffect, useState } from "react";

import { Col, Row } from "reactstrap";

import Badge from "reactstrap/lib/Badge";

import {
  useJobDetails,
  STATUS_TEXT,
} from "../../../providers/jobDetailsProvider";
import { utils } from "../../../utils/utils";

const STATUS_RFQ = 1;
const STATUS_COMPLETED = 5;
const STATUS_CANCELLED = 8;

const statusLog = (job, status) => {
  const statusLog = job.jobStatusLog.find((log) => log.statusId === status.id);
  let time = statusLog?.modifiedAt || statusLog?.createdAt;

  if (!time && status.id === STATUS_RFQ) {
    time = job.createdAt;
  }

  return (
    <div
      className={`ml-1 text-left ${
        job.statusId === status.id ? "text-body" : "text-muted"
      }`}
    >
      <label className="mr-1">{time ? utils.formatDateTime(time) : "-"}</label>
    </div>
  );
};

const isStatusActive = (job, statusId) => job.statusId === statusId;

const JobStatus = () => {
  const [jobDetails] = useJobDetails();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const datesStatus = STATUS_TEXT.filter((status) =>
      status.showOnlyIfActive
        ? isStatusActive(jobDetails.job, status.id)
        : isStatusActive(jobDetails.job, STATUS_CANCELLED)
        ? status.id !== STATUS_COMPLETED
        : true
    ).map((status) => statusLog(jobDetails.job, status));
    setDates(datesStatus);
  }, [jobDetails.job]);

  const statusFiltered = STATUS_TEXT.filter((status) =>
    status.showOnlyIfActive
      ? isStatusActive(jobDetails.job, status.id)
      : isStatusActive(jobDetails.job, STATUS_CANCELLED)
      ? status.id !== STATUS_COMPLETED
      : true
  );

  return (
    <Col className="d-flex flex-column align-items-center col-12 progress-container pt-4 mt-4 border-top">
      <h4 className="mb-4">Job Status</h4>
      <div className="min-width-900 col-12">
        <div className="d-flex justify-content-between align-items-center col-12">
          <div className="progress-line w-100 mx-0"></div>
        </div>
        <Row className="justify-content-between status-container mx-0">
          {statusFiltered.map((status, i) => (
            <div
              key={status.id}
              className={`d-flex flex-column align-items-${
                i === 0
                  ? "start"
                  : i === statusFiltered.length - 1
                  ? "end"
                  : "center"
              }`}
            >
              <Badge
                color={
                  isStatusActive(jobDetails.job, status.id)
                    ? status.id === STATUS_CANCELLED
                      ? "danger"
                      : "success"
                    : "lighter"
                }
              >
                {status.text}
              </Badge>
              <small className="mt-1">{dates[i]}</small>
            </div>
          ))}
        </Row>
      </div>
    </Col>
  );
};

export default JobStatus;
