import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";

const QuoteTotalMaterialSection = () => {
  const [quoteItemsContext] = useQuoteItems();
  const [quoteRecapContext] = useQuoteRecap();

  const [quoteItemTypeQuoteItems, setQuoteItemTypeQuoteItems] = useState([]);

  const [weight, setWeight] = useState(0);
  const [cost, setCost] = useState(0);
  const [sale, setSale] = useState(0);

  useEffect(() => {
    const quoteItemTypeQuoteItems = utils.reduceQuoteItemTypeQuoteItems(
      quoteItemsContext.quoteItems
    );
    setQuoteItemTypeQuoteItems(quoteItemTypeQuoteItems);
  }, [quoteItemsContext]);

  useEffect(() => {
    const weight = quoteItemTypeQuoteItems.reduce(
      (p, c) => p + parseFloat(c.weight),
      0
    );
    const cost = quoteItemTypeQuoteItems.reduce(
      (p, c) => p + parseFloat(c.cost),
      0
    );
    const sale = quoteItemTypeQuoteItems.reduce((p, c) => {
      const cost = c.cost || 0;
      const sale = cost * (1 + quoteRecapContext.markupPercentage / 100);
      return p + sale;
    }, 0);
    setWeight(weight);
    setCost(cost);
    setSale(sale);
  }, [quoteRecapContext.markupPercentage, quoteItemTypeQuoteItems]);

  return (
    <Table className="col-12 px-0 border rounded mb-0">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th className="align-middle" width={"40%"}>
            Type
          </th>
          <th width={"15%"} className="text-center">
            Weight
          </th>
          <th width={"15%"} className="text-center">
            Cost
          </th>
          <th width={"15%"} className="text-center">
            Markup
          </th>
          <th width={"15%"} className="text-center">
            Sale
          </th>
        </tr>
      </thead>
      <tbody>
        {quoteItemTypeQuoteItems
          .sort((x, y) =>
            x.quoteItemType.name < y.quoteItemType.name ? -1 : 1
          )
          .map((quoteItemTypeQuoteItem) => {
            const weight = quoteItemTypeQuoteItem.weight || 0;
            const cost = quoteItemTypeQuoteItem.cost || 0;
            const sale = cost * (1 + quoteRecapContext.markupPercentage / 100);
            return (
              <tr key={quoteItemTypeQuoteItem.id}>
                <td className="py-0 text-truncate" width={"40%"}>
                  {quoteItemTypeQuoteItem.quoteItemType.name}
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatWeight(weight)}
                  </div>
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(cost)}
                  </div>
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatPercent(quoteRecapContext.markupPercentage, 100)}
                  </div>
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(sale)}
                  </div>
                </td>
              </tr>
            );
          })}
        <tr>
          <td className="font-weight-bold py-0" width={"40%"}>
            Total Material
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatWeight(weight)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(cost)}
            </div>
          </td>
          <td className="py-0" width={"15%"} />
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(sale)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteTotalMaterialSection;
