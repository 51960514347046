import React, { useState } from "react";
import { utils } from "../utils/utils";
import { DayPicker } from "react-day-picker";
import { faCalendar, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-day-picker/dist/style.css";

const NUMBRE_OF_MONTHS = 1;

const DatePicker = ({
  date = new Date(),
  required,
  align = "center",
  onClick,
}) => {
  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);

  const updateDate = (day) => {
    onClick(day);
    setDateSelectorOpen(false);
  };

  return (
    <div>
      {dateSelectorOpen ? (
        <div className="position-relative text-right">
          <div className="w-100 p-1 position-absolute">
            <FontAwesomeIcon
              size="lg"
              icon={faWindowClose}
              className="text-danger"
              onClick={() => setDateSelectorOpen(false)}
            />
          </div>
          <DayPicker
            className={`bg-white rounded border m-0 d-flex align-items-center justify-content-${align}`}
            numberOfMonths={NUMBRE_OF_MONTHS}
            mode="single"
            defaultMonth={date}
            selected={date}
            modifiersClassNames={{
              selected: "my-selected",
              today: "my-today",
              range_start: "my-range_start",
              range_end: "my-range_end",
            }}
            onDayClick={updateDate}
            required={required}
          />
        </div>
      ) : (
        <div
          className="col-12 d-flex align-items-center p-2 border rounded bg-white cursor-pointer"
          onClick={() => setDateSelectorOpen(true)}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faCalendar}
            className="mr-3 z-index-0 text-secondary"
          />
          <span>{date ? utils.formatDate(date) : "Select a Date ..."}</span>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
