import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipItem = (props) => {
  const { position = "top", id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span className={props.className || ""}>
      <span
        id={"tooltip-" + id}
        className="d-flex flex-grow-1 justify-content-center"
      >
        {props.children}
      </span>
      {props.title ? (
        <Tooltip
          placement={position}
          isOpen={tooltipOpen}
          target={"tooltip-" + id}
          toggle={toggle}
        >
          {props.title}
        </Tooltip>
      ) : null}
    </span>
  );
};

export default TooltipItem;
