import React, { useEffect, useState } from "react";
import { Button, Col } from "reactstrap";

import InformationModal from "../../../InformationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import QuoteItemModal from "./quoteItemModal/QuoteItemModal";
import {
  useQuoteItems,
  ACTIONS,
} from "../../../../providers/quoteItemsProvider";
import { QuoteItemProvider } from "../../../../providers/quoteItemProvider";
import QuoteItemCollapsible from "./QuoteItemCollapsible";
import { quoteItemsApi } from "../../../../services/quoteItemServices";
import Loader from "../../../Loader";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const DIRECTION_UP = "DIRECTION_UP";

const QuoteItemsTab = () => {
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [quoteItemsContext, setQuoteItemsContext] = useQuoteItems();

  const [quoteItemModalOpen, setQuoteItemModalOpen] = useState();
  const [sortLoader, setSortLoader] = useState();

  const [loading, setLoading] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const quoteItems = await quoteItemsApi.getQuoteItems({
        jobId: jobDetails.job.id,
      });
      setLoading(false);
      setQuoteItemsContext({
        action: ACTIONS.GET_QUOTE_ITEMS_SUCCESS,
        payload: { quoteItems },
      });
    };
    if (jobDetails.job) {
      fetchData();
    }
  }, [setQuoteItemsContext, quoteItemsContext.refresh, jobDetails.job]);

  const onSortQuoteItem = async (direction, quoteItem, index) => {
    try {
      if (sortLoader) {
        return;
      }
      const quoteItems = quoteItemsContext.quoteItems.sort(
        (x, y) =>
          (x.order || Number.MAX_SAFE_INTEGER) -
          (y.order || Number.MAX_SAFE_INTEGER)
      );
      setSortLoader(true);
      if (!quoteItem.order) {
        quoteItem.order = index + 1;
      }
      if (direction === DIRECTION_UP) {
        //if its first, return
        if (quoteItem.order === 1 || index === 0) {
          return setSortLoader(false);
        }
        //exchange order with previous
        const previousQuoteItem = quoteItems[index - 1];
        previousQuoteItem.order = quoteItem.order;
        quoteItem.order = quoteItem.order - 1;
        const updatedQuoteItems = await quoteItemsApi.updateQuoteItemsOrder({
          jobId: jobDetails.job.id,
          quoteItems: [quoteItem, previousQuoteItem],
        });
        setQuoteItemsContext({
          action: ACTIONS.UPDATE_QUOTE_ITEMS_ORDER,
          payload: { quoteItems: updatedQuoteItems },
        });
      } else {
        //if its last, return
        if (index === quoteItems.length - 1) {
          return setSortLoader(false);
        }
        //exchange order with next
        const nextQuoteItem = quoteItems[index + 1];
        nextQuoteItem.order = quoteItem.order;
        quoteItem.order = quoteItem.order + 1;
        const updatedQuoteItems = await quoteItemsApi.updateQuoteItemsOrder({
          jobId: jobDetails.job.id,
          quoteItems: [quoteItem, nextQuoteItem],
        });
        setQuoteItemsContext({
          action: ACTIONS.UPDATE_QUOTE_ITEMS_ORDER,
          payload: { quoteItems: updatedQuoteItems },
        });
      }
    } catch (err) {
      setSortLoader(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
    setSortLoader(false);
  };

  /*
  const onSortQuoteItem = async (direction, quoteItem, index) => {
    if (sortLoader) { return };
    const quoteItems = JSON.parse(JSON.stringify(quoteItemsContext.quoteItems));
    setSortLoader(true);
    if (!quoteItem.order) {
      quoteItem.order = index + 1;
    };
    const quoteItemsToUpdate = []
    if (direction === DIRECTION_UP) {
      //if its first, return
      if (quoteItem.order === 1) { return setSortLoader(false) };
      //exchange order with previous
      const previousQuoteItem = quoteItems[index - 1];
      previousQuoteItem.order = quoteItem.order;
      quoteItem.order = quoteItem.order - 1;
      quoteItemsToUpdate.push(previousQuoteItem);
    } else {
      //if its last, return
      if (index === (quoteItems.length - 1)) { return setSortLoader(false) };
      //exchange order with next
      const nextQuoteItem = quoteItems[index + 1];
      nextQuoteItem.order = quoteItem.order;
      quoteItem.order = quoteItem.order + 1;
      quoteItemsToUpdate.push(nextQuoteItem);
    };
    quoteItemsToUpdate.push(quoteItem);
    try {
      await quoteItemsApi.updateQuoteItemsOrder({ quoteItems: quoteItemsToUpdate });
      quoteItemsToUpdate.forEach(qiUpdate => (
        quoteItems.splice(quoteItems.findIndex(qi => qi.id === qiUpdate.id), 1, qiUpdate)
      ));
      setQuoteItemsContext({
        action: ACTIONS.GET_QUOTE_ITEMS_SUCCESS,
        payload: { quoteItems }
      });
    } catch (err) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: 'There was an error with your request.'
      });
    };
    setSortLoader(false);
  };
*/

  return (
    <Col className="mt-3 col-12">
      <div className="d-flex align-items-start justify-content-between">
        <h3>Quote Items</h3>
        {isJobLocked ?
          <div className="px-1 py-0 bg-danger text-white">Locked</div>
          : (<Button
              className="rounded"
              size="sm"
              onClick={() => setQuoteItemModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="ml-2">Add Item</span>
            </Button>
          )
        }
      </div>
      <div className="d-flex flex-column">
        {quoteItemsContext.quoteItems?.length ? (
          loading ? (
            <Loader size="sm" />
          ) : (
            quoteItemsContext.quoteItems
              .sort(
                (x, y) =>
                  (x.order || Number.MAX_SAFE_INTEGER) -
                  (y.order || Number.MAX_SAFE_INTEGER)
              )
              .map((quoteItem, index) => (
                <QuoteItemProvider key={quoteItem.id}>
                  <QuoteItemCollapsible
                    index={index}
                    quoteItem={quoteItem}
                    onSortQuoteItem={(direction) =>
                      onSortQuoteItem(direction, quoteItem, index)
                    }
                  />
                </QuoteItemProvider>
              ))
          )
        ) : (
          <div className="text-center">No quote items</div>
        )}
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : quoteItemModalOpen ? (
        <QuoteItemModal
          onClose={() => setQuoteItemModalOpen(false)}
          onSubmit={() => {
            setQuoteItemModalOpen(false);
            setQuoteItemsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Col>
  );
};

export default QuoteItemsTab;
