import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/users`;

export const usersApi = {
  getUsers: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id ? `${_endpoint}/${data.id}` : `${_endpoint}?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createUser: (data) => {
    return axios(`${_endpoint}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updatePassword: (data) => {
    return axios(`${_endpoint}/${data.id}/password`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateUser: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteUser: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  forgotPassword: (data) => {
    return axios(`/api/users/password/reset`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  recoverPassword: (data) => {
    return axios(`/api/users/password/recover`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => {
        if (err.isAxiosError) {
          throw Error(err.response.data[0].param);
        }
        throw Error(err);
      });
  },
};
