import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/settings`;

export const settingsApi = {
  getSettings: () => {
    return axios(_endpoint, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  setSetting: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
