export const apiHelper = {
  handleError: (err) => {
    if (err?.response?.status === 401) {
      window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
    } else if (err?.response?.status === 404) {
      window.location.href = "/error/404";
    } else if (err?.response?.status === 400 || err?.response?.status === 422) {
      throw err;
    } else {
      window.location.href = "/error/500";
    }
  },
};
