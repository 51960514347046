import React from "react";
import { useNavigate } from "react-router";

import { Button } from "reactstrap";
import { useAuth } from "../../providers/authProvider";

const Page500 = () => {
  const navigate = useNavigate();
  const [authContext] = useAuth();

  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">500</h1>
      <p className="h1">Internal server error.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        The server encountered something unexpected that didn't allow it to
        complete the request.
      </p>
      <Button
        onClick={() =>
          authContext.currentUser
            ? navigate("/rfq/all")
            : navigate("/auth/sign-in")
        }
        color="primary"
        size="lg"
      >
        Return to website
      </Button>
    </div>
  );
};

export default Page500;
