import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import InformationModal from "../../InformationModal";

const CustomerContactCreationModal = ({ onSubmit, onClose }) => {
  const [customerContact, setCustomerContact] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    onSubmit(customerContact);
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader close={closeBtn}>Add Customer Contact</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="firstName">
              <span>First Name</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              maxLength="50"
              type="text"
              name="firstName"
              placeholder="First Name..."
              value={customerContact.firstName || ""}
              onChange={(e) =>
                setCustomerContact({
                  ...customerContact,
                  firstName: e.target.value,
                })
              }
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">
              <span>Last Name</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              maxLength="50"
              type="text"
              name="lastName"
              placeholder="Last Name..."
              value={customerContact.lastName || ""}
              onChange={(e) =>
                setCustomerContact({
                  ...customerContact,
                  lastName: e.target.value,
                })
              }
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="title">
              <span>Title</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              maxLength="50"
              name="title"
              placeholder="Title..."
              value={customerContact.title || ""}
              onChange={(e) =>
                setCustomerContact({
                  ...customerContact,
                  title: e.target.value,
                })
              }
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              <span>Email</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              maxLength="50"
              type="email"
              name="email"
              placeholder="Email..."
              value={customerContact.email || ""}
              onChange={(e) =>
                setCustomerContact({
                  ...customerContact,
                  email: e.target.value.trim(),
                })
              }
              required
            />
          </FormGroup>
          <FormGroup className="mb-0">
            <Label for="phoneNumber">
              <span>Phone Number</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            <Input
              maxLength="50"
              type="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number..."
              value={customerContact.phoneNumber || ""}
              onChange={(e) =>
                setCustomerContact({
                  ...customerContact,
                  phoneNumber: e.target.value.trim(),
                })
              }
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            className="d-flex align-items-center ml-2"
            color="secondary"
            onClick={onClose}
          >
            Discard
          </Button>
          <Button
            className="d-flex align-items-center"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomerContactCreationModal;
