import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Loader from "./Loader";

const ConfirmationModal = ({
  onSubmit,
  onClose,
  title,
  body,
  confirmColor,
}) => {
  const [loading, setLoading] = useState(false);

  const doSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button
          disabled={loading}
          color={confirmColor || "primary"}
          onClick={doSubmit}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
