import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import "./assets/scss/_app.scss";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: "https://3983dc579b4d41dcbfa971ee42b2b7a5@o912544.ingest.sentry.io/6508627",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.origin],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "local",
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
