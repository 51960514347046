import React, { useState } from "react";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import CheckingInProgress from "../../components/admin/detailing/CheckingInProgress";
import WaitingForChecking from "../../components/admin/detailing/WaitingForChecking";
import WaitingForDetailing from "../../components/admin/detailing/WaitingForDetailing";
import DetailingInProgress from "../../components/admin/detailing/DetailingInProgress";

const WAITING_FOR_DETAILING_TAB = "WAITING_FOR_DETAILING_TAB";
const DETAILING_TAB = "DETAILING_TAB";
const WAITING_FOR_CHECKING_TAB = "WAITING_FOR_CHECKING_TAB";
const CHECKING_IN_PROGRESS_TAB = "CHECKING_IN_PROGRESS_TAB";
const CHECKING_COMPLETE_TAB = "CHECKING_COMPLETE_TAB";
const REVISION_GENERATED_TAB = "REVISION_GENERATED_TAB";
const DEFAULT_TABS_TO_LOAD = [
  WAITING_FOR_DETAILING_TAB,
  DETAILING_TAB,
  WAITING_FOR_CHECKING_TAB,
  CHECKING_IN_PROGRESS_TAB,
  CHECKING_COMPLETE_TAB,
  REVISION_GENERATED_TAB,
];

const DetailerCheckerDashboard = () => {
  const [reloadTabs, setReloadTabs] = useState(DEFAULT_TABS_TO_LOAD);
  const [checkingInProgressTotal, setCheckingInProgressTotal] = useState(0);
  const [waitingForCheckingTotal, setWaitingForCheckingTotal] = useState(0);
  const [waitingForDetailingTotal, setWaitingForDetailingTotal] = useState(0);
  const [detailingTotal, setDetailingTotal] = useState(0);
  return (
    <Container fluid className="mb-4">
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-column align-items-start">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Jobs In Detailing
          </span>
        </HeaderTitle>
      </Header>
      <Row>
        <Card className="box-shadow-none border-left border-right mb-0 w-100">
          <CardHeader className="pb-0">
            <div className="h4 m-0">New Jobs</div>
          </CardHeader>
          <CardBody className="pt-0">
            <WaitingForDetailing
              setSequencesTotal={setWaitingForDetailingTotal}
              sequencesTotal={waitingForDetailingTotal}
              setReloadTabs={setReloadTabs}
              reloadTabs={reloadTabs}
              tabName={WAITING_FOR_DETAILING_TAB}
            />
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card className="box-shadow-none border-left border-right mb-0 w-100">
          <CardHeader className="pb-0">
            <div className="h4 m-0">Detailing Jobs</div>
          </CardHeader>
          <CardBody className="pt-0">
            <DetailingInProgress
              setSequencesTotal={setDetailingTotal}
              sequencesTotal={detailingTotal}
              setReloadTabs={setReloadTabs}
              reloadTabs={reloadTabs}
              tabName={DETAILING_TAB}
            />
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card className="box-shadow-none border-left border-right mb-0 w-100">
          <CardHeader className="pb-0">
            <div className="h4 m-0">Waiting for Check</div>
          </CardHeader>
          <CardBody className="pt-0">
            <WaitingForChecking
              setSequencesTotal={setWaitingForCheckingTotal}
              sequencesTotal={waitingForCheckingTotal}
              setReloadTabs={setReloadTabs}
              reloadTabs={reloadTabs}
              tabName={WAITING_FOR_CHECKING_TAB}
            />
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card className="box-shadow-none border-left border-right mb-0 w-100">
          <CardHeader className="pb-0">
            <div className="h4 m-0">Checking Jobs</div>
          </CardHeader>
          <CardBody className="pt-0">
            <CheckingInProgress
              setSequencesTotal={setCheckingInProgressTotal}
              sequencesTotal={checkingInProgressTotal}
              setReloadTabs={setReloadTabs}
              reloadTabs={reloadTabs}
              tabName={CHECKING_IN_PROGRESS_TAB}
            />
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default DetailerCheckerDashboard;
