import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import { utils } from "../../../utils/utils";
import PeriodTypeSelector from "../../../components/PeriodTypeSelector";

const columns = {
  "Week #": {
    type: "number",
  },
  "Estimated Man Hours": {
    type: "number",
  },
  "Contract Amount": {
    type: "string",
  },
  "Sales Rank": {
    type: "number",
  },
};

const report = {
  dataSource: {
    data: [columns],
  },
  slice: {
    rows: [
      {
        uniqueName: "Week #",
      },
      {
        uniqueName: "Estimated Man Hours",
      },
      {
        uniqueName: "Contract Amount",
      },
      {
        uniqueName: "Sales Rank",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Weekly Summary Report",
    },
  },
};

const WeeklySummaryReport = () => {
  const pivot = useRef(null);
  const currentYear = moment().year();

  const [yearSelected, setYearSelected] = useState({
    value: currentYear,
    label: currentYear,
  });

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect( () => {
    const fetchData = async () => {
      setLoading(true);
      const condition = {
        year: yearSelected.value
      };

      try {
        let result = await reportsApi.weeklySummary(condition);
        result.data = utils.formatCurrencyToObjectsInArray(result.data, ['Contract Amount']);
        if (result.minYear) {
          const optionsGenerated = [];
          for (let index = result.minYear; index <= currentYear; index++) {
            optionsGenerated.push({ value: index, label: index });
          }
          setOptions(optionsGenerated);
        }
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, ...result.data],
          });
        }
      } catch (error) {
        setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
          });
      }
    }
    fetchData();
  }, [
    pivot,
    currentYear,
    yearSelected,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Weekly Summary Report</h3>
              <div className="d-flex align-items-center">
                {loading ? (
                  <div className="min-width-50">
                    <Loader size="sm" className="mr-3" />
                  </div>
                ) : null}
                <PeriodTypeSelector
                  periodType={yearSelected}
                  setPeriodType={setYearSelected}
                  options= {options}
                />
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  key={yearSelected}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utils.customizeToolbarReport(
                      toolbar,
                      pivot,
                      "customer_summary_report"
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default WeeklySummaryReport;
