import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Label,
} from "reactstrap";
import Loader from "./Loader";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../utils/utils";

const SHIPPED_DATE = "SHIPPED_DATE";
const now = new Date();

const ShippedJobModal = ({ onSubmit, onClose, title, body, confirmColor }) => {
  const [loading, setLoading] = useState(false);
  const [dateSelectorOpen, setDateSelectorOpen] = useState({});
  const [shippedDate, setShippedDate] = useState(now);

  const doSubmit = () => {
    setLoading(true);
    onSubmit(shippedDate);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal size={"sm"} isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>
            <Col>
              <Label className="float-left">Shipped Date</Label>
            </Col>
            <br />
            <Col className="float-left">
              {dateSelectorOpen[SHIPPED_DATE] ? (
                <DayPicker
                  required={false}
                  defaultMonth={shippedDate}
                  className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                  mode="single"
                  selected={shippedDate}
                  modifiersClassNames={{
                    selected: "my-selected",
                    today: "my-today",
                    range_start: "my-range_start",
                    range_end: "my-range_end",
                  }}
                  onDayClick={(sentDate) => {
                    setDateSelectorOpen({ [SHIPPED_DATE]: false });
                    setShippedDate(sentDate);
                  }}
                />
              ) : (
                <div className="col-8 d-flex z-index-0 p-2 border rounded bg-white cursor-pointer">
                  <FontAwesomeIcon
                    size="lg"
                    icon={faCalendar}
                    className="z-index-0 text-secondary"
                    onClick={() =>
                      setDateSelectorOpen({ [SHIPPED_DATE]: true })
                    }
                  />
                  <span
                    onClick={() =>
                      setDateSelectorOpen({ [SHIPPED_DATE]: true })
                    }
                    className="col-8"
                  >
                    {shippedDate
                      ? utils.formatDate(shippedDate)
                      : "Select a Date ..."}
                  </span>
                </div>
              )}
            </Col>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button
          disabled={loading}
          color={confirmColor || "primary"}
          onClick={doSubmit}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShippedJobModal;
