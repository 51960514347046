import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/quote-items`;

export const quoteItemsApi = {
  getQuoteItems: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`${_endpoint}${data.id ? `/${data.id}` : ""}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createQuoteItem: (data) => {
    return axios(`${_endpoint}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemsOrder: (data) => {
    return axios(`${_endpoint}/orders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItem: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteQuoteItem: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  //quoteItemTypeQuoteItems
  createQuoteItemTypeQuoteItem: (data) => {
    return axios(`${_endpoint}/quote-item-type-quote-items`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemTypeQuoteItems: (data) => {
    return axios(`${_endpoint}/quote-item-type-quote-items/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemTypeQuoteItem: (data) => {
    return axios(`${_endpoint}/quote-item-type-quote-items/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  //labor categories
  createQuoteItemLaborCategory: (data) => {
    return axios(`${_endpoint}/quote-item-labor-categories`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemLaborCategories: (data) => {
    return axios(`${_endpoint}/quote-item-labor-categories/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemLaborCategory: (data) => {
    return axios(`${_endpoint}/quote-item-labor-categories/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  //quoteItemManagementRole
  createQuoteItemManagementRole: (data) => {
    return axios(`${_endpoint}/quote-item-management-roles`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemManagementRoles: (data) => {
    return axios(`${_endpoint}/quote-item-management-roles/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemManagementRole: (data) => {
    return axios(`${_endpoint}/quote-item-management-roles/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  //quoteItemSubcontractorRole
  createQuoteItemSubcontractorRole: (data) => {
    return axios(`${_endpoint}/quote-item-subcontractor-roles`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemSubcontractorRoles: (data) => {
    return axios(`${_endpoint}/quote-item-subcontractor-roles/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemSubcontractorRole: (data) => {
    return axios(`${_endpoint}/quote-item-subcontractor-roles/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  //quoteItemShippingHandling
  createQuoteItemShippingHandling: (data) => {
    return axios(`${_endpoint}/quote-item-shipping-handling`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemShippingHandling: (data) => {
    return axios(`${_endpoint}/quote-item-shipping-handling/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteItemShippingHandlings: (data) => {
    return axios(`${_endpoint}/quote-item-shipping-handling/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
