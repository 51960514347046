import React, { useState } from "react";
import { utils } from "../../../utils/utils";
import { Container, Button } from "reactstrap";
import SequencesTable from "./SequencesTable";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import SequenceDetailsModal from "./SequenceDetailsModal";
import { sequenceApi } from "../../../services/sequenceServices";

const WAITING_FOR_DETAILER_STATUS = 2;
const DETAILING_IN_PROGRESS_STATUS = 3;
const WAITING_FOR_DETAILING_TAB = "WAITING_FOR_DETAILING_TAB";

const WaitingForDetailing = ({
  sequencesTotal,
  setSequencesTotal,
  setReloadTabs,
  reloadTabs,
  tabName,
}) => {
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [sequenceSelected, setSequenceSelected] = useState();
  const [detailModal, setDetailModal] = useState(false);
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const columns = [
    {
      accessor: "startDetailing",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 120,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"success"}
            onClick={() => {
              changeSequenceStatus(
                rowData.row.original,
                DETAILING_IN_PROGRESS_STATUS
              );
            }}
          >
            Start Detailing
          </Button>
        );
      },
    },
    {
      accessor: "seeJobDetailsButton",
      Header: "Job Details",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 125,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original;
              setSequenceSelected(sequence);
              setSequenceStatusId(sequence.sequenceStatusId);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const onClear = async () => {
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
    setDetailModal(false);
    setSequenceSelected();
    setSequenceStatusId();
  };

  const changeSequenceStatus = async (sequence, sequenceStatusId) => {
    try {
      const startDetailing = sequenceStatusId === DETAILING_IN_PROGRESS_STATUS;
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId,
            });
            onClear();
            setInformationModal({
              isOpen: true,
              title: startDetailing ? "Start Detailing" : "Sequence Update",
              body: startDetailing
                ? "Detailing started successfully."
                : "Sequence updated successfully.",
            });
            setReloadTabs([
              WAITING_FOR_DETAILING_TAB,
              utils.detailingTabs[sequenceStatusId],
            ]);
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => setConfirmationModal(utils.initConfirmationModal),
        title: startDetailing ? "Start Detailing" : "Sequence Update",
        body: startDetailing
          ? `
          <div class="text-center">
            Are you sure you are ready to begin detailing this job?
          </div>
        `
          : `
        <div class="text-center">
          Do you confirm you want to update the sequence ${sequence.job.jobNumber}?
        </div>
      `,
        confirmColor: "warning",
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    changeSequenceStatus(sequenceSelected, sequenceStatusId);
  };

  return (
    <Container fluid className="px-0">
      <SequencesTable
        columns={columns}
        sequenceStatusId={WAITING_FOR_DETAILER_STATUS}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal(utils.initInformationModal)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : detailModal ? (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClear}
          readOnly={true}
          checkerId={
            sequenceSelected?.checker
              ? `${sequenceSelected?.checker?.firstName} ${sequenceSelected?.checker?.lastName}`
              : "-"
          }
          detailerId={
            sequenceSelected?.detailer
              ? `${sequenceSelected?.detailer?.firstName} ${sequenceSelected?.detailer?.lastName}`
              : "-"
          }
          enableUpdate={true}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      ) : null}
    </Container>
  );
};

export default WaitingForDetailing;
