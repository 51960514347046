import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { utils } from "../../../../../utils/utils";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../../../../providers/evaOverrideProvider";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useCommonData } from "../../../../../providers/commonDataProvider";

const EstimatedActualMaterialSection = () => {
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [commonDataContext] = useCommonData();
  const [quoteItemsContext] = useQuoteItems();
  const [jobDetails] = useJobDetails();
  const [quoteItemTypeQuoteItems, setQuoteItemTypeQuoteItems] = useState([]);

  const [estimatedTotal, setEstimatedTotal] = useState(0);
  const [actualTotal, setActualTotal] = useState(0);
  const [differenceTotal, setDifferenceTotal] = useState(0);

  useEffect(() => {
    const quoteItemTypeQuoteItems = utils.reduceQuoteItemTypeQuoteItems(
      quoteItemsContext.quoteItems
    );

    const items = commonDataContext.quoteItemTypeQuoteItems.map(
      (quoteItemTypeQuoteItem) => {
        const actualItem = jobDetails.job.teklaMaterialData.find(
          (item) => item.quoteItemTypeId === quoteItemTypeQuoteItem.id
        );
        const actualCost = actualItem?.cost || 0;

        const estimatedItem = quoteItemTypeQuoteItems.find(
          (item) => item.quoteItemTypeId === quoteItemTypeQuoteItem.id
        );

        const estimatedCost = estimatedItem?.cost || 0;

        return {
          ...quoteItemTypeQuoteItem,
          actualCost,
          estimatedCost,
        };
      }
    );

    setQuoteItemTypeQuoteItems(items);
  }, [
    commonDataContext.quoteItemTypeQuoteItems,
    quoteItemsContext,
    jobDetails.job,
  ]);

  useEffect(() => {
    const { totalCost, totalActual } = quoteItemTypeQuoteItems.reduce(
      (acc, quoteItemTypeQuoteItem) => {
        const actualCostOverride =
          evaOverrideContext.quoteItemTypes[quoteItemTypeQuoteItem.id];
        return {
          totalCost: acc.totalCost + quoteItemTypeQuoteItem.estimatedCost,
          totalActual:
            acc.totalActual +
            (actualCostOverride || quoteItemTypeQuoteItem.actualCost),
        };
      },
      { totalCost: 0, totalActual: 0 }
    );

    setActualTotal(totalActual);
    setEstimatedTotal(totalCost);
    setDifferenceTotal(totalCost - totalActual);
  }, [quoteItemTypeQuoteItems, evaOverrideContext.quoteItemTypes]);

  return (
    <Table className="col-12 px-0 border rounded mb-0 mt-4">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th className="align-middle" width={"45%"}>
            Type
          </th>
          <th width={"15%"} className="text-center">
            Estimated
          </th>
          <th width={"15%"} className="text-center">
            Actual
          </th>
          <th width={"15%"} className="text-center">
            Difference
          </th>
        </tr>
      </thead>
      <tbody>
        {quoteItemTypeQuoteItems
          .sort((x, y) => x.name.localeCompare(y.name))
          .map((quoteItemType) => {
            const actualCostOverride =
              evaOverrideContext.quoteItemTypes[quoteItemType.id];
            const actualCost = actualCostOverride || quoteItemType.actualCost;

            return (
              <tr key={quoteItemType.id}>
                <td className="py-0 text-truncate" width={"45%"}>
                  {quoteItemType.name}
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(quoteItemType.estimatedCost)}
                  </div>
                </td>
                <td className="py-0" width={"15%"}>
                  <Input
                    className="form-control border text-right m-1 hide-arrows"
                    type={evaOverrideContext.editEnabled ? "number" : "text"}
                    step="0.1"
                    value={
                      evaOverrideContext.editEnabled
                        ? parseFloat(actualCost)
                        : utils.formatCurrency(actualCost)
                    }
                    readOnly={!evaOverrideContext.editEnabled}
                    onChange={(evt) =>
                      setEvaOverrideContext({
                        action: EVA_OVERRIDE_ACTIONS.UPDATE_ITEM,
                        payload: {
                          id: quoteItemType.id,
                          key: "quoteItemTypes",
                          value: evt.currentTarget.value,
                        },
                      })
                    }
                  />
                </td>
                <td className="py-0" width={"15%"}>
                  <div
                    readOnly
                    className={`form-control border text-right m-1 font-weight-bold ${
                      quoteItemType.estimatedCost - actualCost < 0
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    {utils.formatCurrency(
                      quoteItemType.estimatedCost - actualCost
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        <tr className="text-muted bg-lighter">
          <th width={"45%"} className="align-middle">
            Total
          </th>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(estimatedTotal)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(actualTotal)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className={`form-control border text-right m-1 font-weight-bold ${
                differenceTotal < 0 ? "text-danger" : "text-success"
              }`}
            >
              {utils.formatCurrency(differenceTotal)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EstimatedActualMaterialSection;
