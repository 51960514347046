import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  CardFooter,
} from "reactstrap";

import UserModal from "../../components/admin/users/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers/authProvider";

import { ACTIONS, useUsers } from "../../providers/usersProvider";

import { usersApi } from "../../services/userServices";

import {
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const Users = () => {
  const [usersContext, setUsersContext] = useUsers();
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(true);

  const [userToEdit, setUserToEdit] = useState();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createUserModal, setCreateUserModal] = useState(false);

  const [editUserModal, setEditUserModal] = useState(false);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          usersContext.sortBy === sortBy.id &&
          usersContext.direction === direction
        ) {
          return;
        }
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setUsersContext, usersContext.direction, usersContext.sortBy]
  );

  const onEdit = (user) => {
    setUserToEdit(user.id);
    setEditUserModal(true);
  };

  const onDelete = (user) => {
    if (user.id === authContext.currentUser.id) {
      return setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "Can't delete yourself.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await usersApi.deleteUser(user);
          setConfirmationModal(initConfirmationModal);
          setUsersContext({
            action: ACTIONS.REFRESH,
          });
        } catch (err) {}
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete User",
      body: `<p class="text-center">Do you confirm you want to delete this user?</p>
        <span>${user.email} - ${user.role?.name}</span>`,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        search: usersContext.search,
        page: usersContext.page - 1,
        pageSize: usersContext.sizePerPage,
        sortBy: usersContext.sortBy,
        direction: usersContext.direction,
      })
      .then((users) => {
        setLoading(false);
        setUsersContext({
          action: ACTIONS.GET_USERS_SUCCESS,
          payload: { users },
        });
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setUsersContext,
    usersContext.sortBy,
    usersContext.direction,
    usersContext.sizePerPage,
    usersContext.page,
    usersContext.search,
    usersContext.refresh,
  ]);

  const columns = [
    {
      accessor: "firstName",
      Header: "First Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { firstName } = rowData.row.original;
        return firstName || "-";
      },
    },
    {
      accessor: "lastName",
      Header: "Last Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { lastName } = rowData.row.original;
        return lastName || "-";
      },
    },
    {
      accessor: "email",
      Header: "Email",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return email || "-";
      },
    },
    {
      accessor: "phoneNumber",
      Header: "Phone Number",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { phoneNumber } = rowData.row.original;
        return phoneNumber || "-";
      },
    },
    {
      accessor: "role.name",
      Header: "Role",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        return rowData.row.original.role.name || "-";
      },
    },
    {
      accessor: "jobNumberSuffix",
      Header: "Job Number Suffix",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { jobNumberSuffix } = rowData.row.original;
        return jobNumberSuffix || "-";
      },
    },
    {
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => (
        <div>
          <Button
            size="sm"
            className="rounded"
            color="warning"
            onClick={() => onEdit(rowData.row.original)}
          >
            <span>Edit</span>
            <FontAwesomeIcon icon={faPen} className="ml-2" />
          </Button>{" "}
          <Button
            size="sm"
            className="rounded"
            color="danger"
            onClick={() => onDelete(rowData.row.original)}
          >
            <span>Delete</span>
            <FontAwesomeIcon icon={faTrash} className="ml-2" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={usersContext.users.data || []}
          pageSize={usersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: usersContext.sortBy,
            direction: usersContext.direction,
          }}
        >
          <Card className="mb-3 w-100 overflow-x-auto">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Users</h3>
                <small className="text-muted ml-2 pt-1">
                  ({usersContext.users.count})
                </small>
              </div>
              <div className=" d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    maxLength="50"
                    placeholder="Search for.."
                    value={usersContext.search}
                    onChange={(evt) =>
                      setUsersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-jobs input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle custom-rounded-button d-flex "
                  color="primary"
                  onClick={() => setCreateUserModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex "
                  color="primary"
                  onClick={() =>
                    setUsersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={usersContext.users.count}
                pageCount={usersContext.users.totalPages}
                currentPage={usersContext.page - 1}
                onPageChange={(page) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={usersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createUserModal ? (
        <UserModal
          onClose={() => {
            setCreateUserModal(false);
          }}
          onSubmit={() => {
            setCreateUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create User",
              body: "User created successfully.",
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : editUserModal ? (
        <UserModal
          userId={userToEdit}
          onClose={() => {
            setUserToEdit(null);
            setEditUserModal(false);
          }}
          onSubmit={() => {
            setUserToEdit(null);
            setEditUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update User",
              body: "User Updated Successfully.",
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default Users;
