import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/order-acknowledgment`;

export const orderAcknowledgmentApi = {
  getAll: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`${_endpoint}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  create: (data) => {
    if (data.salesTax === "") {
      delete data.salesTax;
    }
    return axios(_endpoint, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  update: (data) => {
    if (data.salesTax === "") {
      delete data.salesTax;
    }
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  uploadAttachments: (data) => {
    const url = `${_endpoint}/upload-attachments`;
    return axios(url, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getSummaryReport: (data) => {
    return axios(`${_endpoint}/pdf-summary-report/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  delete: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => console.log(err));
  },
};
