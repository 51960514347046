import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Table,
} from "reactstrap";
import { ACTIONS, useQuoteItem } from "../../../../providers/quoteItemProvider";
import {
  ACTIONS as QUOTE_ITEMS_ACTIONS,
  useQuoteItems,
} from "../../../../providers/quoteItemsProvider";
import { quoteItemsApi } from "../../../../services/quoteItemServices";
import { utils } from "../../../../utils/utils";
import ConfirmationModal from "../../../ConfirmationModal";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import QuoteItemLaborCategories from "./QuoteItemLaborCategories";
import QuoteItemManagementRoles from "./QuoteItemManagementRoles";
import QuoteItemShippingHandlings from "./QuoteItemShippingHandlings";
import QuoteItemSubcontractorRoles from "./QuoteItemSubcontractorRoles";
import QuoteItemTypes from "./QuoteItemTypes";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const MAX_COST_VALUE = 5000000;
const MAX_WEIGHT_VALUE = 10000000;
const MAX_HOURS_VALUE = 99999;

const QuoteItem = () => {
  const [quoteItemContext, setQuoteItemContext] = useQuoteItem();
  const [, setQuoteItemsContext] = useQuoteItems();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState();
  const [description, setDescription] = useState();

  const [quoteItemTypeQuoteItems, setQuoteItemTypeQuoteItems] = useState({});
  const [quoteItemShippingHandlings, setQuoteItemShippingHandlings] = useState(
    {}
  );
  const [quoteItemSubcontractorRoles, setQuoteItemSubcontractorRoles] =
    useState({});
  const [quoteItemLaborCategories, setQuoteItemLaborCategories] = useState({});
  const [quoteItemManagementRoles, setQuoteItemManagementRoles] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    if (quoteItemContext.quoteItem) {
      setQuantity(quoteItemContext.quoteItem.quantity);
      setDescription(quoteItemContext.quoteItem.description);
    }
  }, [quoteItemContext.quoteItem]);

  const archiveQuoteItem = async () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        try {
          const data = utils.removeArrays(quoteItemContext.quoteItem);
          await quoteItemsApi.updateQuoteItem({
            ...data,
            archived: true,
          });
          setInformationModal({
            isOpen: true,
            title: "Archive Quote Item",
            body: "Item archived successfully.",
            onClose: () => {
              setInformationModal({ isOpen: false });
              setQuoteItemContext({
                action: ACTIONS.REFRESH,
              });
              setQuoteItemsContext({
                action: QUOTE_ITEMS_ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setInformationModal({
            isOpen: true,
            title: "Archive Quote Item",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Archive Quote Item",
      body: `
            <div class="text-center">
                Do you confirm you want to archive this item?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const unarchiveQuoteItem = async () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        try {
          const data = utils.removeArrays(quoteItemContext.quoteItem);
          await quoteItemsApi.updateQuoteItem({
            ...data,
            archived: false,
          });
          setInformationModal({
            isOpen: true,
            title: "Unarchive Quote Item",
            body: "Item unarchived successfully.",
            onClose: () => {
              setInformationModal({ isOpen: false });
              setQuoteItemContext({
                action: ACTIONS.REFRESH,
              });
              setQuoteItemsContext({
                action: QUOTE_ITEMS_ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setInformationModal({
            isOpen: true,
            title: "Unarchive Quote Item",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Unarchive Quote Item",
      body: `
            <div class="text-center">
                Do you confirm you want to unarchived this item?
            </div>
      `,
      confirmColor: "warning",
    });
  };

  const validateField = (array, field, maxValue, errorText) => {
    for (const value of Object.values(array)) {
      if (parseFloat(value[field]) > maxValue) {
        throw Error(errorText);
      }
    }
  };

  const validateValues = () => {
    try {
      validateField(
        quoteItemTypeQuoteItems,
        "cost",
        MAX_COST_VALUE,
        "Invalid cost. Valid range between $0 and $5,000,000.00"
      );
      validateField(
        quoteItemTypeQuoteItems,
        "weight",
        MAX_WEIGHT_VALUE,
        "Invalid weight. Valid range between 0 lbs and 1,000,000 lbs"
      );
      validateField(
        quoteItemSubcontractorRoles,
        "cost",
        MAX_COST_VALUE,
        "Invalid cost. Valid range between $ 0 and $5,000,000.00"
      );
      validateField(
        quoteItemShippingHandlings,
        "cost",
        MAX_COST_VALUE,
        "Invalid cost. Valid range between $ 0 and $5,000,000.00"
      );
      validateField(
        quoteItemLaborCategories,
        "hours",
        MAX_HOURS_VALUE,
        "Invalid hours. Valid range between 0 and 99,999"
      );
      validateField(
        quoteItemManagementRoles,
        "hours",
        MAX_HOURS_VALUE,
        "Invalid hours. Valid range between 0 and 99,999"
      );
      return true;
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Update Quote Item",
        body: error,
      });
      return false;
    }
  };

  const onSubmit = async () => {
    try {
      if (!validateValues()) {
        return;
      }
      setLoading(true);
      const data = utils.removeArrays(quoteItemContext.quoteItem);
      await quoteItemsApi.updateQuoteItem({
        ...data,
        quantity,
        description,
      });
      await quoteItemsApi.updateQuoteItemTypeQuoteItems({
        data: Object.values(quoteItemTypeQuoteItems),
      });
      await quoteItemsApi.updateQuoteItemSubcontractorRoles({
        data: Object.values(quoteItemSubcontractorRoles),
      });
      await quoteItemsApi.updateQuoteItemShippingHandlings({
        data: Object.values(quoteItemShippingHandlings),
      });
      await quoteItemsApi.updateQuoteItemLaborCategories({
        data: Object.values(quoteItemLaborCategories),
      });
      await quoteItemsApi.updateQuoteItemManagementRoles({
        data: Object.values(quoteItemManagementRoles),
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update Quote Item",
        body: "Quote Item Updated Successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false });
          setQuoteItemContext({
            action: ACTIONS.REFRESH,
          });
          setQuoteItemsContext({
            action: QUOTE_ITEMS_ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update Quote Item",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  return loading || !quoteItemContext.quoteItem ? (
    <Loader size="sm" />
  ) : (
    <div className="mt-3 d-flex flex-column">
      <Table className="col-12 px-0 border rounded mb-3">
        <thead className="small">
          <tr className="text-muted bg-lighter">
            <th className="align-middle px-3">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-0">
              <Input
                className="border m-1"
                placeholder="Enter description..."
                readOnly={isJobLocked}
                type="text"
                maxLength="255"
                name="description"
                value={description || ""}
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <QuoteItemTypes
        quoteItemTypeQuoteItems={quoteItemTypeQuoteItems}
        setQuoteItemTypeQuoteItems={setQuoteItemTypeQuoteItems}
      />
      <Row className="d-flex mt-4">
        <Col>
          <QuoteItemSubcontractorRoles
            quoteItemSubcontractorRoles={quoteItemSubcontractorRoles}
            setQuoteItemSubcontractorRoles={setQuoteItemSubcontractorRoles}
          />
        </Col>
        <Col>
          <QuoteItemShippingHandlings
            quoteItemShippingHandlings={quoteItemShippingHandlings}
            setQuoteItemShippingHandlings={setQuoteItemShippingHandlings}
          />
        </Col>
      </Row>
      <Row className="d-flex mt-4">
        <Col>
          <QuoteItemLaborCategories
            quoteItemLaborCategories={quoteItemLaborCategories}
            setQuoteItemLaborCategories={setQuoteItemLaborCategories}
          />
        </Col>
        <Col>
          <QuoteItemManagementRoles
            quoteItemManagementRoles={quoteItemManagementRoles}
            setQuoteItemManagementRoles={setQuoteItemManagementRoles}
          />
        </Col>
      </Row>
      <ListGroup>
        <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter mt-4">
          <small className="font-weight-bold text-muted">
            Quote Items Quantity
          </small>
          <div className="d-flex align-items-center">
            <Input
              className="border width-150"
              placeholder="Enter a quantity..."
              type="number"
              readOnly={isJobLocked}
              min="0"
              max="99999"
              name="hours"
              value={quantity || ""}
              onChange={(e) => setQuantity(e.currentTarget.value)}
            />
          </div>
        </ListGroupItem>
      </ListGroup>
      {loading ? (
        <div className="min-width-50">
          <Loader size="sm" align="end" />
        </div>
      ) : isJobLocked ? null : (
        <div className="d-flex flex-column">
          <Button className="rounded mt-3" color="success" onClick={onSubmit}>
            Save
          </Button>
          {quoteItemContext.quoteItem.archived ? (
            <Button
              className="rounded mt-3"
              color="warning"
              onClick={unarchiveQuoteItem}
            >
              Unarchive
            </Button>
          ) : (
            <Button
              className="rounded mt-3"
              color="danger"
              onClick={archiveQuoteItem}
            >
              Archive
            </Button>
          )}
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default QuoteItem;
