import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  workOrder: {},
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_WORK_ORDER_SUCCESS: "GET_WORK_ORDERS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORK_ORDER_SUCCESS: {
      const { payload } = data;
      const { workOrder } = payload;

      return {
        ...state,
        workOrder,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const WorkOrdersContext = createContext(initialState);

const WorkOrdersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <WorkOrdersContext.Provider value={stateAndDispatch}>
      {children}
    </WorkOrdersContext.Provider>
  );
};

export const useWorkOrder = () => useContext(WorkOrdersContext);

export { WorkOrdersContext, WorkOrdersProvider, ACTIONS };
