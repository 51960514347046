import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Button, Input, Table } from "reactstrap";
import { useQuoteItem, ACTIONS } from "../../../../providers/quoteItemProvider";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";
import QuoteItemEditOptionsModal from "./QuoteItemEditOptionsModal";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const QuoteItemTypes = ({
  quoteItemTypeQuoteItems,
  setQuoteItemTypeQuoteItems,
}) => {
  const [quoteItemContext, setQuoteItemContext] = useQuoteItem();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [totalCost, setTotalCost] = useState();
  const [totalWeight, setTotalWeight] = useState();
  const [editOptionsModal, setEditOptionsModal] = useState();

  useEffect(() => {
    const quoteItemTypeQuoteItems =
      quoteItemContext.quoteItem.quoteItemTypeQuoteItems.reduce((p, c) => {
        p[c.id] = c;
        return p;
      }, {});
    setQuoteItemTypeQuoteItems(quoteItemTypeQuoteItems);
  }, [
    quoteItemContext.quoteItem,
    quoteItemContext.quoteItem.quoteItemTypeQuoteItems,
    setQuoteItemTypeQuoteItems,
  ]);

  useEffect(() => {
    const totalWeight = Object.values(quoteItemTypeQuoteItems).reduce(
      (p, c) => p + parseFloat(c.weight || 0),
      0
    );
    const totalCost = Object.values(quoteItemTypeQuoteItems).reduce(
      (p, c) => p + parseFloat(c.cost || 0),
      0
    );
    setTotalCost(totalCost);
    setTotalWeight(totalWeight);
  }, [quoteItemTypeQuoteItems]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onFieldChange = (quoteItemTypeId, field, value) =>
    setQuoteItemTypeQuoteItems({
      ...quoteItemTypeQuoteItems,
      [quoteItemTypeId]: {
        ...quoteItemTypeQuoteItems[quoteItemTypeId],
        [field]: value || 0,
      },
    });

  return (
    <Table className="col-12 px-0 border rounded mb-0">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th className="align-middle">Type</th>
          <th className="align-middle px-3">Description</th>
          <th className="align-middle px-3">Weight</th>
          <th className="align-middle px-3">Cost</th>
          {isJobLocked ? null : (
            <th className="align-middle text-right">
              <div className="d-flex justify-content-end">
                <Button
                  className="rounded"
                  size="sm"
                  onClick={() => setEditOptionsModal(true)}
                >
                  Edit Options
                </Button>
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {quoteItemContext.quoteItem.quoteItemTypeQuoteItems
          .sort((x, y) =>
            x.quoteItemType.name < y.quoteItemType.name ? -1 : 1
          )
          .map((quoteItemTypeQuoteItem) => {
            const description =
              quoteItemTypeQuoteItems[quoteItemTypeQuoteItem.id]?.description ||
              "";
            const weight =
              quoteItemTypeQuoteItems[quoteItemTypeQuoteItem.id]?.weight || "";
            const cost =
              quoteItemTypeQuoteItems[quoteItemTypeQuoteItem.id]?.cost || "";
            return (
              <tr key={quoteItemTypeQuoteItem.id}>
                <td className="py-0">
                  {quoteItemTypeQuoteItem.quoteItemType.name}
                </td>
                <td className="py-0">
                  <Input
                    className="border m-1"
                    placeholder={
                      isJobLocked ? "No description" : "Enter description..."
                    }
                    type="text"
                    readOnly={isJobLocked}
                    maxLength="255"
                    name="description"
                    value={description}
                    onChange={(e) =>
                      setQuoteItemTypeQuoteItems({
                        ...quoteItemTypeQuoteItems,
                        [quoteItemTypeQuoteItem.id]: {
                          ...quoteItemTypeQuoteItems[quoteItemTypeQuoteItem.id],
                          description: e.currentTarget.value,
                        },
                      })
                    }
                  />
                </td>
                <td className="py-0">
                  <NumericFormat
                    required={true}
                    readOnly={isJobLocked}
                    maxLength={20}
                    allowNegative={false}
                    decimalScale={2}
                    className="form-control border m-1"
                    value={weight}
                    placeholder={"Enter the weight..."}
                    thousandSeparator={true}
                    prefix={""}
                    suffix={" lb"}
                    onFocus={() =>
                      isJobLocked ||
                      onFieldChange(quoteItemTypeQuoteItem.id, "weight", 0)
                    }
                    onValueChange={(e) =>
                      onFieldChange(
                        quoteItemTypeQuoteItem.id,
                        "weight",
                        e.value
                      )
                    }
                  />
                </td>
                <td className="py-0" colSpan={2}>
                  <NumericFormat
                    required={true}
                    readOnly={isJobLocked}
                    maxLength={20}
                    allowNegative={false}
                    decimalScale={2}
                    className="form-control border m-1"
                    value={cost}
                    placeholder={"Enter the cost..."}
                    thousandSeparator={true}
                    prefix={"$"}
                    onFocus={() =>
                      isJobLocked ||
                      onFieldChange(quoteItemTypeQuoteItem.id, "cost", 0)
                    }
                    onValueChange={(e) =>
                      onFieldChange(quoteItemTypeQuoteItem.id, "cost", e.value)
                    }
                  />
                </td>
              </tr>
            );
          })}
        <tr>
          <td className="font-weight-bold py-0">Total</td>
          <td className="py-0"></td>
          <td className="py-0">
            <div readOnly className="form-control border m-1">
              {utils.formatWeight(totalWeight)}
            </div>
          </td>
          <td className="py-0" colSpan={2}>
            <div readOnly className="form-control border m-1">
              {utils.formatCurrency(totalCost)}
            </div>
          </td>
        </tr>
      </tbody>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : editOptionsModal ? (
        <QuoteItemEditOptionsModal
          title="Types"
          field="quoteItemTypeQuoteItems"
          idField="quoteItemTypeId"
          quoteItem={quoteItemContext.quoteItem}
          onSubmit={() => {
            setEditOptionsModal(false);
            setQuoteItemContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setEditOptionsModal(false)}
        />
      ) : null}
    </Table>
  );
};

export default QuoteItemTypes;
