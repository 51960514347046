import React from "react";
import { Table } from "reactstrap";

const QuoteRecapItemQuantity = ({ quoteItem }) => {
  return (
    <Table className="col-12 px-0 mt-4 border rounded mb-0">
      <thead>
        <tr className="bg-lighter">
          <th className="py-0 align-middle border-bottom-0" width={"87%"}>
            <small className="font-weight-bold text-muted">
              Quote Items Quantity
            </small>
          </th>
          <th className="py-0 border-bottom-0" width={"13%"}>
            <div readOnly className="form-control border text-right m-1">
              {quoteItem.quantity || ""}
            </div>
          </th>
        </tr>
      </thead>
    </Table>
  );
};

export default QuoteRecapItemQuantity;
