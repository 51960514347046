import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

export const syncApi = {
  getCrewOsSyncs: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id ? `/api/crewos-sync/${data.id}` : `/api/crewos-sync?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  doCrewOsSync: () => {
    return axios(`/api/crewos-sync/trigger`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },

  getTASyncs: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/time-attendance-sync/${data.id}`
        : `/api/time-attendance-sync?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  doTASync: () => {
    return axios(`/api/time-attendance-sync/trigger`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },

  doTeklaSync: () => {
    return axios(`/api/tekla/doSync`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },

  getTeklaData: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/tekla/?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
