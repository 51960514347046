import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";
const DETAILER_CHECKER_ROLE_ID = 4;

const redirectTo = (location, route, roleId) => {
  const isDetailerChecker = roleId === DETAILER_CHECKER_ROLE_ID;
  if (!isDetailerChecker && location.search) {
    const params = new URLSearchParams(location.search);
    return <Navigate to={params.get("returnURL")} />;
  }
  return <Navigate to={route} />;
};

const SignIn = () => {
  const location = useLocation();
  const [authContext] = useAuth();
  const roleId = authContext.currentUser?.role?.id;

  if (!authContext.currentUser) {
    return <LoginForm />;
  }

  const route =
    roleId === DETAILER_CHECKER_ROLE_ID
      ? "/detailer-checker-dashboard"
      : "/rfq/all";
  return redirectTo(location, route, roleId);
};

export default SignIn;
