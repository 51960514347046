import React, { useState } from "react";
import TooltipItem from "../../TooltipItem";
import { utils } from "../../../utils/utils";
import SequencesTable from "./SequencesTable";
import { Container, Button } from "reactstrap";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import SequenceDetailsModal from "./SequenceDetailsModal";
import { sequenceApi } from "../../../services/sequenceServices";

const WAITING_FOR_ASSIGNMENT_STATUS = 1;
const WAITING_FOR_DETAILER_STATUS = 2;
const NEW_JOBS_TAB = "NEW_JOBS_TAB";
const WAITING_FOR_DETAILING_TAB = "WAITING_FOR_DETAILING_TAB";
const DEFAULT_TABS_TO_LOAD = [NEW_JOBS_TAB, WAITING_FOR_DETAILING_TAB];

const NewJobs = ({
  sequencesTotal,
  setSequencesTotal,
  reloadTabs,
  setReloadTabs,
  tabName,
}) => {
  const [readOnly, setReadOnly] = useState();
  const [checkerId, setCheckerId] = useState();
  const [detailerId, setDetailerId] = useState();
  const [sequenceSelected, setSequenceSelected] = useState();
  const [detailModal, setDetailModal] = useState(false);
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const columns = [
    {
      id: "assignJobButton",
      accessor: "assignJobButton",
      Header: "Assign Job",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 100,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"success"}
            onClick={() => {
              const sequence = rowData.row.original;
              setSequenceSelected(sequence);
              setDetailerId(sequence?.detailer?.id || null);
              setCheckerId(sequence?.checker?.id || null);
              setDetailModal(true);
            }}
          >
            Assign Job
          </Button>
        );
      },
    },
    {
      id: "sendToDetailerButton",
      accessor: "sendToDetailerButton",
      Header: "Send Job",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 130,
      Cell: (rowData) => {
        const sequence = rowData.row.original;
        const validation = !sequence?.detailer || !sequence?.checker;
        const tooltipMessage = `Missing assign ${
          !sequence?.detailer ? "Detailer" : "Checker"
        }`;
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"warning"}
            onClick={() => {
              changeSequenceStatus(sequence);
            }}
            disabled={validation}
          >
            <TooltipItem
              className="flex-grow-1"
              id={"tooltip-" + sequence?.id}
              title={validation ? tooltipMessage : null}
            >
              Send to Detailer
            </TooltipItem>
          </Button>
        );
      },
    },
    {
      id: "seeJobDetailsButton",
      accessor: "seeJobDetailsButton",
      Header: "Job Details",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 125,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original;
              const detailer = sequence?.detailer;
              const checker = sequence?.checker;
              setSequenceSelected(sequence);
              setDetailerId(
                detailer ? `${detailer?.firstName} ${detailer?.lastName}` : "-"
              );
              setCheckerId(
                checker ? `${checker?.firstName} ${checker?.lastName}` : "-"
              );
              setSequenceStatusId(sequence.sequenceStatusId);
              setReadOnly(true);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setDetailModal(false);
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            const data = readOnly
              ? {
                  ...sequenceSelected,
                  sequenceStatusId,
                }
              : {
                  ...sequenceSelected,
                  detailerId,
                  checkerId,
                };
            await sequenceApi.updateSequence(sequenceSelected.id, data);
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Sequence Update",
              body: "Sequence updated successfully.",
            });
            setReloadTabs(
              readOnly
                ? [NEW_JOBS_TAB, utils.detailingTabs[sequenceStatusId]]
                : [NEW_JOBS_TAB]
            );
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => setConfirmationModal(utils.initConfirmationModal),
        title: "Sequence Update",
        body: `
          <div class="text-center">
            Do you confirm you want to update the sequence ${sequenceSelected?.job?.jobNumber}?
          </div>
        `,
        confirmColor: "warning",
      });
    } catch (error) {
      onClear();
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onClear = async () => {
    setDetailerId();
    setCheckerId();
    setSequenceSelected();
    setDetailModal(false);
    setReadOnly(false);
    setSequenceStatusId();
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
  };

  const changeSequenceStatus = async (sequence) => {
    try {
      setDetailModal(false);
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          setReloadTabs([]);
          await sequenceApi.updateSequence(sequence.id, {
            ...sequence,
            sequenceStatusId: WAITING_FOR_DETAILER_STATUS,
          });
          onClear();
          setInformationModal({
            isOpen: true,
            title: "Send to Detailer",
            body: "Sequence sent to Detailer successfully.",
          });
          setReloadTabs(DEFAULT_TABS_TO_LOAD);
        },
        onClose: () => setConfirmationModal(utils.initConfirmationModal),
        title: "Send to Detailer",
        body: `
          <div class="text-center">
            Do you confirm you want to send the sequence ${sequence?.job?.jobNumber} to Detailer?
          </div>
        `,
        confirmColor: "warning",
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  return (
    <Container fluid className="px-0">
      <SequencesTable
        columns={columns}
        sequenceStatusId={WAITING_FOR_ASSIGNMENT_STATUS}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal(utils.initInformationModal)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClear}
          readOnly={readOnly}
          setInformationModal={setInformationModal}
          checkerId={checkerId}
          setCheckerId={setCheckerId}
          detailerId={detailerId}
          setDetailerId={setDetailerId}
          enableUpdate={readOnly}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      )}
    </Container>
  );
};

export default NewJobs;
