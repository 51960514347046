import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  quoteItem: null,
};

const ACTIONS = {
  ON_INIT: "ON_INIT",
  REFRESH: "REFRESH",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.ON_INIT: {
      const { payload } = data;
      const { quoteItem } = payload;

      return {
        ...state,
        refresh: false,
        quoteItem,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: true,
      };

    default:
      return {
        ...initialState,
      };
  }
};

const quoteItem = createContext(initialState);

const QuoteItemProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <quoteItem.Provider value={stateAndDispatch}>{children}</quoteItem.Provider>
  );
};

export const useQuoteItem = () => useContext(quoteItem);

export { quoteItem, QuoteItemProvider, ACTIONS };
