import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { usersApi } from "../../../services/userServices";
import { rolesApi } from "../../../services/roleServices";

import InformationModal from "../../InformationModal";

import Loader from "../../Loader";

const UserModal = ({ userId, onClose, onSubmit }) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userRoleId: "",
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [password, setPassword] = useState();

  const [loading, setLoading] = useState();

  const [roles, setRoles] = useState([]);

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (user.id) {
      const {
        id,
        firstName,
        lastName,
        email,
        phoneNumber,
        jobNumberSuffix,
        userRoleId,
        userId,
      } = user;
      const promises = [
        usersApi.updateUser({
          id,
          firstName: userId ? "" : firstName,
          lastName: userId ? "" : lastName,
          email,
          jobNumberSuffix,
          phoneNumber,
          userId,
          userRoleId,
        }),
      ];
      if (password) {
        promises.push(
          usersApi.updatePassword({
            id,
            password,
          })
        );
      }
      try {
        await Promise.all(promises);
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update User",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    } else {
      try {
        await usersApi.createUser({
          ...user,
          password,
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Create User",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  // Load User
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await usersApi.getUsers({ id: userId });
        setLoading(false);
        setUser(result);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    if (userId) {
      fetchData();
    }
  }, [userId]);

  // Load Roles
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await rolesApi.getRoles({});
        setLoading(false);
        setRoles(data);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {userId ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label for="firstName">
                    <span>First Name</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="text"
                    name="firstName"
                    placeholder="First Name..."
                    value={user.user?.firstName || user.firstName}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        firstName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">
                    <span>Last Name</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="text"
                    name="lastName"
                    placeholder="Last Name..."
                    value={user.user?.lastName || user.lastName}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        lastName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    <span>Email</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="email"
                    name="email"
                    placeholder="Email..."
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phoneNumber">
                    <span>Phone Number</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number..."
                    value={user.phoneNumber || ""}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        phoneNumber: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="jobNumberSuffix">
                    <span>Job Number Suffix</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="text"
                    name="jobNumberSuffix"
                    placeholder="Job Number Suffix..."
                    value={user.jobNumberSuffix}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        jobNumberSuffix: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="role">
                    <span>Role</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    type="select"
                    name="roleSelect"
                    placeholder="Role..."
                    id="roleSelect"
                    onChange={(e) =>
                      setUser({
                        ...user,
                        userRoleId: parseInt(e.target.value),
                      })
                    }
                    value={user.userRoleId}
                    required
                  >
                    <option value={""}>Select a Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="password">
                    <span>Password</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="password"
                    name="password"
                    placeholder="Password..."
                    autoComplete="new-password"
                    value={password || ""}
                    onChange={(e) => setPassword(e.target.value)}
                    required={!user.id}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
