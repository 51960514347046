import React, { useReducer, createContext, useContext, useEffect } from "react";

const initialState = {
  quoteItemTypeQuoteItems: [],
  quoteItemManagementRoles: [],
  quoteItemSubcontractorRoles: [],
  quoteItemLaborCategories: [],
  quoteItemShippingHandlings: [],
};

const ACTIONS = {
  GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
  FILTER_SUBCONTRACTOR_ROLES_ITEM: "FILTER_SUBCONTRACTOR_ROLES_ITEM",
  FILTER_MANAGEMENT_ROLES_ITEM: "FILTER_MANAGEMENT_ROLES_ITEM",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_DATA_SUCCESS: {
      const { payload } = data;
      const {
        quoteItemTypeQuoteItems,
        quoteItemManagementRoles,
        quoteItemSubcontractorRoles,
        quoteItemShippingHandlings,
        quoteItemLaborCategories,
      } = payload;

      return {
        ...state,
        quoteItemTypeQuoteItems,
        quoteItemManagementRoles,
        quoteItemSubcontractorRoles,
        quoteItemShippingHandlings,
        quoteItemLaborCategories,
      };
    }

    case ACTIONS.FILTER_SUBCONTRACTOR_ROLES_ITEM: {
      const { payload } = data;

      const quoteItemSubcontractorRoles =
        state.quoteItemSubcontractorRoles.filter((quoteItem) => {
          return !payload.includes(quoteItem.id);
        });

      return {
        ...state,
        quoteItemSubcontractorRoles,
      };
    }

    case ACTIONS.FILTER_MANAGEMENT_ROLES_ITEM: {
      const { payload } = data;

      const quoteItemManagementRoles = state.quoteItemManagementRoles.filter(
        (quoteItem) => {
          return !payload.includes(quoteItem.code);
        }
      );

      return {
        ...state,
        quoteItemManagementRoles,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const commonData = createContext(initialState);

const PROJECT_MANAGEMENT_CODE = "32";
const PROJECT_COORDINATOR_CODE = "33";
const SUBCONTRACTOR_MISCELLANEOUS_ID = 5;
const SUBCONTRACTOR_OTHER_ID = 326;

const CommonDataProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  const [state, dispatch] = stateAndDispatch;

  useEffect(() => {
    const needsFilteringSubcontractor = [
      SUBCONTRACTOR_MISCELLANEOUS_ID,
      SUBCONTRACTOR_OTHER_ID,
    ].some((id) =>
      state.quoteItemSubcontractorRoles?.some((quoteItem) => quoteItem.id === id)
    );

    const needsFilteringManagement = [
      PROJECT_MANAGEMENT_CODE,
      PROJECT_COORDINATOR_CODE,
    ].some((code) =>
      state.quoteItemManagementRoles?.some(
        (quoteItem) => quoteItem.code === code
      )
    );

    if (needsFilteringSubcontractor) {
      dispatch({
        action: ACTIONS.FILTER_SUBCONTRACTOR_ROLES_ITEM,
        payload: [SUBCONTRACTOR_MISCELLANEOUS_ID, SUBCONTRACTOR_OTHER_ID],
      });
    }

    if (needsFilteringManagement) {
      dispatch({
        action: ACTIONS.FILTER_MANAGEMENT_ROLES_ITEM,
        payload: [PROJECT_MANAGEMENT_CODE, PROJECT_COORDINATOR_CODE],
      });
    }
  }, [
    state.quoteItemSubcontractorRoles,
    state.quoteItemManagementRoles,
    dispatch,
  ]);

  return (
    <commonData.Provider value={stateAndDispatch}>
      {children}
    </commonData.Provider>
  );
};

export const useCommonData = () => useContext(commonData);

export { commonData, CommonDataProvider, ACTIONS };
