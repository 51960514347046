import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/job-contract-specification`;

export const jobContractSpecificationAPI = {
  createJobContractSpecification: (data) => {
    return axios(`${_endpoint}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },

  updateJobContractSpecification: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
