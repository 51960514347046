import React, { useState } from "react";
import classnames from "classnames";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import NewJobs from "../../components/admin/detailing/NewJobs";
import CheckingComplete from "../../components/admin/detailing/CheckingComplete";
import RevisionGenerated from "../../components/admin/detailing/RevisionGenerated";
import CheckingInProgress from "../../components/admin/detailing/CheckingInProgress";
import WaitingForChecking from "../../components/admin/detailing/WaitingForChecking";
import WaitingForDetailing from "../../components/admin/detailing/WaitingForDetailing";
import DetailingInProgress from "../../components/admin/detailing/DetailingInProgress";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const NEW_JOBS_TAB = "NEW_JOBS_TAB";
const WAITING_FOR_DETAILING_TAB = "WAITING_FOR_DETAILING_TAB";
const DETAILING_TAB = "DETAILING_TAB";
const WAITING_FOR_CHECKING_TAB = "WAITING_FOR_CHECKING_TAB";
const CHECKING_IN_PROGRESS_TAB = "CHECKING_IN_PROGRESS_TAB";
const CHECKING_COMPLETE_TAB = "CHECKING_COMPLETE_TAB";
const REVISION_GENERATED_TAB = "REVISION_GENERATED_TAB";
const DEFAULT_TABS_TO_LOAD = [
  NEW_JOBS_TAB,
  WAITING_FOR_DETAILING_TAB,
  DETAILING_TAB,
  WAITING_FOR_CHECKING_TAB,
  CHECKING_IN_PROGRESS_TAB,
  CHECKING_COMPLETE_TAB,
  REVISION_GENERATED_TAB,
];

const Detailing = () => {
  const [reloadTabs, setReloadTabs] = useState(DEFAULT_TABS_TO_LOAD);
  const [sequencesTotal, setSequencesTotal] = useState(0);
  const [detailingTotal, setDetailingTotal] = useState(0);
  const [checkingCompleteTotal, setCheckingCompleteTotal] = useState(0);
  const [revisionGeneratedTotal, setRevisionGeneratedTotal] = useState(0);
  const [waitingForCheckingTotal, setWaitingForCheckingTotal] = useState(0);
  const [checkingInProgressTotal, setCheckingInProgressTotal] = useState(0);
  const [waitingForDetailingTotal, setWaitingForDetailingTotal] = useState(0);
  const [selectedTab, setSelectedTab] = useState(NEW_JOBS_TAB);

  return (
    <Container fluid>
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-column align-items-start">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Jobs In Detailing
          </span>
        </HeaderTitle>
      </Header>
      <Row>
        <Col className="flex-grow-1">
          <Nav
            tabs
            className="d-flex text-center flex-nowrap job-details-nav cursor-pointer mt-3"
          >
            <div className="d-flex flex-grow-1">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === NEW_JOBS_TAB,
                  })}
                  onClick={() => setSelectedTab(NEW_JOBS_TAB)}
                >
                  {`New Jobs (${sequencesTotal})`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === WAITING_FOR_DETAILING_TAB,
                  })}
                  onClick={() => setSelectedTab(WAITING_FOR_DETAILING_TAB)}
                >
                  {`Waiting for Detailing (${waitingForDetailingTotal})`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === DETAILING_TAB,
                  })}
                  onClick={() => setSelectedTab(DETAILING_TAB)}
                >
                  {`Detailing (${detailingTotal})`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === WAITING_FOR_CHECKING_TAB,
                  })}
                  onClick={() => setSelectedTab(WAITING_FOR_CHECKING_TAB)}
                >
                  {`Waiting for Check (${waitingForCheckingTotal})`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === CHECKING_IN_PROGRESS_TAB,
                  })}
                  onClick={() => setSelectedTab(CHECKING_IN_PROGRESS_TAB)}
                >
                  {`Checking (${checkingInProgressTotal})`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === CHECKING_COMPLETE_TAB,
                  })}
                  onClick={() => setSelectedTab(CHECKING_COMPLETE_TAB)}
                >
                  {`Ready for Review (${checkingCompleteTotal})`}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: selectedTab === REVISION_GENERATED_TAB,
                  })}
                  onClick={() => setSelectedTab(REVISION_GENERATED_TAB)}
                >
                  {`Rev Generated (${revisionGeneratedTotal})`}
                </NavLink>
              </NavItem>
            </div>
          </Nav>
          <TabContent activeTab={selectedTab}>
            <TabPane tabId={NEW_JOBS_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <NewJobs
                    setSequencesTotal={setSequencesTotal}
                    sequencesTotal={sequencesTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={NEW_JOBS_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId={WAITING_FOR_DETAILING_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <WaitingForDetailing
                    setSequencesTotal={setWaitingForDetailingTotal}
                    sequencesTotal={waitingForDetailingTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={WAITING_FOR_DETAILING_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId={DETAILING_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <DetailingInProgress
                    setSequencesTotal={setDetailingTotal}
                    sequencesTotal={detailingTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={DETAILING_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId={WAITING_FOR_CHECKING_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <WaitingForChecking
                    setSequencesTotal={setWaitingForCheckingTotal}
                    sequencesTotal={waitingForCheckingTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={WAITING_FOR_CHECKING_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId={CHECKING_IN_PROGRESS_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <CheckingInProgress
                    setSequencesTotal={setCheckingInProgressTotal}
                    sequencesTotal={checkingInProgressTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={CHECKING_IN_PROGRESS_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId={CHECKING_COMPLETE_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <CheckingComplete
                    setSequencesTotal={setCheckingCompleteTotal}
                    sequencesTotal={checkingCompleteTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={CHECKING_COMPLETE_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
            <TabPane tabId={REVISION_GENERATED_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  <RevisionGenerated
                    setSequencesTotal={setRevisionGeneratedTotal}
                    sequencesTotal={revisionGeneratedTotal}
                    setReloadTabs={setReloadTabs}
                    reloadTabs={reloadTabs}
                    tabName={REVISION_GENERATED_TAB}
                  />
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
};

export default Detailing;
