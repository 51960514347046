import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { pdfjs, Document, Page } from "react-pdf";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faPen,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { jobsApi } from "../../../../services/jobServices";
import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";
import {
  ACTIONS,
  useJobDetails,
} from "../../../../providers/jobDetailsProvider";
import Loader from "../../../Loader";
import InformationModal from "../../../InformationModal";
import ContactModal from "../../../ContactModal";
import ConfirmationModal from "../../../ConfirmationModal";
import { utils } from "../../../../utils/utils";
import JobSummaryModal from "./JobSummaryModal";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TYPE_PDF = "pdf";
const STATUS_RFQ = 1;
const STATUS_PENDING = 3;

const JobSummaryReport = ({ orderAcknowledgmentId }) => {
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [showContactModal, setShowContactModal] = useState({
    isOpen: false,
    isEmail: false,
    employees: null,
    defaultSubject: "",
    supervisor: null,
    defaultRecipients: [],
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [jobDetails, setJobDetails] = useJobDetails();

  const [report, setReport] = useState();
  const [loading, setLoading] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [initialEmail, setInitialEmail] = useState("");
  const [sendingEmail, setSendingEmail] = useState();
  const [showReportModal, setShowReportModal] = useState(false);

  const onClick = () => {
    console.log("ok");
  };

  const onEmailCustomer = async (e) => {
    e.preventDefault();
    setShowContactModal({
      isOpen: true,
      isEmail: true,
      defaultSubject: `Sales Quotation-RFQ#${jobDetails.job.id
        .toString()
        .padStart(5, "0")}`,
      defaultRecipients: [],
    });
  };

  const onCloseInformationModal = () => {
    setInformationModal({ isOpen: false, title: "", body: "" });
    setShowContactModal({
      isOpen: true,
      isEmail: true,
      title: "Send Report by Email",
      defaultRecipients: [initialEmail],
    });
  };

  const sendEmailReport = async (data) => {
    const email = data.recipients;
    if (utils.validateEmail(email)) {
      try {
        setInitialEmail(email);
        setSendingEmail(true);
        await jobsApi.sendJobSummaryReport({
          jobId: jobDetails.job.id,
          recipient: email,
          subject: data.subject,
          body: data.content,
          replyTo: data.replyTo,
        });
        setSendingEmail(false);
        setShowContactModal({ isOpen: false });
        setInformationModal({
          isOpen: true,
          title: "Send Report by Email",
          body: "Email sent successfully",
          onClose: () => {
            setInformationModal({ isOpen: false });
            markAsQuote();
          },
        });
      } catch (err) {
        setSendingEmail(false);
        setShowContactModal({ isOpen: false });
        setInformationModal({
          isOpen: true,
          title: "Send Report by Email",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
          onClose: onCloseInformationModal,
        });
      }
    } else {
      setShowContactModal({ isOpen: false });
      setInformationModal({
        isOpen: true,
        title: "Send Report by Email",
        body: "Please, enter a valid recipient",
        onClose: onCloseInformationModal,
      });
    }
  };

  const markAsQuote = () => {
    if (jobDetails.job.statusId === STATUS_RFQ) {
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          setConfirmationModal(initConfirmationModal);
          await jobsApi.updateJob({
            ...jobDetails.job,
            statusId: STATUS_PENDING,
          });
          setInformationModal({
            isOpen: true,
            title: "Mark RFQ to Pending",
            body: "RFQ marked as pending successfully.",
            onClose: () => {
              setJobDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        },
        onClose: () => setConfirmationModal(initConfirmationModal),
        title: "Mark RFQ to Pending",
        body: `
              <div class="text-center">
                  Do you confirm you want to mark this RFQ as Pending?
              </div>
        `,
        confirmColor: "danger",
      });
    }
  };

  const onDownload = async (type) => {
    markAsQuote();
    if (type === TYPE_PDF) {
      return window.open(report, "new");
    }
    try {
      setLoading(true);
      const res = await jobsApi.jobSummaryReport({
        jobId: jobDetails.job.id,
      });
      const data = new Blob([res], { type: "text/csv" });
      const URL = window.URL.createObjectURL(data);
      window.open(URL, "new");
      setLoading(false);
    } catch (err) {}
  };

  const onSubmit = () => {
    setShowReportModal(false);
    const getJob = async () => {
      setLoading(true);
      try {
        const job = await jobsApi.getJobsById(jobDetails.job.id);
        setLoading(false);
        setJobDetails({
          action: ACTIONS.GET_JOB_SUCCESS,
          payload: { job },
        });
      } catch (err) {
        setLoading(false);
      }
    };
    setInformationModal({
      isOpen: true,
      title: "Sales Quotation",
      body: "Sales Quotation Updated Successfully.",
      onClose: () => {
        setInformationModal({ isOpen: false });
        getJob();
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = orderAcknowledgmentId
          ? await orderAcknowledgmentApi.getSummaryReport({
              id: orderAcknowledgmentId,
            })
          : await jobsApi.jobSummaryReport({
              jobId: jobDetails.job.id,
            });
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setLoading(false);
        setReport(URL);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [jobDetails, orderAcknowledgmentId]);

  return (
    <Col className="col-12">
      <div className="d-flex justify-content-end">
        {!orderAcknowledgmentId ? (
          <div>
            <Button
              size="sm"
              className="rounded mx-2"
              color="warning"
              onClick={() => setShowReportModal(true)}
            >
              <span className="font-weight-bold">Edit</span>
              <FontAwesomeIcon icon={faPen} className="ml-2" />
            </Button>
            <Button
              color="tertiary"
              size="sm"
              className="rounded"
              onClick={() => onDownload(TYPE_PDF)}
            >
              <span className="font-weight-bold">PDF</span>
              <FontAwesomeIcon icon={faDownload} className="ml-1" />
            </Button>
            <Button
              color="tertiary"
              size="sm"
              className="ml-2 rounded"
              onClick={onEmailCustomer}
            >
              <span className="font-weight-bold">Send Report</span>
              <FontAwesomeIcon icon={faPaperPlane} className="ml-1" />
            </Button>
            <Button
              className="rounded ml-2 d-none"
              size="sm"
              color="tertiary"
              onClick={onClick}
            >
              <span>Create Revision</span>
            </Button>
          </div>
        ) : (
          <div>
            <Button
              color="tertiary"
              size="sm"
              className="rounded"
              onClick={() => onDownload(TYPE_PDF)}
            >
              <span className="font-weight-bold">PDF</span>
              <FontAwesomeIcon icon={faDownload} className="ml-1" />
            </Button>
          </div>
        )}
      </div>
      <Row>
        <Col className="flex-shrink-0 py-3">
          {loading ? (
            <div className="my-2">
              <Loader size="sm" />
            </div>
          ) : !report ? (
            <div className="text-center text-muted mt-4">
              No data has been recorded
            </div>
          ) : (
            <>
              <Document
                className="d-flex justify-content-center"
                file={report}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Page className="border rounded" pageNumber={pageNumber} />
              </Document>
              <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                <Button
                  className="rounded"
                  onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  className="rounded"
                  onClick={() =>
                    setPageNumber(Math.min(pageNumber + 1, numPages))
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
      {showContactModal.isOpen && (
        <ContactModal
          {...showContactModal}
          doSubmit={sendEmailReport}
          loading={sendingEmail}
          defaultSubject={showContactModal.defaultSubject}
          recipientsEditable={true}
          replyToEmail={true}
          onClose={() => {
            setShowContactModal({
              isOpen: false,
              employees: null,
              supervisor: null,
            });
            markAsQuote();
          }}
        />
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
      {showReportModal ? (
        <JobSummaryModal
          onClose={() => setShowReportModal(false)}
          onSubmit={onSubmit}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default JobSummaryReport;
