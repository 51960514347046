import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
  Card,
  NavLink,
  CardBody,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Button,
} from "reactstrap";
import QuoteRecapTable from "./QuoteRecapTable";
import {
  ACTIONS as JOB_DETAIL_ACTION,
  useJobDetails,
} from "../../../../providers/jobDetailsProvider";
import {
  ACTIONS as QUOTE_ITEMS_ACTION,
  useQuoteItems,
} from "../../../../providers/quoteItemsProvider";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuoteTotalTable from "./quoteTotal/QuoteTotalTable";
import { quoteItemsApi } from "../../../../services/quoteItemServices";
import Loader from "../../../Loader";

const QUOTE_ITEMS_TAB = "QUOTE_ITEMS_TAB";
const QUOTE_RECAP_TOTAL_TAB = "QUOTE_RECAP_TOTAL_TAB";

const QuoteRecapTab = () => {
  const [jobDetails, setJobDetails] = useJobDetails();
  const isJobLocked = jobDetails.job.lockDate;
  const [quoteItemsContext, setQuoteItemsContext] = useQuoteItems();

  const [loading, setLoading] = useState();
  const jobIsLocked = jobDetails.job.lockDate !== null;

  const toggle = (tab) => {
    if (jobDetails.activeGeneralTab !== tab) {
      setJobDetails({
        action: JOB_DETAIL_ACTION.CHANGE_QUOTE_RECAP_TOTAL_TAB,
        payload: {
          tab,
        },
      });
    }
  };

  const onEdit = () => {
    if (jobDetails.activeGeneralTab !== QUOTE_ITEMS_TAB) {
      setJobDetails({
        action: JOB_DETAIL_ACTION.CHANGE_GENERAL_TAB,
        payload: {
          tab: QUOTE_ITEMS_TAB,
        },
      });
      setQuoteItemsContext({
        action: QUOTE_ITEMS_ACTION.OPEN_SECTION,
        payload: { section: jobDetails.activeQuoteRecapTab },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const quoteItems = await quoteItemsApi.getQuoteItems({
        jobId: jobDetails.job.id,
      });
      setQuoteItemsContext({
        action: QUOTE_ITEMS_ACTION.GET_QUOTE_ITEMS_SUCCESS,
        payload: { quoteItems },
      });
      setLoading(false);
    };
    if (jobDetails.job) {
      fetchData();
    }
  }, [setQuoteItemsContext, quoteItemsContext.refresh, jobDetails.job]);

  return (
    <Col className="mt-3 col-12">
      <div className="d-flex align-items-start justify-content-between">
        <h3>Quote Recap</h3>
      </div>
      <Row>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Col>
            <Nav
              tabs
              className="d-flex align-items-center justify-content-between job-details-nav cursor-pointer mt-2"
            >
              <div className="d-flex flex-grow-1">
                {quoteItemsContext.quoteItems
                  ?.filter((quoteItem) => !quoteItem.archived)
                  ?.map((quoteItem) => (
                    <NavItem key={quoteItem.id}>
                      <NavLink
                        className={classnames({
                          active:
                            jobDetails.activeQuoteRecapTab === quoteItem.id,
                        })}
                        onClick={() => toggle(quoteItem.id)}
                      >
                        <div>{quoteItem.description}</div>
                      </NavLink>
                    </NavItem>
                  ))}
                <NavItem key={QUOTE_RECAP_TOTAL_TAB}>
                  <NavLink
                    className={classnames({
                      active:
                        jobDetails.activeQuoteRecapTab ===
                        QUOTE_RECAP_TOTAL_TAB,
                    })}
                    onClick={() => toggle(QUOTE_RECAP_TOTAL_TAB)}
                  >
                    <div>Quote Total</div>
                  </NavLink>
                </NavItem>
              </div>
              {jobDetails.activeQuoteRecapTab !== QUOTE_RECAP_TOTAL_TAB ? (
                isJobLocked ? (
                  <div className="px-1 py-0 bg-danger text-white">Locked</div>
                ) : (
                  <div>
                    <Button
                      color="warning"
                      className="rounded"
                      size="sm"
                      onClick={jobIsLocked ? () => {} : onEdit}
                    >
                      <span>Edit</span>
                      <FontAwesomeIcon icon={faPen} className="ml-2" />
                    </Button>
                  </div>
                )
              ) : isJobLocked &&
                jobDetails.activeQuoteRecapTab === QUOTE_RECAP_TOTAL_TAB ? (
                <div className="px-1 py-0 bg-danger text-white">Locked</div>
              ) : null}
            </Nav>
            {quoteItemsContext?.quoteItems
              ?.filter((quoteItem) => !quoteItem.archived)
              .map((quoteItem) => (
                <TabContent
                  key={quoteItem.id}
                  activeTab={jobDetails.activeQuoteRecapTab}
                >
                  <TabPane tabId={quoteItem.id}>
                    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                      <CardBody>
                        {jobDetails.activeQuoteRecapTab === quoteItem.id ? (
                          <QuoteRecapTable quoteItem={quoteItem} />
                        ) : null}
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              ))}
            <TabContent
              key={QUOTE_RECAP_TOTAL_TAB}
              activeTab={jobDetails.activeQuoteRecapTab}
            >
              <TabPane tabId={QUOTE_RECAP_TOTAL_TAB}>
                <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                  <CardBody>
                    {jobDetails.activeQuoteRecapTab ===
                    QUOTE_RECAP_TOTAL_TAB ? (
                      <QuoteTotalTable />
                    ) : null}
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default QuoteRecapTab;
