import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import CSRForm from "./CSRForm";
import Loader from "../../../Loader";
import InformationModal from "../../../InformationModal";
import { jobContractSpecificationAPI } from "../../../../services/jobContractSpecificationServices";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import { jobsApi } from "../../../../services/jobServices";

const CRSModal = ({ onClose, onSubmit }) => {
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [jobContractSpecification, setJobContractSpecification] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (jobContractSpecification.id) {
        await jobContractSpecificationAPI.updateJobContractSpecification(
          jobContractSpecification
        );
      } else {
        const contractSpecification =
          await jobContractSpecificationAPI.createJobContractSpecification(
            jobContractSpecification
          );
        await jobsApi.updateJob({
          ...jobDetails.job,
          jobContractSpecificationId: contractSpecification.id,
        });
      }

      onSubmit();
    } catch (err) {
      return setInformationModal({
        isOpen: true,
        title: "Update Job Contract Specification",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setJobContractSpecification(jobDetails.job.jobContractSpecification);
  }, [jobDetails]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Update Contract and Specification Review
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <CSRForm
                jobContractSpecification={jobContractSpecification}
                setJobContractSpecification={setJobContractSpecification}
              />
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CRSModal;
