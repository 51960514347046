import React from "react";

import { Col, ListGroup, ListGroupItem } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";

import CustomCheckbox from "../../../../CustomCheckbox";

const QuoteItemModalTypes = ({ selectedTypes, setSelectedTypes }) => {
  const [commonDataContext] = useCommonData();

  const onItemClick = (type) => {
    setSelectedTypes({
      ...selectedTypes,
      [type.id]: !selectedTypes[type.id],
    });
  };

  return commonDataContext.quoteItemTypeQuoteItems.length ? (
    <Col className="col-12">
      <ListGroup>
        {commonDataContext.quoteItemTypeQuoteItems.map((type) => (
          <ListGroupItem
            key={type.id}
            className="d-flex justify-content-between align-items-center cursor-pointer"
            onClick={() => onItemClick(type)}
          >
            <div className="flex-shrink-0">{type.name}</div>
            <CustomCheckbox checked={selectedTypes[type.id]} />
          </ListGroupItem>
        ))}
      </ListGroup>
    </Col>
  ) : null;
};

export default QuoteItemModalTypes;
