import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  editEnabled: false,
  laborCategories: {},
  managementRoles: {},
  quoteItemTypes: {},
  subcontractorRoles: {},
  shippingHandlingOptions: {},
  weldingGrindingOverride: null,
};

const ACTIONS = {
  INIT: "INIT",
  UPDATE_ITEM: "UPDATE_ITEM",
  ON_FINISH: "ON_FINISH",
  START_EDIT: "START_EDIT",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.INIT: {
      const { payload } = data;
      const {
        weldingGrindingOverride,
        teklaMaterialDataOverride,
        timeAttendanceLaborCodeHoursOverride,
      } = payload;

      const laborCategories = {};
      const managementRoles = {};
      const quoteItemTypes = {};
      const subcontractorRoles = {};
      const shippingHandlingOptions = {};

      timeAttendanceLaborCodeHoursOverride.forEach((taOverride) => {
        if (taOverride.laborCategoryId) {
          laborCategories[taOverride.laborCategoryId] = taOverride.reg;
        } else if (taOverride.managementRoleId) {
          managementRoles[taOverride.managementRoleId] = taOverride.reg;
        }
      });

      teklaMaterialDataOverride.forEach((teklaOverride) => {
        if (teklaOverride.quoteItemTypeId) {
          quoteItemTypes[teklaOverride.quoteItemTypeId] = teklaOverride.cost;
        } else if (teklaOverride.subcontractorRoleId) {
          subcontractorRoles[teklaOverride.subcontractorRoleId] =
            teklaOverride.cost;
        } else if (teklaOverride.shippingHandlingOptionId) {
          shippingHandlingOptions[teklaOverride.shippingHandlingOptionId] =
            teklaOverride.cost;
        }
      });

      return {
        ...initialState,
        laborCategories,
        managementRoles,
        quoteItemTypes,
        subcontractorRoles,
        shippingHandlingOptions,
        weldingGrindingOverride,
      };
    }

    case ACTIONS.START_EDIT: {
      return {
        ...state,
        editEnabled: true,
      };
    }

    case ACTIONS.UPDATE_ITEM: {
      const { payload } = data;
      const { id, value, key } = payload;

      if (key === "weldingGrindingOverride") {
        return {
          ...state,
          [key]: parseFloat(value),
        };
      }

      return {
        ...state,
        [key]: {
          ...state[key],
          [id]: parseFloat(value || 0),
        },
      };
    }

    default:
      throw new Error();
  }
};

const EvaOverrideContext = createContext(initialState);

const EvaOverrideProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EvaOverrideContext.Provider value={stateAndDispatch}>
      {children}
    </EvaOverrideContext.Provider>
  );
};

export const useEvaOverride = () => useContext(EvaOverrideContext);

export { EvaOverrideContext, EvaOverrideProvider, ACTIONS };
