import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faPen,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ACTIONS,
  useJobDetails,
} from "../../../../providers/jobDetailsProvider";
import { jobsApi } from "../../../../services/jobServices";
import Loader from "../../../Loader";
import { Document, Page } from "react-pdf";
import ContactModal from "../../../ContactModal";
import InformationModal from "../../../InformationModal";
import CRSModal from "./CRSModal";
import { useParams } from "react-router-dom";
import { utils } from "../../../../utils/utils";

const TYPE_PDF = "pdf";

const CSRSummary = () => {
  const { id } = useParams();

  const [jobDetails, setJobDetails] = useJobDetails();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [showCRSModal, setShowCRSModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [showContactModal, setShowContactModal] = useState({
    isOpen: false,
    isEmail: false,
    defaultSubject: "",
    defaultRecipients: [],
  });

  const [sendingEmail, setSendingEmail] = useState();
  const [initialEmail, setInitialEmail] = useState("");

  const onEmailCustomer = async (e) => {
    e.preventDefault();
    setShowContactModal({
      isOpen: true,
      isEmail: true,
      defaultSubject: `CS&R Summary-RFQ#${jobDetails?.job?.customerRFQNumber}`,
      defaultRecipients: [],
    });
  };

  const onCloseInformationModal = () => {
    setInformationModal({ isOpen: false, title: "", body: "" });
    setShowContactModal({
      isOpen: true,
      isEmail: true,
      title: "Send Report by Email",
      defaultRecipients: [initialEmail],
    });
  };

  const sendEmailReport = async (data) => {
    const email = data.recipients;
    if (utils.validateEmail(email)) {
      try {
        setInitialEmail(email);
        setSendingEmail(true);
        await jobsApi.sendContractSpecificationReport({
          jobId: jobDetails.job.id,
          recipient: email,
          subject: data.subject,
          body: data.content,
        });
        setSendingEmail(false);
        setShowContactModal({ isOpen: false });
        setInformationModal({
          isOpen: true,
          title: "Send Report by Email",
          body: "Email sent successfully",
          onClose: () => {
            setInformationModal({ isOpen: false });
          },
        });
      } catch (err) {
        setSendingEmail(false);
        setShowContactModal({ isOpen: false });
        setInformationModal({
          isOpen: true,
          title: "Send Report by Email",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
          onClose: onCloseInformationModal,
        });
      }
    } else {
      setShowContactModal({ isOpen: false });
      setInformationModal({
        isOpen: true,
        title: "Send Report by Email",
        body: "Please, enter a valid recipient",
        onClose: onCloseInformationModal,
      });
    }
  };

  const onSubmit = () => {
    setShowCRSModal(false);
    const getJob = async () => {
      setLoading(true);
      try {
        const job = await jobsApi.getJobsById(id);
        setLoading(false);
        setJobDetails({
          action: ACTIONS.GET_JOB_SUCCESS,
          payload: { job },
        });
      } catch (err) {
        setLoading(false);
      }
    };
    setInformationModal({
      isOpen: true,
      title: "Job Contract Specification",
      body: "Job Contract Specification updated successfully.",
      onClose: () => {
        setInformationModal({ isOpen: false });
        getJob();
      },
    });
  };

  const onDownload = (type) => {
    if (type === TYPE_PDF) {
      return window.open(report, "new");
    }
    setLoading(true);
    jobsApi
      .contractSpecificationReport({
        jobId: jobDetails.job.id,
      })
      .then((res) => {
        const data = new Blob([res], { type: "text/csv" });
        const URL = window.URL.createObjectURL(data);
        window.open(URL, "new");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchReport = () => {
      setLoading(true);
      jobsApi
        .contractSpecificationReport({
          jobId: jobDetails.job.id,
        })
        .then((res) => {
          const data = new Blob([res], { type: "application/pdf" });
          const URL = window.URL.createObjectURL(data);
          setLoading(false);
          setReport(URL);
        })
        .catch(() => {
          setLoading(false);
        });
    };
    fetchReport();
  }, [jobDetails]);

  return (
    <Col className="mt-3 col-12">
      <div className="d-flex align-items-center justify-content-end">
        <div>
          <Button
            size="sm"
            className="rounded mx-2"
            onClick={() => setShowCRSModal(true)}
            color="warning"
          >
            <span className="font-weight-bold">Edit</span>
            <FontAwesomeIcon icon={faPen} className="ml-1" />
          </Button>
          <Button
            color="tertiary"
            size="sm"
            className="rounded"
            onClick={() => onDownload(TYPE_PDF)}
          >
            <span className="font-weight-bold">PDF</span>
            <FontAwesomeIcon icon={faDownload} className="ml-1" />
          </Button>
          <Button
            color="tertiary"
            size="sm"
            className="ml-2 rounded"
            onClick={onEmailCustomer}
          >
            <span className="font-weight-bold">Send Report</span>
            <FontAwesomeIcon icon={faPaperPlane} className="ml-1" />
          </Button>
        </div>
      </div>
      <Row>
        <Col className="flex-shrink-0 py-3">
          {loading ? (
            <div className="my-2">
              <Loader size="sm" />
            </div>
          ) : !report ? (
            <div className="text-center text-muted mt-4">
              No data has been recorded
            </div>
          ) : (
            <>
              <Document
                className="d-flex justify-content-center"
                file={report}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Page className="border rounded" pageNumber={pageNumber} />
              </Document>
              <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                <Button
                  className="rounded"
                  onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  className="rounded"
                  onClick={() =>
                    setPageNumber(Math.min(pageNumber + 1, numPages))
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
      {showContactModal.isOpen && (
        <ContactModal
          {...showContactModal}
          doSubmit={sendEmailReport}
          loading={sendingEmail}
          defaultSubject={showContactModal.defaultSubject}
          recipientsEditable={true}
          onClose={() => {
            setShowContactModal({
              isOpen: false,
            });
          }}
        />
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
      {showCRSModal ? (
        <CRSModal
          title={informationModal.title}
          onClose={() => setShowCRSModal(false)}
          onSubmit={() => onSubmit()}
        />
      ) : null}
    </Col>
  );
};

export default CSRSummary;
