import React, { useReducer, createContext, useContext } from "react";

//GENERAL TABS
const PROJECT_DETAILS_TAB = "PROJECT_DETAILS_TAB";
const QUOTE_ITEMS_TAB = "QUOTE_ITEMS_TAB";
const QUOTE_RECAP_TAB = "QUOTE_RECAP_TAB";
const ORDER_ACKNOWLEDGMENT_TAB = "ORDER_ACKNOWLEDGMENT_TAB";
const DETAILING_INFO_TAB = "DETAILING_INFO_TAB";
const REPORTS_TAB = "REPORTS_TAB";
const PRODUCTION_HANDOFF_TAB = "PRODUCTION_HANDOFF_TAB";
const ESTIMATED_VS_ACTUAL = "ESTIMATED_VS_ACTUAL";
const INVOICE_STATUS = "INVOICE_STATUS";

//QUOTE RECAP TABS
const QUOTE_RECAP_TOTAL_TAB = "QUOTE_RECAP_TOTAL_TAB";

const initialState = {
  job: null,
  activeGeneralTab: PROJECT_DETAILS_TAB,
  activeQuoteRecapTab: QUOTE_RECAP_TOTAL_TAB,
};

const STATUS_RFQ = 1;
const STATUS_PENDING = 3;
const STATUS_IN_PROGRESS = 4;
const STATUS_SHIP_COMPLETE = 2;
const STATUS_COMPLETED = 5;
const STATUS_CANCELLED = 8;
const STATUS_INVOICED = 9;
const STATUS_IN_PROJECT_MANAGEMENT = 10;
const STATUS_READY_FOR_INVOICE = 12;

const STATUS_TEXT = [
  { id: STATUS_RFQ, text: "Estimating" },
  { id: STATUS_PENDING, text: "Quoted" },
  { id: STATUS_IN_PROJECT_MANAGEMENT, text: "In PM" },
  { id: STATUS_IN_PROGRESS, text: "In Progress" },
  { id: STATUS_SHIP_COMPLETE, text: "Shipped Complete" },
  { id: STATUS_READY_FOR_INVOICE, text: "Ready For Invoice" },
  { id: STATUS_CANCELLED, text: "Cancelled", showOnlyIfActive: true },
  { id: STATUS_INVOICED, text: "Invoiced" },
  { id: STATUS_COMPLETED, text: "Completed" },
];

const TABS = {
  PROJECT_DETAILS_TAB,
  QUOTE_ITEMS_TAB,
  QUOTE_RECAP_TAB,
  ORDER_ACKNOWLEDGMENT_TAB,
  DETAILING_INFO_TAB,
  REPORTS_TAB,
  PRODUCTION_HANDOFF_TAB,
  ESTIMATED_VS_ACTUAL,
  INVOICE_STATUS,
};

const ACTIONS = {
  REFRESH: "REFRESH",
  CHANGE_GENERAL_TAB: "CHANGE_GENERAL_TAB",
  CHANGE_QUOTE_RECAP_TOTAL_TAB: "CHANGE_QUOTE_RECAP_TOTAL_TAB",
  GET_JOB_SUCCESS: "GET_JOB_SUCCESS",
  RESET: "RESET",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_JOB_SUCCESS: {
      const { payload } = data;
      const { job } = payload;

      return {
        ...state,
        job,
      };
    }

    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh,
      };

      return { ...newState };
    }

    case ACTIONS.RESET: {
      const newState = {
        ...initialState,
        activeGeneralTab: state.activeGeneralTab,
      };

      return { ...newState };
    }

    case ACTIONS.CHANGE_GENERAL_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeGeneralTab: tab,
      };
    }

    case ACTIONS.CHANGE_QUOTE_RECAP_TOTAL_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeQuoteRecapTab: tab,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const jobDetails = createContext(initialState);

const JobDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <jobDetails.Provider value={stateAndDispatch}>
      {children}
    </jobDetails.Provider>
  );
};

export const useJobDetails = () => useContext(jobDetails);

export { jobDetails, JobDetailsProvider, ACTIONS, TABS, STATUS_TEXT };
