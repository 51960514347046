import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  openSections: {},
  quoteItems: [],
};

const ACTIONS = {
  TOGGLE_SECTION: "TOGGLE_SECTION",
  OPEN_SECTION: "OPEN_SECTION",
  GET_QUOTE_ITEMS_SUCCESS: "GET_QUOTE_ITEMS_SUCCESS",
  UPDATE_QUOTE_ITEMS: "UPDATE_QUOTE_ITEMS",
  UPDATE_QUOTE_ITEMS_ORDER: "UPDATE_QUOTE_ITEMS_ORDER",
  REFRESH: "REFRESH",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.GET_QUOTE_ITEMS_SUCCESS: {
      const { payload } = data;
      const { quoteItems } = payload;

      return {
        ...state,
        quoteItems,
      };
    }

    case ACTIONS.UPDATE_QUOTE_ITEMS: {
      const { payload } = data;
      const { quoteItems } = payload;

      return {
        ...state,
        quoteItems,
      };
    }

    case ACTIONS.UPDATE_QUOTE_ITEMS_ORDER: {
      const { payload } = data;
      const { quoteItems } = payload;

      const updatedQuoteItems = state.quoteItems.map((quoteItem) => ({
        ...quoteItem,
        order:
          quoteItems.find((qi) => qi.id === quoteItem.id)?.order ||
          quoteItem.order,
      }));
      return {
        ...state,
        quoteItems: updatedQuoteItems,
      };
    }

    case ACTIONS.TOGGLE_SECTION: {
      const { payload } = data;
      const { section } = payload;

      return {
        ...state,
        openSections: {
          ...state.openSections,
          [section]: !state.openSections[section],
        },
      };
    }

    case ACTIONS.OPEN_SECTION: {
      const { payload } = data;
      const { section } = payload;

      return {
        ...state,
        openSections: {
          [section]: true,
        },
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const quoteItems = createContext(initialState);

const QuoteItemsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <quoteItems.Provider value={stateAndDispatch}>
      {children}
    </quoteItems.Provider>
  );
};

export const useQuoteItems = () => useContext(quoteItems);

export { quoteItems, QuoteItemsProvider, ACTIONS };
