import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";
import QuoteTotalOfficeSectionTotal from "./QuoteTotalOfficeSectionTotal";

const QuoteTotalOfficeSection = () => {
  const [jobDetails] = useJobDetails();
  const [quoteRecapContext] = useQuoteRecap();
  const [quoteItemsContext] = useQuoteItems();
  const [quoteItemManagementRoles, setQuoteItemManagementRoles] = useState([]);
  const fieldName = utils.getFieldNameToUseAsCostByHour(jobDetails.job);

  useEffect(() => {
    const quoteItemManagementRoles = utils.reduceQuoteItemsOfficeLabors(
      quoteItemsContext.quoteItems
    );
    setQuoteItemManagementRoles(quoteItemManagementRoles);
  }, [quoteItemsContext]);

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {quoteItemManagementRoles
          .sort((x, y) =>
            x.managementRole.code < y.managementRole.code ? -1 : 1
          )
          .map((quoteItemManagementRole) => {
            const costByHours =
              quoteItemManagementRole.managementRole.costByHoursAndYear[
                fieldName
              ];
            const hours = quoteItemManagementRole.hours || 0;
            const totalCost = costByHours * hours;
            const saleHrs =
              costByHours * (1 + quoteRecapContext.markupPercentage / 100);
            const totalSale =
              hours * costByHours * (1 + quoteRecapContext.markupPercentage / 100);
            return (
              <tr key={quoteItemManagementRole.id}>
                <td className="py-0 text-truncate" width={"35%"}>
                  {`${quoteItemManagementRole.managementRole.code} - ${quoteItemManagementRole.managementRole.name}`}
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatHour(hours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(costByHours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalCost)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(saleHrs)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalSale)}
                  </div>
                </td>
              </tr>
            );
          })}
        <QuoteTotalOfficeSectionTotal
          quoteItemManagementRoles={quoteItemManagementRoles}
        />
      </tbody>
    </Table>
  );
};

export default QuoteTotalOfficeSection;
