import React from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";

const QuoteRecapMaterialSection = ({ quoteItem }) => {
  const { quoteItemTypeQuoteItems, margin } = quoteItem;

  const materialRows = quoteItemTypeQuoteItems
    .filter((item) => item.weight > 0 || item.cost > 0)
    .sort((x, y) => (x.quoteItemType.name < y.quoteItemType.name ? -1 : 1))
    .map((item) => {
      const { quoteItemType, weight, cost } = item;
      const sale = cost * (1 + margin / 100);

      return (
        <tr key={item.id}>
          <td className="py-0 text-truncate" width={"40%"}>
            {quoteItemType.name}
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatWeight(weight)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(cost)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatPercent(quoteItem.margin, 100)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(sale)}
            </div>
          </td>
        </tr>
      );
    });

  const totalWeight = quoteItemTypeQuoteItems.reduce(
    (p, c) => p + parseFloat(c.weight),
    0
  );
  const totalCost = quoteItemTypeQuoteItems.reduce(
    (p, c) => p + parseFloat(c.cost),
    0
  );
  const totalSale = totalCost * (1 + margin / 100);

  return (
    <Table className="col-12 px-0 border rounded mb-0">
      <thead className="small">
        <tr className="text-muted bg-lighter">
          <th className="align-middle" width={"40%"}>
            Type
          </th>
          <th width={"15%"} className="text-center">
            Weight
          </th>
          <th width={"15%"} className="text-center">
            Cost
          </th>
          <th width={"15%"} className="text-center">
            Markup
          </th>
          <th width={"15%"} className="text-center">
            Sale
          </th>
        </tr>
      </thead>
      <tbody>
        {materialRows}
        <tr>
          <td className="font-weight-bold py-0" width={"40%"}>
            Total Material
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatWeight(totalWeight)}
            </div>
          </td>
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totalCost)}
            </div>
          </td>
          <td className="py-0" width={"15%"} />
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totalSale)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteRecapMaterialSection;
