import React, { useState } from "react";
import ReactQuill from "react-quill";

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Loader from "./Loader";

const ContactModal = ({
  defaultRecipients,
  isOpen,
  title,
  isEmail,
  onClose,
  doSubmit,
  loading,
  multipleRecipients,
  replyToEmail,
  withAttachment,
  recipientsEditable,
  defaultSubject,
}) => {
  const [recipients, setRecipients] = useState(defaultRecipients.join(", "));
  const [subject, setSubject] = useState(defaultSubject || "");
  const [replyTo, setReplyTo] = useState("");
  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState();

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      recipients: recipients,
      subject: subject,
      content: content,
      replyTo: replyTo,
      attachment: attachment,
    };
    if (!replyToEmail) {
      delete data.replyTo;
    }
    if (!withAttachment) {
      delete data.attachment;
    }
    doSubmit(data);
  };

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={onSubmit}>
        <ModalHeader close={closeBtn}>{title}</ModalHeader>
        <ModalBody>
          <Row className="mb-3 d-flex align-items-center">
            <Col sm="4" className="d-flex flex-column">
              <div className="d-flex">
                <Label className="mb-0" for="subject">{`Recipient${
                  multipleRecipients ? "s" : ""
                }`}</Label>
                <span className="text-danger ml-2">*</span>
              </div>
              {multipleRecipients ? (
                <small className="text-primary">Separated by commas</small>
              ) : null}
            </Col>
            <Col sm="8">
              <Input
                maxLength="1024"
                type="text"
                placeholder={`Input recipient${multipleRecipients ? "s" : ""}`}
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                required
                disabled={recipientsEditable ? false : true}
              />
            </Col>
          </Row>
          {isEmail && (
            <>
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Subject
                  </Label>
                  <span className="text-danger ml-2">*</span>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="100"
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Enter the subject..."
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              {replyToEmail ? (
                <Row className="mb-3 d-flex align-items-center">
                  <Col sm="4">
                    <Label className="mb-0" for="subject">
                      Reply To Email
                    </Label>
                    <span className="text-danger ml-2">*</span>
                  </Col>
                  <Col sm="8">
                    <Input
                      maxLength="50"
                      type="email"
                      placeholder="Enter an email address..."
                      value={replyTo}
                      onChange={(e) => setReplyTo(e.target.value.trim())}
                      required
                    />
                  </Col>
                </Row>
              ) : null}
              {withAttachment ? (
                <Row className="mb-3 d-flex align-items-center">
                  <Col sm="4" className="d-flex flex-column">
                    <Label className="mb-0" for="attachment">
                      Attachment
                    </Label>
                    <span className="text-danger ml-2">*</span>
                    <small className="text-primary">Max 10mb.</small>
                  </Col>
                  <Col sm="8">
                    <Input
                      type="file"
                      id="attachment"
                      name="attachment"
                      onChange={(e) => setAttachment(e.target.files[0])}
                    />
                  </Col>
                </Row>
              ) : null}
              <div className="mt-3">
                <Col sm="12 border rounded">
                  <ReactQuill
                    placeholder="Type something..."
                    theme={"snow"}
                    value={content}
                    onChange={(content) => setContent(content)}
                  />
                </Col>
              </div>
            </>
          )}
          {!isEmail && (
            <Row className="mt-3">
              <Col sm="4">
                <Label className="mb-0" for="subject">
                  Content
                </Label>
                <span className="text-danger ml-2">*</span>
              </Col>
              <Col sm="8">
                <Input
                  maxLength="1024"
                  type="textarea"
                  id="content"
                  name="content"
                  placeholder="Enter the content..."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                />
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Send
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ContactModal;
