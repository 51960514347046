import {
  faDownload,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import {
  ACTIONS,
  useWorkOrder,
} from "../../../../../providers/workOrderProvider";
import { jobsApi } from "../../../../../services/jobServices";
import { workOrderItemApi } from "../../../../../services/workOrderItemServices";
import { workOrderApi } from "../../../../../services/workOrderServices";
import { utils } from "../../../../../utils/utils";
import ConfirmationModal from "../../../../ConfirmationModal";
import InformationModal from "../../../../InformationModal";
import Loader from "../../../../Loader";
import WorkOrderItemModal from "./WorkOrderItemModal";
import WorkOrderNotesModal from "./WorkOrderNotesModal";

const TYPE_PDF = "pdf";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const initInformationModal = {
  isOpen: false,
  title: "",
  body: "",
  onClose: null,
};

const WorkOrderSummary = () => {
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [workOrder, setWorkOrder] = useWorkOrder();
  const [showWorkOrderItemModal, setShowWorkOrderItemModal] = useState(false);
  const [showWorkOrderNotesModal, setShowWorkOrderNotesModal] = useState(false);
  const [wo, setWO] = useState();
  const [refresh, setRefresh] = useState();

  const [informationModal, setInformationModal] =
    useState(initInformationModal);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onDownload = (type) => {
    setLoading(true);
    jobsApi
      .workOrderReport({
        jobId: jobDetails.job.id,
      })
      .then((res) => {
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setLoading(false);
        return window.open(URL, "new");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onEdit = (workOrderItem) => setShowWorkOrderItemModal(workOrderItem);

  const onDelete = (workOrderItem) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await workOrderItemApi.deleteWorkOrderItem(workOrderItem);
        setInformationModal({
          isOpen: true,
          title: "Delete Work Order Item",
          body: "Work Order Item deleted successfully.",
          onClose: () => {
            setRefresh(!refresh);
            setInformationModal(initInformationModal);
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Work Order Item",
      body: `
            <div class="text-center">
                Do you confirm you want to remove this Work Order Item?
            </div>
      `,
      confirmColor: "warning",
    });
  };

  useEffect(() => {
    const fetchWorkOrder = async () => {
      setLoading(true);
      workOrderApi
        .getWorkOrder({
          jobId: jobDetails.job.id,
        })
        .then((res) => {
          const workOrder = res[0] || {};
          setWorkOrder({
            action: ACTIONS.GET_WORK_ORDER_SUCCESS,
            payload: { workOrder },
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };
    if (jobDetails.job?.id) {
      fetchWorkOrder();
    }
  }, [jobDetails.job, setWorkOrder, refresh]);

  useEffect(() => {
    if (workOrder.workOrder) {
      setWO(workOrder.workOrder);
    }
  }, [workOrder]);

  return (
    <Col className="mt-3 col-12">
      <Row className="d-flex justify-content-between px-3 align-items-center">
        <div className="font-weight-bold">Work Order Items</div>
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            className="rounded"
            color="warning"
            onClick={() => setShowWorkOrderNotesModal(true)}
          >
            <span className="font-weight-bold">Edit</span>
            <FontAwesomeIcon icon={faPen} className="ml-1" />
          </Button>
          <Button
            className="rounded mr-2 ml-2"
            size="sm"
            onClick={() => setShowWorkOrderItemModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ml-2">Add Item</span>
          </Button>
          <Button
            color="tertiary"
            size="sm"
            className="rounded"
            onClick={() => onDownload(TYPE_PDF)}
          >
            <span className="font-weight-bold">PDF</span>
            <FontAwesomeIcon icon={faDownload} className="ml-1" />
          </Button>
        </div>
      </Row>
      <Row>
        <Col className="flex-shrink-0 py-3">
          {loading ? (
            <div className="my-2">
              <Loader size="sm" />
            </div>
          ) : (
            <div>
              <Table className="col-12 px-0 border rounded mb-0">
                <thead className="small">
                  <tr className="text-muted bg-lighter">
                    <th width={"10%"} className="text-left">
                      PO#
                    </th>
                    <th width={"20%"} className="text-center">
                      Vendor
                    </th>
                    <th width={"50%"} className="text-center">
                      Description
                    </th>
                    <th width={"10%"} className="text-center">
                      Expected
                    </th>
                    <th width={"10%"} className="text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {wo?.workOrderItems?.length > 0 ? (
                    wo.workOrderItems.map((workOrderItem, key) => {
                      return (
                        <tr key={workOrderItem.id}>
                          <td className="text-left" width={"10%"}>
                            {workOrderItem.poNumber || ""}
                          </td>
                          <td className="text-center" width={"20%"}>
                            {workOrderItem.vendor || ""}
                          </td>
                          <td className="text-center" width={"50%"}>
                            {workOrderItem.description || ""}
                          </td>
                          <td className="text-center" width={"10%"}>
                            {workOrderItem.expectedDate
                              ? utils.formatDate(
                                  workOrderItem.expectedDate,
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                          <td className="text-right" width={"10%"}>
                            <div className="d-flex align-items-center justify-content-end">
                              <Button
                                size="sm"
                                className="rounded"
                                color="warning"
                                onClick={() => onEdit(workOrderItem)}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </Button>
                              <Button
                                size="sm"
                                className="rounded ml-2"
                                color="danger"
                                onClick={() => onDelete(workOrderItem)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center small">
                        No items to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
      {showWorkOrderItemModal ? (
        <WorkOrderItemModal
          defaultItem={showWorkOrderItemModal}
          onClose={() => setShowWorkOrderItemModal(false)}
          onSubmit={() => {
            setShowWorkOrderItemModal(false);
            setRefresh(!refresh);
          }}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {showWorkOrderNotesModal ? (
        <WorkOrderNotesModal
          workOrder={wo}
          onClose={() => setShowWorkOrderNotesModal(false)}
          onSubmit={() => {
            setRefresh(!refresh);
          }}
        />
      ) : null}
    </Col>
  );
};

export default WorkOrderSummary;
