import React, { useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  pageSize,
  sortable,
  onSort,
  defaultSort,
}) => {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    setPageSize,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      disableSortBy: !sortable,
      manualSortBy: true,
      initialState: {
        sortBy: defaultSort
          ? [
              {
                id: defaultSort.sortBy,
                desc: defaultSort.direction === "desc",
              },
            ]
          : [],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [sortBy, onSort]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        });
      } else {
        if (child?.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            prepareRow,
          });
        } else {
          return child;
        }
      }
    });
  };

  return recursiveMap(children);
};

export default AdvanceTableWrapper;
