import React from "react";
import Routes from "./routes/Routes";

import { AuthProvider } from "./providers/authProvider";
import { UsersProvider } from "./providers/usersProvider";
import { SidebarProvider } from "./providers/sidebarProvider";
import { JobsProvider } from "./providers/jobsProvider";
import { JobKPIsProvider } from "./providers/jobsKPIsProvider";
import { SyncProvider } from "./providers/syncProvider";
import { CustomersProvider } from "./providers/customersProvider";
import { JobDetailsProvider } from "./providers/jobDetailsProvider";
import { QuoteItemsProvider } from "./providers/quoteItemsProvider";
import { QuoteRecapProvider } from "./providers/quoteRecapProvider";
import { CommonDataProvider } from "./providers/commonDataProvider";
import { WorkOrdersProvider } from "./providers/workOrderProvider";
import { EvaOverrideProvider } from "./providers/evaOverrideProvider";
import { SequenceRevisionsProvider } from "./providers/sequenceRevisionsProvider";

class App extends React.Component {
  render() {
    return (
      <SidebarProvider>
        <AuthProvider>
          <UsersProvider>
            <JobsProvider>
              <WorkOrdersProvider>
                <JobKPIsProvider>
                  <SyncProvider>
                    <CustomersProvider>
                      <JobDetailsProvider>
                        <QuoteItemsProvider>
                          <QuoteRecapProvider>
                            <CommonDataProvider>
                              <EvaOverrideProvider>
                                <SequenceRevisionsProvider>
                                  <Routes />
                                </SequenceRevisionsProvider>
                              </EvaOverrideProvider>
                            </CommonDataProvider>
                          </QuoteRecapProvider>
                        </QuoteItemsProvider>
                      </JobDetailsProvider>
                    </CustomersProvider>
                  </SyncProvider>
                </JobKPIsProvider>
              </WorkOrdersProvider>
            </JobsProvider>
          </UsersProvider>
        </AuthProvider>
      </SidebarProvider>
    );
  }
}

export default App;
