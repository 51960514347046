import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { quoteItemsApi } from "../../../../../services/quoteItemServices";

import InformationModal from "../../../../InformationModal";

import Loader from "../../../../Loader";
import QuoteItemModalLaborCategories from "./QuoteItemModalLaborCategories";
import QuoteItemModalManagementRoles from "./QuoteItemModalManagementRoles";
import QuoteItemModalShippingHandling from "./QuoteItemModalShippingHandling";
import QuoteItemModalSubcontractorRoles from "./QuoteItemModalSubcontractorRoles";
import QuoteItemModalTypes from "./QuoteItemModalTypes";

const QuoteItemModal = ({ onClose, onSubmit }) => {
  const [jobDetails] = useJobDetails();

  const [quoteItem, setQuoteItem] = useState({
    description: null,
    jobId: jobDetails.job.id,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedTypes, setSelectedTypes] = useState({});
  const [selectedSubcontractorRoles, setSelectedSubcontractorRoles] = useState(
    {}
  );
  const [selectedShippingHandling, setSelectedShippingHandling] = useState({});
  const [selectedManagementRoles, setSelectedManagementRoles] = useState({});

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const quoteItemLaborCategories = Object.keys(selectedCategories).filter(
      (c) => selectedCategories[c]
    );
    const quoteItemTypeQuoteItems = Object.keys(selectedTypes).filter(
      (c) => selectedTypes[c]
    );
    const quoteItemSubcontractorRoles = Object.keys(
      selectedSubcontractorRoles
    ).filter((c) => selectedSubcontractorRoles[c]);
    const quoteItemManagementRoles = Object.keys(
      selectedManagementRoles
    ).filter((c) => selectedManagementRoles[c]);
    const quoteItemShippingHandlings = Object.keys(
      selectedShippingHandling
    ).filter((c) => selectedShippingHandling[c]);
    try {
      await quoteItemsApi.createQuoteItem({
        ...quoteItem,
        quoteItemLaborCategories,
        quoteItemTypeQuoteItems,
        quoteItemSubcontractorRoles,
        quoteItemManagementRoles,
        quoteItemShippingHandlings,
      });
      return setInformationModal({
        isOpen: true,
        title: "Add Quote Item",
        body: "Quote item added successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false });
          onSubmit();
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Quote Item
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <>
                <Col className="col-12">
                  <FormGroup>
                    <Label for="description">
                      <span>Item Description</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <Input
                      required
                      className="height-40p border"
                      placeholder="Enter description..."
                      type="text"
                      maxLength="255"
                      name="description"
                      value={quoteItem.description || ""}
                      onChange={(e) =>
                        setQuoteItem({
                          ...quoteItem,
                          description: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <QuoteItemModalTypes
                  selectedTypes={selectedTypes}
                  setSelectedTypes={setSelectedTypes}
                />
                <QuoteItemModalSubcontractorRoles
                  selectedSubcontractorRoles={selectedSubcontractorRoles}
                  setSelectedSubcontractorRoles={setSelectedSubcontractorRoles}
                />
                <QuoteItemModalLaborCategories
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
                <QuoteItemModalShippingHandling
                  selectedShippingHandling={selectedShippingHandling}
                  setSelectedShippingHandling={setSelectedShippingHandling}
                />
                <QuoteItemModalManagementRoles
                  selectedManagementRoles={selectedManagementRoles}
                  setSelectedManagementRoles={setSelectedManagementRoles}
                />
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default QuoteItemModal;
