import React, { useEffect, useState } from "react";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";

const QuoteTotalLaborSectionTotal = ({ quoteItemLaborCategories }) => {
  const [quoteRecapContext] = useQuoteRecap();

  const [hours, setHours] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [costByHour, setCostByHour] = useState(0);
  const [saleHrs, setSaleHrs] = useState(0);
  const [totalSale, setTotalSale] = useState(0);

  useEffect(() => {
    const hours = quoteItemLaborCategories.reduce(
      (p, c) => p + (c.hours || 0),
      0
    );

    const totalCost = quoteItemLaborCategories.reduce((p, c) => {
      const fieldName = utils.getFieldNameToUseAsCostByHour(c);
      const totalCost =
        c.laborCategory.costByHoursAndYear[fieldName] * (c.hours || 0);
      return p + totalCost;
    }, 0);

    const costByHour = hours ? totalCost / hours : 0;

    const saleHrs = costByHour * (1 + quoteRecapContext.markupPercentage / 100);

    const totalSale = quoteItemLaborCategories.reduce((p, c) => {
      const totalSale = (c.hours || 0) * saleHrs;
      return p + totalSale;
    }, 0);

    setHours(hours);
    setTotalCost(totalCost);
    setCostByHour(costByHour);
    setSaleHrs(saleHrs);
    setTotalSale(totalSale);
  }, [quoteRecapContext.markupPercentage, quoteItemLaborCategories]);

  return (
    <tr>
      <td className="font-weight-bold py-0" width={"35%"}>
        Total Shop Labor
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatHour(hours)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(costByHour)}
        </div>
      </td>
      <td className="py-0">
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totalCost)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(saleHrs)}
        </div>
      </td>
      <td className="py-0" width={"13%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(totalSale)}
        </div>
      </td>
    </tr>
  );
};

export default QuoteTotalLaborSectionTotal;
