import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import InformationModal from "../../InformationModal";

import Loader from "../../Loader";

import { jobsApi } from "../../../services/jobServices";

import moment from "moment";
import { useJobDetails } from "../../../providers/jobDetailsProvider";
import ConvertToJobForm from "./ConvertToJobForm";
import { workOrderApi } from "../../../services/workOrderServices";
import ConfirmationModal from "../../ConfirmationModal";

const STATUS_IN_PROJECT_MANAGEMENT = 10;

const MODE_MODAL = "modal";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const ConvertToJobModal = ({ onClose, onSubmit }) => {
  const [jobDetails] = useJobDetails();

  const [job, setJob] = useState({
    ...jobDetails.job,
    contractDate: new Date(),
    estimatedShipDate: new Date(),
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [loading, setLoading] = useState();

  const convertToJob = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => doSubmit(),
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Convert RFQ to Job",
      body: `
            <div class="text-center">
                Do you confirm you want to convert this RFQ to Job?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const doSubmit = async () => {
    setConfirmationModal(initConfirmationModal);
    if (!job.contractDate) {
      return setInformationModal({
        isOpen: true,
        title: "Convert to Job",
        body: "Please select contract date.",
      });
    }
    if (!job.estimatedShipDate) {
      return setInformationModal({
        isOpen: true,
        title: "Convert to Job",
        body: "Please select estimated ship date.",
      });
    }
    if (!job.managerId) {
      return setInformationModal({
        isOpen: true,
        title: "Convert to Job",
        body: "Please select the project manager.",
      });
    }
    setLoading(true);
    const data = {
      ...job,
      contractDate:
        moment(job.contractDate).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      estimatedShipDate:
        moment(job.estimatedShipDate).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      receivedDate:
        moment(job.receivedDate).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      quoteDue:
        moment(job.quoteDue).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
    };
    try {
      delete data.jobNumber;
      await jobsApi.updateJob({
        ...data,
        statusId: STATUS_IN_PROJECT_MANAGEMENT,
      });
      await workOrderApi.createWorkOrder({
        jobId: jobDetails.job.id,
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Convert to Job",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={convertToJob}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Convert to Job
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <ConvertToJobForm job={job} setJob={setJob} mode={MODE_MODAL} />
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ConvertToJobModal;
