import React, { useState } from "react";
import {
  Container,
  Button,
} from "reactstrap";
import { utils } from "../../../utils/utils";
import SequencesTable from "./SequencesTable";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import SequenceDetailsModal from "./SequenceDetailsModal";
import CreateNewRevisionModal from "./CreateNewRevisionModal";
import { sequenceApi } from "../../../services/sequenceServices";

const CHECKING_COMPLETE_STATUS = 6;
const REVISION_GENERATED_STATUS = 7;
const CHECKING_COMPLETE_TAB = "CHECKING_COMPLETE_TAB";
const REVISION_GENERATED_TAB = "REVISION_GENERATED_TAB";
const DEFAULT_TABS_TO_LOAD = [CHECKING_COMPLETE_TAB, REVISION_GENERATED_TAB];

const CheckingComplete = ({
  sequencesTotal,
  setSequencesTotal,
  reloadTabs,
  setReloadTabs,
  tabName,
}) => {
  const [checkerId, setCheckerId] = useState();
  const [detailerId, setDetailerId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [sequenceSelected, setSequenceSelected] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );
  const columns = [
    {
      accessor: "generateRevision",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 135,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"success"}
            onClick={() => {
              setSequenceSelected(rowData.row.original);
              setShowModal(true);
            }}
          >
            Generate Revision
          </Button>
        );
      },
    },
    {
      id: "seeJobDetailsButton",
      accessor: "seeJobDetailsButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 125,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original;
              const detailer = sequence.detailer;
              const checker = sequence.checker;
              setSequenceSelected(sequence);
              setDetailerId(
                detailer ? `${detailer.firstName} ${detailer.lastName}` : "-"
              );
              setCheckerId(
                checker ? `${checker.firstName} ${checker.lastName}` : "-"
              );
              setSequenceStatusId(sequence.sequenceStatusId);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const changeSequenceStatus = async (sequence) => {
    try {
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId,
            });
            onClose();
            setInformationModal({
              isOpen: true,
              title: "Sequence Update",
              body: "Sequence updated successfully.",
            });
            setReloadTabs([
              CHECKING_COMPLETE_TAB,
              utils.detailingTabs[sequenceStatusId],
            ]);
          } catch (error) {
            onClose();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => setConfirmationModal(utils.initConfirmationModal),
        title: "Sequence Update",
        body: `
        <div class="text-center">
          Do you confirm you want to update the sequence ${sequence.job.jobNumber}?
        </div>
      `,
        confirmColor: "warning",
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const beforeSubmit = () => {
      setReloadTabs([]);
  };
  const afterSubmit = () => {
    setShowModal(false);
    setReloadTabs(DEFAULT_TABS_TO_LOAD);
  };

  const onClose = () => {
    setDetailerId();
    setCheckerId();
    setShowModal(false);
    setSequenceSelected();
    setDetailModal(false);
    setSequenceStatusId();
    setSequenceSelected();
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    changeSequenceStatus(sequenceSelected);
  };

  return (
    <Container fluid className="px-0">
      <SequencesTable
        columns={columns}
        sequenceStatusId={CHECKING_COMPLETE_STATUS}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      <CreateNewRevisionModal
        showRevisionModal={showModal}
        onClose={onClose}
        beforeSubmit={beforeSubmit}
        afterSubmit={afterSubmit}
        sequenceStatusId={REVISION_GENERATED_STATUS}
        sequenceId={sequenceSelected?.id}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal(utils.initInformationModal)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : detailModal ? (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClose}
          readOnly={true}
          checkerId={checkerId}
          detailerId={detailerId}
          enableUpdate={true}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      ) : null}
    </Container>
  );
};

export default CheckingComplete;
