import React, { useEffect } from "react";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import {
  ACTIONS,
  useQuoteRecap,
} from "../../../../../providers/quoteRecapProvider";
import QuoteTotalLaborSection from "./QuoteTotalLaborSection";
import QuoteTotalMaterialSection from "./QuoteTotalMaterialSection";
import QuoteTotalOfficeSection from "./QuoteTotalOfficeSection";
import QuoteTotalResumeSection from "./QuoteTotalResumeSection";
import QuoteTotalShippingHandlingsSection from "./QuoteTotalShippingHandlingsSection";
import QuoteTotalSubContractorsSection from "./QuoteTotalSubContractorsSection";

const QuoteTotalTable = () => {
  const [quoteItemsContext] = useQuoteItems();
  const [, setQuoteRecapContext] = useQuoteRecap();
  const [jobDetails] = useJobDetails();

  useEffect(() => {
    if (quoteItemsContext.quoteItems) {
      setQuoteRecapContext({
        action: ACTIONS.ON_DO_CALCULATIONS,
        payload: {
          quoteItems: quoteItemsContext.quoteItems,
          job: jobDetails.job,
        },
      });
    }
  }, [quoteItemsContext.quoteItems, setQuoteRecapContext, jobDetails.job]);

  return (
    <div>
      <QuoteTotalMaterialSection />
      <QuoteTotalSubContractorsSection />
      <QuoteTotalShippingHandlingsSection />
      <QuoteTotalLaborSection />
      <QuoteTotalOfficeSection />
      <QuoteTotalResumeSection />
    </div>
  );
};

export default QuoteTotalTable;
