import React from "react";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../providers/authProvider";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import brand from "../../../assets/img/logo.png";

import SidebarMenu from "./SidebarMenu";
import { adminRoutes } from "../../../routes";
import { useSidebar } from "../../../providers/sidebarProvider";
import { useNavigate } from "react-router-dom";
import { authApi } from "../../../services/authServices";

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext] = useSidebar();

  const logout = () => {
    authApi.logout();
    setAuthContext({ currentUser: null });
    navigate("/auth/sign-in");
  };

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? "toggled" : ""}`}>
      <div className="sidebar-content py-2">
        <div className="sidebar-brand d-flex align-items-center">
          <img src={brand} className="rounded p-0 col-12" alt={"Ben Parker"} />
        </div>
        <SideBarUser />
        <ul className="sidebar-nav">
          {adminRoutes
            .filter((route) => {
              return (
                route.sidebar &&
                (!route.roles?.length ||
                  route.roles?.indexOf(authContext.currentUser.userRoleId) > -1)
              );
            })
            .map((route, index) =>
              route.entries ? (
                <SidebarMenu
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  to={route.path}
                  entries={route.entries}
                />
              ) : (
                <SidebarItem
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  to={route.path}
                />
              )
            )}
          <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
