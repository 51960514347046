import React from "react";
import { utils } from "../utils/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateRangeLabel = ({ title, startDate, endDate, background }) => {
  return (
    <div className="d-flex mx-2 align-items-center justify-content-center">
      {title ? <strong className="mr-2">{title}: </strong> : null}
      <div
        className="d-flex align-items-center px-2 py-1 border rounded"
        style={{ background }}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className="mr-2 text-primary"
          style={{ paddingBottom: "1px" }}
        />
        <span>{startDate ? utils.formatDate(startDate) : "Not Set"}</span>
      </div>
      <span className="mx-2">to</span>
      <div
        className="d-flex align-items-center px-2 py-1 border rounded"
        style={{ background }}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className="mr-2 text-primary"
          style={{ paddingBottom: "1px" }}
        />
        <span>{endDate ? utils.formatDate(endDate) : "Not Set"}</span>
      </div>
    </div>
  );
};

export default DateRangeLabel;
