import Loader from "../../../Loader";
import React, { useEffect, useState, useCallback } from "react";
import { utils } from "../../../../utils/utils";
import classnames from "classnames";
import {
  FormGroup,
  Label,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CustomInput,
  Nav,
} from "reactstrap";
import InformationModal from "../../../InformationModal";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";
import JobSummaryReport from "../reports/JobSummaryReport";
import OrderAcknowledgmentAttachment from "../orderAcknowledgment/OrderAcknowledgmentAttachment";

const PAGE_NUMBER = 0;
const PAGE_SIZE = 99999;
const SALES_ORDER_PDF = "SALES_ORDER_PDF";

const OrderAcknowledgmentList = ({ items }) => {
  const [ordersAcknowledgment, setOrdersAcknowledgment] = useState([]);
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [selectedOrderAck, setSelectedOrderAck] = useState();
  const [tab, setTab] = useState(SALES_ORDER_PDF);
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );

  const loadOrdersAcknowledgment = useCallback(async (jobId) => {
    try {
      setLoading(true);
      const ordersAcknowledgment = await orderAcknowledgmentApi.getAll({
        jobId,
        page: PAGE_NUMBER,
        pageSize: PAGE_SIZE,
      });
      setOrdersAcknowledgment(ordersAcknowledgment.data);
      setLoading(false);
      return ordersAcknowledgment.data;
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, []);

  // Load Orders Acknowledgment
  useEffect(() => {
    if (!items) {
      loadOrdersAcknowledgment(jobDetails.job.id);
    } else {
      setOrdersAcknowledgment(items);
    }
  }, [jobDetails.job.id, loadOrdersAcknowledgment, items]);

  return (
    <div className="w-100">
      <Row className="d-flex justify-content-between px-3 align-items-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <div className="w-100">
            <FormGroup>
              <Label for="billingState">
                <span>Order ACK</span>
              </Label>
              <CustomInput
                id="orderAckSelect"
                type="select"
                name="orderAckSelect"
                value={selectedOrderAck?.id || ""}
                onChange={(e) => {
                  setSelectedOrderAck(
                    e.target.value
                      ? ordersAcknowledgment.find(
                          (ack) => ack.id === parseInt(e.target.value)
                        )
                      : null
                  );
                }}
              >
                <option value={""}>Select an order ack..</option>
                {ordersAcknowledgment.map((orderAck) => (
                  <option key={orderAck.id} value={orderAck.id}>
                    {`${jobDetails.job.id}.${orderAck.id}`}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
            {selectedOrderAck ? (
              <>
                <Nav
                  tabs
                  className="d-flex text-center flex-nowrap job-details-nav cursor-pointer mt-2 w-100"
                >
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === SALES_ORDER_PDF,
                      })}
                      onClick={() => setTab(SALES_ORDER_PDF)}
                    >
                      Sales Order PDF
                    </NavLink>
                  </NavItem>
                  {selectedOrderAck.attachments?.map((attachment, index) => (
                    <NavItem key={`nav-${index}`}>
                      <NavLink
                        className={classnames({
                          active: tab === index,
                        })}
                        onClick={() => setTab(index)}
                      >
                        {attachment.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={tab} className="w-100">
                  <TabPane tabId={SALES_ORDER_PDF}>
                    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                      <CardBody>
                        <JobSummaryReport
                          orderAcknowledgmentId={selectedOrderAck?.id}
                        />
                      </CardBody>
                    </Card>
                  </TabPane>
                  {selectedOrderAck.attachments?.map((attachment, index) => (
                    <TabPane tabId={index} key={`pane-${index}`}>
                      <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                        <CardBody>
                          {tab === index ? (
                            <Row>
                              <OrderAcknowledgmentAttachment
                                attachment={attachment}
                              />
                            </Row>
                          ) : null}
                        </CardBody>
                      </Card>
                    </TabPane>
                  ))}
                </TabContent>
              </>
            ) : null}
          </div>
        )}
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal(utils.initInformationModal)
          }
        />
      ) : null}
    </div>
  );
};

export default OrderAcknowledgmentList;
