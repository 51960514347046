import axios from "axios";
import { apiHelper } from "../utils/apiHelper";

export const awsApi = {
  putDocumentsToS3: (data) => {
    return axios(data.url, {
      method: "put",
      data: data.file,
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then(() => {
        return { name: data.file?.name, url: data.fileUrl };
      })
      .catch((err) => apiHelper.handleError(err));
  },
};
