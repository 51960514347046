import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, FormGroup, Input, Label } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { DayPicker } from "react-day-picker";
import makeAnimated from "react-select/animated";
import "react-day-picker/dist/style.css";
import InformationModal from "../../InformationModal";
import { usersApi } from "../../../services/userServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../utils/utils";
import TooltipItem from "../../TooltipItem";
import { jobsApi } from "../../../services/jobServices";
import { ACTIONS, useJobDetails } from "../../../providers/jobDetailsProvider";
import ConfirmationModal from "../../ConfirmationModal";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const CONTRACT_DATE = "contractDate";
const EST_SHIP_DATE = "estShipDate";
const SHIPPED_DATE = "shippedDate";
const UPDATED_SHIP_DATE = "updatedShipDate";

const MODE_MODAL = "modal";

const PAGE_SIZE = 30;

const STATUS_IN_ESTIMATING = 1;
const STATUS_SHIPPED = 2;
const STATUS_QUOTED = 3;
const STATUS_IN_PROGRESS = 4;
const STATUS_COMPLETED = 5;
const STATUS_CANCELED = 8;
const STATUS_INVOICED = 9;
const STATUS_IN_PROJECT_MANAGEMENT = 10;
const STATUS_ARCHIVED = 11;
const RFQ = "rfq";
const STATUS_TO_LOCK_EST_SHIP_DATE = [
  STATUS_IN_ESTIMATING,
  STATUS_QUOTED,
  STATUS_IN_PROJECT_MANAGEMENT,
];
const STATUS_TO_LOCK_UPDATED_SHIP_DATE = [
  STATUS_SHIPPED,
  STATUS_INVOICED,
  STATUS_COMPLETED,
  STATUS_CANCELED,
  STATUS_ARCHIVED,
];

const JOB_STATUSES_ALLOWED = [
  STATUS_IN_PROJECT_MANAGEMENT,
  STATUS_IN_PROGRESS,
  STATUS_SHIPPED,
  STATUS_INVOICED,
  STATUS_COMPLETED,
];

const ConvertToJobForm = ({ job, setJob, mode }) => {
  const [jobDetails, setJobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;
  const lockEstShipDate = STATUS_TO_LOCK_EST_SHIP_DATE.includes(
    jobDetails.job.statusId
  );
  const lockUpdatedShipDate = STATUS_TO_LOCK_UPDATED_SHIP_DATE.includes(
    jobDetails.job.statusId
  );
  const location = useLocation();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [dateSelectorOpen, setDateSelectorOpen] = useState({});

  //project manager
  const [loadingManagerSearch, setLoadingManagerSearch] = useState();
  const [managerUsers, setManagerUsers] = useState([]);
  const [managerSearch, setManagerSearch] = useState();
  const [manager, setManager] = useState();

  const setJobToTimeBank = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          intoTimeBankDate: jobDetails.job.intoTimeBankDate ? null : new Date(),
        });

        setInformationModal({
          isOpen: true,
          title: "Set Job into Time Bank",
          body: `${
            jobDetails.job.intoTimeBankDate
              ? `Job removed from Time Bank successfully.`
              : `Job set into Time Bank successfully.`
          }`,
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Set Job into Time Bank",
      body: `
            <div class="text-center">
                ${
                  jobDetails.job.intoTimeBankDate
                    ? `Are you sure you want to undo?`
                    : `Do you confirm you want to set this Job into Time Bank?`
                }
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const setJobToQuickbooks = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          intoQuickbooksDate: jobDetails.job.intoQuickbooksDate
            ? null
            : new Date(),
        });
        setInformationModal({
          isOpen: true,
          title: "Set Job into Quick Books",
          body: `${
            jobDetails.job.intoQuickbooksDate
              ? `Job removed from Quickbooks successfully.`
              : `Job set into Quickbooks successfully.`
          }`,
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Set Job into Quick Books",
      body: `
            <div class="text-center">
                ${
                  jobDetails.job.intoQuickbooksDate
                    ? `Are you sure you want to undo?`
                    : `Do you confirm you want to set this Job into Quickbooks?`
                }
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const getToolTipTitle = (date) => {
    const ret = date ? `Set on ${utils.formatDateTime(new Date(date))}` : null;

    return ret;
  };

  useEffect(() => {
    const managerUser = managerUsers.find((u) => u.id === job.managerId);
    if (managerUser) {
      setManager({
        label: ` ${managerUser.firstName} ${managerUser.lastName}`,
        value: managerUser.id,
      });
    }
  }, [job, managerUsers]);

  // Search manager users
  useEffect(() => {
    const fetchData = async () => {
      setLoadingManagerSearch(true);
      const results = await usersApi.getUsers({
        search: managerSearch || "",
        pageSize: PAGE_SIZE,
      });
      setManagerUsers(results?.data || []);
      setLoadingManagerSearch(false);
    };
    fetchData();
  }, [managerSearch]);

  return (
    <>
      <Col className={`col-${mode === MODE_MODAL ? "12" : "6"}`}>
        <FormGroup className="d-flex flex-column">
          <Label for={CONTRACT_DATE}>
            <span>Contract Date</span>
            <span className="text-danger ml-2">*</span>
          </Label>
          {dateSelectorOpen[CONTRACT_DATE] ? (
            <DayPicker
              required={true}
              defaultMonth={
                job.contractDate ? new Date(job.contractDate) : new Date()
              }
              className="date-picker bg-white rounded border d-flex justify-content-center m-0"
              mode="single"
              selected={
                job.contractDate ? new Date(job.contractDate) : new Date()
              }
              modifiersClassNames={{
                selected: "my-selected",
                today: "my-today",
                range_start: "my-range_start",
                range_end: "my-range_end",
              }}
              onDayClick={(contractDate) => {
                setDateSelectorOpen({ [CONTRACT_DATE]: false });
                setJob({
                  ...job,
                  contractDate,
                });
              }}
            />
          ) : (
            <div
              className={`col-12 d-flex z-index-0 p-2 border rounded bg-white ${
                isJobLocked ? "form-control" : "cursor-pointer"
              }`}
              readOnly={isJobLocked}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faCalendar}
                className="text-secondary"
                onClick={() =>
                  isJobLocked || setDateSelectorOpen({ [CONTRACT_DATE]: true })
                }
              />
              <span
                onClick={() =>
                  isJobLocked || setDateSelectorOpen({ [CONTRACT_DATE]: true })
                }
                className="col-11"
              >
                {job.contractDate
                  ? utils.formatDate(job.contractDate)
                  : "Select a Date ..."}
              </span>
            </div>
          )}
        </FormGroup>
        <FormGroup className="d-flex flex-column">
          <Label for={EST_SHIP_DATE}>
            <span>Est. Ship Date</span>
            <span className="text-danger ml-2">*</span>
          </Label>
          {lockEstShipDate && dateSelectorOpen[EST_SHIP_DATE] ? (
            <DayPicker
              required={true}
              defaultMonth={
                job.estimatedShipDate
                  ? new Date(job.estimatedShipDate)
                  : new Date()
              }
              className="date-picker bg-white rounded border d-flex justify-content-center m-0"
              mode="single"
              selected={
                job.estimatedShipDate
                  ? new Date(job.estimatedShipDate)
                  : new Date()
              }
              modifiersClassNames={{
                selected: "my-selected",
                today: "my-today",
                range_start: "my-range_start",
                range_end: "my-range_end",
              }}
              onDayClick={(estimatedShipDate) => {
                setDateSelectorOpen({ [EST_SHIP_DATE]: false });
                setJob({
                  ...job,
                  estimatedShipDate,
                });
              }}
            />
          ) : (
            <div
              className={`col-12 d-flex z-index-0 p-2 border rounded bg-white ${
                isJobLocked || !lockEstShipDate
                  ? "form-control bg-light"
                  : "cursor-pointer"
              }`}
              readOnly={isJobLocked}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faCalendar}
                className="text-secondary"
                onClick={() =>
                  isJobLocked || setDateSelectorOpen({ [EST_SHIP_DATE]: true })
                }
              />
              <span
                onClick={() =>
                  isJobLocked || setDateSelectorOpen({ [EST_SHIP_DATE]: true })
                }
                className="col-11"
              >
                {job.estimatedShipDate
                  ? utils.formatDate(job.estimatedShipDate)
                  : "Select a Date ..."}
              </span>
            </div>
          )}
        </FormGroup>
        {job.updatedShipDate ? (
          <FormGroup className="d-flex flex-column">
            <Label for={UPDATED_SHIP_DATE}>
              <span>Updated Ship Date</span>
              <span className="text-danger ml-2">*</span>
            </Label>
            {dateSelectorOpen[UPDATED_SHIP_DATE] && !lockUpdatedShipDate ? (
              <DayPicker
                required={true}
                defaultMonth={new Date(job.updatedShipDate)}
                className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                mode="single"
                selected={moment(job.updatedShipDate).toDate()}
                modifiersClassNames={{
                  selected: "my-selected",
                  today: "my-today",
                  range_start: "my-range_start",
                  range_end: "my-range_end",
                }}
                onDayClick={(updatedShipDate) => {
                  setDateSelectorOpen({ [UPDATED_SHIP_DATE]: false });
                  setJob({
                    ...job,
                    updatedShipDate,
                  });
                }}
              />
            ) : (
              <div
                className={`col-12 d-flex z-index-0 p-2 border rounded bg-white ${
                  isJobLocked || lockUpdatedShipDate
                    ? "form-control bg-light"
                    : "cursor-pointer"
                }`}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faCalendar}
                  className="text-secondary"
                  onClick={() =>
                    setDateSelectorOpen({ [UPDATED_SHIP_DATE]: true })
                  }
                />
                <span
                  onClick={() =>
                    setDateSelectorOpen({ [UPDATED_SHIP_DATE]: true })
                  }
                  className="col-11"
                >
                  {utils.formatDate(job.updatedShipDate)}
                </span>
              </div>
            )}
          </FormGroup>
        ) : null}

        {mode !== MODE_MODAL && !location.pathname.includes(RFQ) && (
          <FormGroup className="d-flex flex-column">
            <Label for={SHIPPED_DATE}>
              <span>Shipped Date</span>
            </Label>
            {dateSelectorOpen[SHIPPED_DATE] ? (
              <DayPicker
                required={true}
                defaultMonth={
                  job.shippedCompleteDate
                    ? new Date(job.shippedCompleteDate)
                    : new Date()
                }
                className="date-picker bg-white rounded border d-flex justify-content-center m-0"
                mode="single"
                selected={
                  job.shippedCompleteDate
                    ? new Date(job.shippedCompleteDate)
                    : new Date()
                }
                modifiersClassNames={{
                  selected: "my-selected",
                  today: "my-today",
                  range_start: "my-range_start",
                  range_end: "my-range_end",
                }}
                onDayClick={(shippedCompleteDate) => {
                  setDateSelectorOpen({ [SHIPPED_DATE]: false });
                  setJob({
                    ...job,
                    shippedCompleteDate:
                      moment(shippedCompleteDate).format("YYYY-MM-DD"),
                  });
                }}
              />
            ) : (
              <div
                className={`col-12 d-flex z-index-0 p-2 border rounded bg-white ${
                  isJobLocked ? "form-control" : "cursor-pointer"
                }`}
                readOnly={isJobLocked}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faCalendar}
                  className="text-secondary"
                  onClick={() =>
                    isJobLocked || setDateSelectorOpen({ [SHIPPED_DATE]: true })
                  }
                />
                <span
                  onClick={() =>
                    isJobLocked || setDateSelectorOpen({ [SHIPPED_DATE]: true })
                  }
                  className="col-11"
                >
                  {job.shippedCompleteDate
                    ? utils.formatDate(job.shippedCompleteDate)
                    : "Select a Date ..."}
                </span>
                {isJobLocked ? null : (
                  <FontAwesomeIcon
                    icon={faWindowClose}
                    className="z-index-1 ml-auto align-self-center text-danger rounded"
                    onClick={() =>
                      setJob({
                        ...job,
                        shippedCompleteDate: null,
                      })
                    }
                    color={"#adb5bd"}
                  />
                )}
              </div>
            )}
          </FormGroup>
        )}
      </Col>
      <Col className={`col-${mode === MODE_MODAL ? "12" : "6"}`}>
        {mode !== MODE_MODAL &&
        (jobDetails.job.statusId === STATUS_IN_PROJECT_MANAGEMENT ||
          jobDetails.job.statusId === STATUS_IN_PROGRESS ||
          jobDetails.job.statusId === STATUS_COMPLETED) ? (
          <FormGroup className="d-flex flex-column mt-3">
            <Label for="jobNumber">
              <span>{`BP Job# `}</span>
            </Label>
            <Input
              required={true}
              maxLength="50"
              className="height-40p border"
              placeholder="Enter BP Job#..."
              type="text"
              name="jobNumber"
              readOnly={true}
              value={job.jobNumber || ""}
            />
          </FormGroup>
        ) : null}
      </Col>
      <FormGroup className="d-flex flex-column mb-0 col-12">
        <Label for="Manager">
          <span>Manager</span>
          <span className="text-danger ml-2">*</span>
        </Label>
        {isJobLocked ? (
          <Input
            className="height-40p border"
            readOnly={true}
            type="text"
            name="Manager"
            defaultValue={manager?.label}
          />
        ) : (
          <Select
            required={true}
            id="Manager"
            placeholder={
              <span className="text-muted">Search for an user...</span>
            }
            noOptionsMessage={() => "No users found"}
            styles={style}
            className="flex-grow-1 border rounded"
            options={managerUsers.map((c) => ({
              label: `${c.firstName} ${c.lastName}`,
              value: c.id,
            }))}
            closeMenuOnSelect={true}
            components={animatedComponents}
            isSearchable
            isClearable
            isMulti={false}
            inputValue={managerSearch}
            value={manager}
            onInputChange={setManagerSearch}
            isLoading={loadingManagerSearch}
            onChange={(value) => {
              setManager(value);
              setJob({
                ...job,
                managerId: value?.value,
              });
            }}
          />
        )}
      </FormGroup>
      {mode !== MODE_MODAL &&
      (jobDetails.job.statusId === STATUS_IN_PROJECT_MANAGEMENT ||
        jobDetails.job.statusId === STATUS_IN_PROGRESS ||
        jobDetails.job.statusId === STATUS_COMPLETED) ? (
        <Col className="col-12">
          <FormGroup className="d-flex flex-column mt-3">
            <Label for="jobNotes">
              <span>{`Job Notes`}</span>
            </Label>
            <Input
              className=" border"
              placeholder="Enter job notes..."
              type="textarea"
              rows={7}
              maxLength="4096"
              name="jobNotes"
              value={job.jobNotes || ""}
              onChange={(e) =>
                setJob({
                  ...job,
                  jobNotes: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
      ) : null}
      {mode !== MODE_MODAL &&
      JOB_STATUSES_ALLOWED.includes(jobDetails.job.statusId) ? (
        <Col className="col-12 mt-3">
          <div className="d-flex rounded border cursor-pointer">
            <div
              className={`d-flex col-6 py-1 bg-${
                job.intoTimeBankDate ? "primary" : "light"
              } text-${job.intoTimeBankDate ? "white" : "dark"}`}
              onClick={() => setJobToTimeBank()}
            >
              <TooltipItem
                className="flex-grow-1"
                id="into-timebank"
                title={getToolTipTitle(job.intoTimeBankDate)}
              >
                Into Time Bank
              </TooltipItem>
            </div>
            <div
              className={`d-flex col-6 py-1 border-left bg-${
                job.intoQuickbooksDate ? "primary" : "light"
              } text-${job.intoQuickbooksDate ? "white" : "dark"}`}
              onClick={() => setJobToQuickbooks()}
            >
              <TooltipItem
                className="flex-grow-1"
                id="into-quickbooks"
                title={getToolTipTitle(job.intoQuickbooksDate)}
              >
                Into Quickbooks
              </TooltipItem>
            </div>
          </div>
          {confirmationModal.isOpen ? (
            <ConfirmationModal {...confirmationModal} />
          ) : null}
          {informationModal.isOpen ? (
            <InformationModal
              title={informationModal.title}
              body={informationModal.body}
              onClose={() =>
                informationModal.onClose
                  ? informationModal.onClose()
                  : setInformationModal({
                      isOpen: false,
                      title: "",
                      body: "",
                      onClose: null,
                    })
              }
            />
          ) : null}
        </Col>
      ) : null}
    </>
  );
};

export default ConvertToJobForm;
