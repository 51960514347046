import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { utils } from "../../../../../utils/utils";
import QuoteTotalSubContractorsSectionTotal from "./QuoteTotalSubContractorsSectionTotal";

const QuoteTotalSubContractorsSection = () => {
  const [quoteRecapContext] = useQuoteRecap();
  const [quoteItemsContext] = useQuoteItems();
  const [quoteItemSubcontractorRoles, setQuoteItemSubcontractorRoles] =
    useState([]);

  useEffect(() => {
    const quoteItemSubcontractorRoles = utils.reduceQuoteItemSubcontractorRoles(
      quoteItemsContext.quoteItems
    );
    setQuoteItemSubcontractorRoles(quoteItemSubcontractorRoles);
  }, [quoteItemsContext]);

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {quoteItemSubcontractorRoles.map((quoteItemSubcontractorRole) => {
          const cost = quoteItemSubcontractorRole.cost || 0;
          const sale = cost * (1 + quoteRecapContext.markupPercentage / 100);
          return (
            <tr key={quoteItemSubcontractorRole.id}>
              <td className="py-0 text-truncate" width={"40%"}>
                {quoteItemSubcontractorRole.subcontractorRole.name}
              </td>
              <td className="py-0" width={"15%"} />
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(cost)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatPercent(quoteRecapContext.markupPercentage, 100)}
                </div>
              </td>
              <td className="py-0" width={"15%"}>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatCurrency(sale)}
                </div>
              </td>
            </tr>
          );
        })}
        <QuoteTotalSubContractorsSectionTotal
          quoteItemSubcontractorRoles={quoteItemSubcontractorRoles}
        />
      </tbody>
    </Table>
  );
};

export default QuoteTotalSubContractorsSection;
