import React, { useState } from "react";
import { utils } from "../../../utils/utils";
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import SequenceDetailsModal from "./SequenceDetailsModal";
import SequenceRevisionsTable from "./SequenceRevisionsTable";
import { sequenceApi } from "../../../services/sequenceServices";

const DATE_FORMAT = "MM/DD/YY LT";
const REVISION_GENERATED_TAB = "REVISION_GENERATED_TAB";

const RevisionGenerated = ({
  sequencesTotal,
  setSequencesTotal,
  reloadTabs,
  setReloadTabs,
  tabName,
}) => {
  const [history, setHistory] = useState();
  const [checkerId, setCheckerId] = useState();
  const [detailerId, setDetailerId] = useState();
  const [detailModal, setDetailModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [sequenceSelected, setSequenceSelected] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );
  const columns = [
    {
      accessor: "seeHistoryButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 110,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"success"}
            onClick={() => {
              const { history } = rowData.row.original.sequence;
              setHistory(history);
              setDisplayModal(true);
            }}
          >
            See History
          </Button>
        );
      },
    },
    {
      id: "seeJobDetailsButton",
      accessor: "seeJobDetailsButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 125,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original.sequence;
              const detailer = sequence.detailer;
              const checker = sequence.checker;
              setSequenceSelected(sequence);
              setDetailerId(
                detailer ? `${detailer.firstName} ${detailer.lastName}` : "-"
              );
              setCheckerId(
                checker ? `${checker.firstName} ${checker.lastName}` : "-"
              );
              setSequenceStatusId(sequence.sequenceStatusId);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const changeSequenceStatus = async (sequence) => {
    try {
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId,
            });
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Sequence Update",
              body: "Sequence updated successfully.",
            });
            setReloadTabs([
              REVISION_GENERATED_TAB,
              utils.detailingTabs[sequenceStatusId],
              utils.detailingTabs[sequence.sequenceStatusId],
            ]);
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => setConfirmationModal(utils.initConfirmationModal),
        title: "Sequence Update",
        body: `
        <div class="text-center">
          Do you confirm you want to update the sequence ${sequence.job.jobNumber}?
        </div>
      `,
        confirmColor: "warning",
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onClear = () => {
    setHistory();
    setCheckerId();
    setDetailerId();
    setDetailModal(false);
    setSequenceStatusId();
    setSequenceSelected();
    setDisplayModal(false);
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    changeSequenceStatus(sequenceSelected);
  };
  return (
    <Container fluid className="px-0">
      <SequenceRevisionsTable
        columns={columns}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal(utils.initInformationModal)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : detailModal ? (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClear}
          readOnly={true}
          checkerId={checkerId}
          detailerId={detailerId}
          enableUpdate={true}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      ) : (
        <Modal isOpen={displayModal} className="modal-lg">
          <ModalHeader>Detailing History</ModalHeader>
          <ModalBody>
            <Row className="w-100 m-0 mt-3">
              <Table>
                <thead>
                  <tr className="bg-light border">
                    <th key="dateTime">Date / Time</th>
                    <th key="action">Action</th>
                    <th key="actionBy">Action By</th>
                  </tr>
                </thead>
                <tbody className={``}>
                  {history ? (
                    history?.map((row, index) => {
                      return (
                        <tr key={index} className={`border`}>
                          <td key={`date${row?.id}`}>
                            {utils.formatDate(row?.createdAt, DATE_FORMAT)}
                          </td>
                          <td key={`action${row?.id}`}>{row?.details}</td>
                          <td key={`actionBy${row?.id}`}>
                            {`${row?.user?.firstName} ${row?.user?.lastName}`}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        className="text-muted small text-center border"
                        colSpan={3}
                      >
                        No data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row>
                <Button color={"secondary"} onClick={onClear}>
                  Close
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};

export default RevisionGenerated;
