import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { useAuth } from "../../../providers/authProvider";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import CustomPeriodSelector from "../../../components/CustomPeriodSelector";
import { utils } from "../../../utils/utils";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PeriodTypeSelector from "../../../components/PeriodTypeSelector";

const columns = {
  "Customer Name": {
    type: "string",
  },
  "Total Sales": {
    type: "number",
  },
};

const report = {
  dataSource: {
    data: [columns],
  },
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  slice: {
    rows: [
      {
        uniqueName: "Customer Name",
      },
      {
        uniqueName: "Total Sales",
      },
    ],
    measures: [
      {
        uniqueName: "Total Sales",
        format: "currency",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Customer Summary Report",
    },
  },
};

const TYPE_ANY = "TYPE_ANY";

const CustomerSummaryReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const format = "YYYY-MM-DD";

  const [periodType, setPeriodType] = useState({
    value: TYPE_ANY,
    label: "Any Date",
  });

  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("year")
  );
  const [customEndDate, setCustomEndDate] = useState(moment());
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const condition = {};
      if (periodType.value !== TYPE_ANY) {
        condition.startDate = moment(customStartDate).format(format);
        condition.endDate = moment(customEndDate).format(format);
      }

      try {
        let result = await reportsApi.customerSummary(condition);
        result = utils.formatCurrencyToObjectsInArray(result, ["Total Sales"]);
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, ...result],
          });
        }
      } catch (error) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [
    pivot,
    customEndDate,
    customStartDate,
    authContext.currentUser,
    refresh,
    periodType,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Customer Summary Report</h3>
              <div className="d-flex align-items-center">
                {loading ? (
                  <div className="min-width-50">
                    <Loader size="sm" className="mr-3" />
                  </div>
                ) : null}
                <PeriodTypeSelector
                  periodType={periodType}
                  setPeriodType={setPeriodType}
                />
                {periodType.value !== TYPE_ANY ? (
                  <CustomPeriodSelector
                    defaultEndDate={customEndDate}
                    defaultStartDate={customStartDate}
                    onSubmit={(startDate, endDate) => {
                      setCustomStartDate(startDate);
                      setCustomEndDate(endDate);
                    }}
                  />
                ) : null}
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex ml-3"
                  color="secondary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  key={customStartDate + "-" + customEndDate}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utils.customizeToolbarReport(
                      toolbar,
                      pivot,
                      "customer_summary_report"
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default CustomerSummaryReport;
