import React, { useState } from "react";
import moment from "moment";
import { utils } from "../../../utils/utils";
import SequencesTable from "./SequencesTable";
import { Container, Button } from "reactstrap";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import { sequenceApi } from "../../../services/sequenceServices";
import SequenceDetailsModal from "./SequenceDetailsModal";
import { useAuth } from "../../../providers/authProvider";

const DETAILER_CHECKER_ROLE_ID = 4;
const DETAILING_IN_PROGRESS_STATUS = 3;
const WAITING_FOR_CHECKING_STATUS = 4;
const DETAILING_TAB = "DETAILING_TAB";

const DetailingInProgress = ({
  sequencesTotal,
  setSequencesTotal,
  reloadTabs,
  setReloadTabs,
  tabName,
}) => {
  const [authContext] = useAuth();
  const [checkerId, setCheckerId] = useState();
  const [detailerId, setDetailerId] = useState();
  const [detailModal, setDetailModal] = useState(false);
  const [sequenceSelected, setSequenceSelected] = useState();
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );
  const isDetailerChecker =
    authContext.currentUser?.role?.id === DETAILER_CHECKER_ROLE_ID;

  const columns = [
    {
      id: "detailerName",
      accessor: "detailer",
      Header: "Detailer",
      headerProps: { className: "text-truncate" },
      width: 100,
      Cell: (rowData) => {
        const { detailer } = rowData.row.original;
        return detailer ? `${detailer?.firstName} ${detailer?.lastName}` : "-";
      },
    },
    {
      accessor: "updatedAt",
      Header: "Began Detailing",
      headerProps: { className: "text-truncate" },
      width: 100,
      Cell: (rowData) => {
        const { updatedAt } = rowData.row.original;
        let dateString = "-";
        if (updatedAt) {
          var isToday = moment().isSame(updatedAt, "day");
          dateString = isToday
            ? `${utils.formatDate(updatedAt, "LT")} Today`
            : utils.formatDate(updatedAt, "LT MM/DD/YY");
        }
        return dateString;
      },
    },
    {
      id: "checkerName",
      accessor: "checker",
      Header: "Checker",
      headerProps: { className: "text-truncate" },
      width: 100,
      Cell: (rowData) => {
        const { checker } = rowData.row.original;
        return checker ? `${checker?.firstName} ${checker?.lastName}` : "-";
      },
    },
  ];

  const buttonColumns = [
    {
      accessor: "sendToCheckerButton",
      Header: "Send Job",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 130,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"warning"}
            onClick={() => {
              changeSequenceStatus(
                rowData.row.original,
                WAITING_FOR_CHECKING_STATUS
              );
            }}
          >
            Send to Checker
          </Button>
        );
      },
    },
    {
      id: "seeJobDetailsButton",
      accessor: "seeJobDetailsButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 125,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original;
              const detailer = sequence.detailer;
              const checker = sequence.checker;
              setSequenceSelected(sequence);
              setDetailerId(
                detailer ? `${detailer.firstName} ${detailer.lastName}` : "-"
              );
              setCheckerId(
                checker ? `${checker.firstName} ${checker.lastName}` : "-"
              );
              setSequenceStatusId(sequence.sequenceStatusId);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const changeSequenceStatus = async (sequence, sequenceStatusId) => {
    try {
      const sentToChecker = sequenceStatusId === WAITING_FOR_CHECKING_STATUS;
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId,
            });
            onClear();
            setInformationModal({
              isOpen: true,
              title: sentToChecker ? "Send to Checker" : "Sequence Update",
              body: sentToChecker
                ? "Sequence sent to Checker successfully."
                : "Sequence updated successfully.",
            });
            setReloadTabs([
              DETAILING_TAB,
              utils.detailingTabs[sequenceStatusId],
            ]);
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => setConfirmationModal(utils.initConfirmationModal),
        title: sentToChecker ? "Send to Checker" : "Sequence Update",
        body: sentToChecker
          ? `
          <div class="text-center">
            Are you sure you are ready to send this job to the ${
              sequence.checker
                ? `${sequence.checker.firstName} ${sequence.checker.lastName}`
                : "Checker assigned"
            } for Checking?
          </div>
        `
          : `
        <div class="text-center">
          Do you confirm you want to update the sequence ${sequence.job.jobNumber}?
        </div>
      `,
        confirmColor: "warning",
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onClear = async () => {
    setDetailerId();
    setCheckerId();
    setSequenceSelected();
    setDetailModal(false);
    setSequenceStatusId();
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    changeSequenceStatus(sequenceSelected, sequenceStatusId);
  };

  return (
    <Container fluid className="px-0">
      <SequencesTable
        columns={
          isDetailerChecker ? buttonColumns : [...columns, ...buttonColumns]
        }
        sequenceStatusId={DETAILING_IN_PROGRESS_STATUS}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal(utils.initInformationModal)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : detailModal ? (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClear}
          readOnly={true}
          checkerId={checkerId}
          detailerId={detailerId}
          enableUpdate={true}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      ) : null}
    </Container>
  );
};

export default DetailingInProgress;
