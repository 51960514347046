import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/review-processes`;

export const reviewProcessApi = {
  getAllReviewProcess: () => {
    return axios(_endpoint, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
