import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
} from "reactstrap";

import { settingsApi } from "../../services/settings";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await settingsApi.getSettings({});
        setSettings(result.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh]);

  const onChangeSettingStatus = (setting, status) => {
    setLoading(true);
    setting.status = status;
    const newSettings = [...settings];
    newSettings.splice(
      newSettings.findIndex((s) => s.id === setting.id),
      1,
      setting
    );
    setSettings(newSettings);
    settingsApi
      .setSetting(setting)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      accessor: "name",
      Header: "Name",
      headerProps: { className: "text-truncate" },
    },
    {
      accessor: "status",
      Header: "Status",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const setting = rowData.row.original;
        return (
          <CustomInput
            checked={setting.status}
            onChange={(event) =>
              onChangeSettingStatus(setting, event.currentTarget.checked)
            }
            type="switch"
            id={setting.id}
            name={setting.id}
            label={setting.status ? "Enabled" : "Disabled"}
            className="cursor-pointer d-flex align-items-center small setting-switch"
          />
        );
      },
    },
  ];

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={settings}
          pageSize={10}
          sortable
        >
          <Card className="mb-3 w-100 overflow-x-auto">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">General Settings</h3>
                <small className="text-muted ml-2 pt-1">
                  ({settings.length})
                </small>
              </div>
              <div className=" d-flex align-items-center justify-content-between">
                <div></div>
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex  mx-2"
                  color="primary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
          </Card>
        </AdvanceTableWrapper>
      </div>
    </Container>
  );
};

export default Settings;
