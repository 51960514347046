import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import { jobInvoicesApi } from "../../../../services/jobInvoicesServices";

import InformationModal from "../../../InformationModal";
import ConfirmationModal from "../../../ConfirmationModal";
import Loader from "../../../Loader";
import CurrencyInput from "../../../CurrencyInput";
import CustomCheckbox from "../../../CustomCheckbox";
import OrderAcknowledgmentsSelector from "./OrderAcknowledgmentsSelector";
import { utils } from "../../../../utils/utils";

const CreateInvoiceModal = ({ onClose, onSubmit }) => {
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [orderAcknowledgments, setOrderAcknowledgments] = useState([]);
  const [jobOrderAcknowledgments, setJobOrderAcknowledgments] = useState([]);

  const [jobInvoice, setJobInvoice] = useState({});

  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );

  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const doSubmit = async (e) => {
    e.preventDefault();

    if (!orderAcknowledgments.length) {
      if (moment(jobDetails.job.createdAt).isBefore(utils.RELESASE_DATE)) {
        const modalMessage = jobOrderAcknowledgments.length
          ? "There's no order acknowledgements selected for this job."
          : "There's no order acknowledgements for this job.";

        setConfirmationModal({
          isOpen: true,
          onSubmit: async () => {
            await createJobInvoice();
          },
          onClose: () => setConfirmationModal(utils.initConfirmationModal),
          title: "Create Job Invoice",
          body: `
          <div class="text-center">
            ${modalMessage} Do you confirm that you want to continue?
          </div>
        `,
          confirmColor: "warning",
        });
        return;
      }

      if (jobOrderAcknowledgments.length) {
        setInformationModal({
          isOpen: true,
          title: "Create Job Invoice",
          body: "At least one order acknowledgement must be selected.",
        });
        return;
      }
      setInformationModal({
        isOpen: true,
        title: "Create Job Invoice",
        body: "There's no order acknowledgement for this job. If you want to generate the invoice, then at least one order acknowledgement must be created and selected.",
      });
      return;
    }
    await createJobInvoice();
  };

  const createJobInvoice = async () => {
    try {
      setLoading(true);
      await jobInvoicesApi.createJobInvoice({
        ...jobInvoice,
        jobId: jobDetails.job.id,
        freightPrePaidAdded: jobInvoice.freightPrePaidAdded || null,
        orderAcknowledgments: orderAcknowledgments.map((qi) => ({
          id: qi.id,
        })),
      });
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Create Job Invoice",
        body: "Job Invoice created successfully.",
        onClose: () => {
          setInformationModal(utils.initInformationModal);
          onSubmit();
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Create Job Invoice",
        body: "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Create Job Invoice
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <div className="col">
                <Row className="col-12 mb-3">
                  <Col row={12} className="px-0">
                    <Label>
                      Description
                      <small className="text-danger ml-1">*</small>
                    </Label>
                    <Input
                      placeholder="Enter description.."
                      type="textarea"
                      required={true}
                      maxLength={4096}
                      value={jobInvoice?.description || ""}
                      onChange={(e) =>
                        setJobInvoice({
                          ...jobInvoice,
                          description: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={12} className="px-0">
                    <Label className="mb-n1">
                      Select order acknowledgements to invoice
                      <small className="text-danger ml-1">*</small>
                    </Label>
                    <OrderAcknowledgmentsSelector
                      orderAcknowledgmentsSelected={orderAcknowledgments}
                      setOrderAcknoledgmentsSelected={setOrderAcknowledgments}
                      setJobOrderAcknowledgments={setJobOrderAcknowledgments}
                    />
                  </Col>
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={12} className="px-0">
                    <Label>
                      Amount
                      <small className="text-danger ml-1">*</small>
                    </Label>
                    <CurrencyInput
                      placeholder="Enter amount.."
                      value={jobInvoice?.rawAmount || ""}
                      required={true}
                      onChange={({ numericValue, rawValue }) =>
                        setJobInvoice({
                          ...jobInvoice,
                          amount: numericValue,
                          rawAmount: rawValue,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={12} className="px-0">
                    <Label>Freight Pre-Paid/Added</Label>
                    <CurrencyInput
                      placeholder="Enter amount.."
                      value={jobInvoice?.rawFreightPrePaidAdded || ""}
                      onChange={({ numericValue, rawValue }) =>
                        setJobInvoice({
                          ...jobInvoice,
                          freightPrePaidAdded: numericValue,
                          rawFreightPrePaidAdded: rawValue,
                        })
                      }
                    />
                  </Col>
                </Row>
                <div
                  className="col-12 d-flex justify-content-start"
                  onClick={() =>
                    setJobInvoice({
                      ...jobInvoice,
                      isTaxable: !jobInvoice.isTaxable,
                    })
                  }
                >
                  <Label className="mb-0 mr-2">Job: Is Taxable</Label>
                  <CustomCheckbox checked={jobInvoice.isTaxable} />
                </div>
              </div>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"primary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"success"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateInvoiceModal;
