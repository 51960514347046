import React from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";

const QuoteRecapSubContractorsSection = ({ quoteItem }) => {
  const { quoteItemSubcontractorRoles, margin } = quoteItem;

  const subtotal = quoteItemSubcontractorRoles.reduce(
    (p, c) => p + parseFloat(c.cost),
    0
  );

  const totalSale = quoteItemSubcontractorRoles.reduce(
    (p, c) => p + parseFloat(c.cost * (1 + margin / 100)),
    0
  );

  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {quoteItemSubcontractorRoles
          .filter((role) => role.cost > 0)
          .sort((x, y) =>
            x.subcontractorRole?.code?.localeCompare(y.subcontractorRole?.code)
          )
          .map((role) => {
            const { subcontractorRole, cost, percent } = role;
            const itemMargin = percent || margin;
            const sale = cost * (1 + itemMargin / 100);

            return (
              <tr key={role.id}>
                <td className="py-0 text-truncate" width={"40%"}>
                  {subcontractorRole.name}
                </td>
                <td className="py-0" width={"15%"} />
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(cost)}
                  </div>
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatPercent(itemMargin, 100)}
                  </div>
                </td>
                <td className="py-0" width={"15%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(sale)}
                  </div>
                </td>
              </tr>
            );
          })}
        <tr>
          <td className="font-weight-bold py-0" width={"40%"}>
            Total Subcontractors
          </td>
          <td className="py-0" width={"15%"} />
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(subtotal)}
            </div>
          </td>
          <td className="py-0" width={"15%"} />
          <td className="py-0" width={"15%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(totalSale)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteRecapSubContractorsSection;
