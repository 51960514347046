import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import InformationModal from "../../InformationModal";

import Loader from "../../Loader";

import { jobsApi } from "../../../services/jobServices";
import moment from "moment";
import JobForm from "./JobForm";

const STATUS_RFQ = 1;

const JobModal = ({ onClose, onSubmit }) => {
  const [job, setJob] = useState({
    name: "",
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!job.receivedById) {
      return setInformationModal({
        isOpen: true,
        title: "Create Job",
        body: "Please select received by.",
      });
    }
    if (!job.estimatorId) {
      return setInformationModal({
        isOpen: true,
        title: "Create Job",
        body: "Please select assigned estimator.",
      });
    }
    if (!job.customerId) {
      return setInformationModal({
        isOpen: true,
        title: "Create Job",
        body: "Please select customer.",
      });
    }
    if (!job.customerContactId) {
      return setInformationModal({
        isOpen: true,
        title: "Create Job",
        body: "Please select a customer contact.",
      });
    }
    setLoading(true);
    const data = {
      ...job,
      receivedDate:
        moment(job.receivedDate).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      quoteDue:
        moment(job.quoteDue).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
    };
    if (!data.customerRFQNumber?.length) {
      delete data.customerRFQNumber;
    }
    try {
      const job = await jobsApi.createJob({
        ...data,
        statusId: STATUS_RFQ,
      });
      onSubmit(job.id);
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Create Request for Quote",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Create Job
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <JobForm job={job} setJob={setJob} />
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default JobModal;
