import React, { Fragment, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import {
  Button,
  Col,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  ACTIONS,
  useJobDetails,
} from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { useQuoteRecap } from "../../../../../providers/quoteRecapProvider";
import { jobsApi } from "../../../../../services/jobServices";
import { utils } from "../../../../../utils/utils";
import ConfirmationModal from "../../../../ConfirmationModal";
import InformationModal from "../../../../InformationModal";
import Loader from "../../../../Loader";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const QuoteTotalResumeSection = () => {
  const [jobDetailsContext] = useJobDetails();
  const [quoteRecapContext] = useQuoteRecap();
  const [, setQuoteItemsContext] = useQuoteItems();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();

  const [marginAmount, setMarginAmount] = useState();
  const [marginPercent, setMarginPercent] = useState();
  const [markupPercentage, setMarkupPercentage] = useState();
  const [salePrice, setSalePrice] = useState();
  const [updateItem, setUpdateItem] = useState("");

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const marginPercent =
      (quoteRecapContext.marginAmount / quoteRecapContext.salePrice) * 100;
    setMarginPercent(marginPercent);
    setMarkupPercentage(quoteRecapContext.markupPercentage);
    setMarginAmount(quoteRecapContext.marginAmount);
    setSalePrice(quoteRecapContext.salePrice);
  }, [
    quoteRecapContext.marginAmount,
    quoteRecapContext.markupPercentage,
    quoteRecapContext.salePrice,
    refresh,
  ]);

  const onSubmitMarginUpdate = async () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          let newMarginAmount = 0;
          switch (updateItem) {
            case "Margin Amount":
              newMarginAmount = marginAmount;
              break;
            case "Markup Percentage":
              newMarginAmount =
                (quoteRecapContext.totalCost * markupPercentage) / 100;
              break;
            case "Sale Price":
              const newMarkupPercentage =
                ((salePrice - quoteRecapContext.totalCost) * 100) /
                quoteRecapContext.totalCost;
              newMarginAmount =
                (quoteRecapContext.totalCost * newMarkupPercentage) / 100;
              break;

            default:
              break;
          }

          if (!newMarginAmount) {
            setConfirmationModal(initConfirmationModal);
            return setInformationModal({
              isOpen: true,
              title: `Update ${updateItem}`,
              body: `The new value will result in no margin. Margin must be more than zero. Please review`,
            });
          }
          if (newMarginAmount < 0) {
            setConfirmationModal(initConfirmationModal);
            return setInformationModal({
              isOpen: true,
              title: `Update ${updateItem}`,
              body: `The new value will result in a negative margin. Margin can't be negative. Please review`,
            });
          }
          try {
            setLoading(true);
            await jobsApi.updateQuoteTotalMargin({
              id: jobDetailsContext.job.id,
              newMarginAmount,
              currentMarginAmount: quoteRecapContext.marginAmount,
            });
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: `Update ${updateItem}`,
              body: `${updateItem} Updated Successfully.`,
              onClose: () => {
                setInformationModal({ isOpen: false });
                setQuoteItemsContext({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          } catch (err) {
            setInformationModal({
              isOpen: true,
              title: `Update ${updateItem}`,
              body:
                err?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          }
        } catch (err) {
          setRefresh(!refresh);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Update Quote Total Margin",
      body: `
                <p class="text-center">Are you sure you want to apply these changes to the quote total?</p>
                <span>It will overwrite the margins applied to each line item.</span>
            `,
      confirmColor: "danger",
    });
  };

  return (
    <Fragment>
      <Form className="mt-4">
        <ListGroup>
          <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter">
            <small className="font-weight-bold text-muted">Line Total</small>
            {loading ? (
              <div className="min-width-50">
                <Loader size="sm" />
              </div>
            ) : isJobLocked ? null : (
              <Button
                color="success"
                className="rounded"
                size="sm"
                onClick={onSubmitMarginUpdate}
              >
                Save
              </Button>
            )}
          </ListGroupItem>
          <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center flex-wrap px-0 pb-0">
            <Col className="col-2">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">Weight</small>
                <div readOnly className="form-control border text-right m-1">
                  {utils.formatWeight(quoteRecapContext.totalWeight || 0)}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-2">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">Shop Hrs.</small>
                <div readOnly className="form-control border text-right">
                  {utils.formatHour(quoteRecapContext.shopHours || 0)}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-2">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">
                  Office Hrs.
                </small>
                <div readOnly className="form-control border text-right">
                  {utils.formatHour(quoteRecapContext.officeHours || 0)}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-2">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">Cost </small>
                <div readOnly className="form-control border text-right">
                  {utils.formatCurrency(quoteRecapContext.totalCost || 0)}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-2">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="text-center font-weight-bold text-muted">
                  Cost Per Lb.
                </small>
                <div readOnly className="form-control border text-right">
                  {utils.formatCurrency(
                    quoteRecapContext.totalWeight
                      ? quoteRecapContext.totalCost /
                          quoteRecapContext.totalWeight
                      : 0
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-2">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="text-center font-weight-bold text-muted">
                  Sale Per Lb.
                </small>
                <div readOnly className="form-control border text-right">
                  {utils.formatCurrency(quoteRecapContext.salePerLB)}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-3">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="text-center font-weight-bold text-muted">
                  Margin Percent
                </small>
                <NumericFormat
                  required={true}
                  maxLength={20}
                  decimalScale={2}
                  className={`form-control border text-right ${
                    marginPercent >= 20 ? "text-success" : "text-danger"
                  }`}
                  value={marginPercent || 0}
                  readOnly={true}
                  thousandSeparator={true}
                  prefix={""}
                  suffix={"%"}
                />
              </FormGroup>
            </Col>
            <Col className="col-3">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">
                  Margin Amount
                </small>
                <NumericFormat
                  required={true}
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  className="form-control border text-right"
                  value={marginAmount || 0}
                  readOnly={isJobLocked}
                  thousandSeparator={true}
                  onFocus={() => isJobLocked || setMarginAmount(0)}
                  onValueChange={(e) => {
                    setMarginAmount(e.value);
                    setUpdateItem("Margin Amount");
                  }}
                  prefix={"$"}
                />
              </FormGroup>
            </Col>
            <Col className="col-3">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">
                  Markup Percentage
                </small>
                <NumericFormat
                  className="form-control border text-right"
                  value={markupPercentage || 0}
                  readOnly={isJobLocked}
                  thousandSeparator={true}
                  decimalScale={2}
                  onFocus={() => isJobLocked || setMarkupPercentage(0)}
                  onValueChange={(e) => {
                    setMarkupPercentage(e.value);
                    setUpdateItem("Markup Percentage");
                  }}
                  prefix={""}
                  suffix={"%"}
                />
              </FormGroup>
            </Col>
            <Col className="col-3">
              <FormGroup className="d-flex flex-column align-items-center">
                <small className="font-weight-bold text-muted">
                  Sale Price
                </small>
                <NumericFormat
                  required={true}
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  onFocus={() => isJobLocked || setSalePrice(0)}
                  onValueChange={(e) => {
                    setSalePrice(e.value);
                    setUpdateItem("Sale Price");
                  }}
                  className="form-control border text-right"
                  value={utils.formatNumber(salePrice) || 0}
                  readOnly={isJobLocked}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </FormGroup>
            </Col>
          </ListGroupItem>
        </ListGroup>
      </Form>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Fragment>
  );
};

export default QuoteTotalResumeSection;
