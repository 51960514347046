import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { workOrderApi } from "../../../../../services/workOrderServices";

import InformationModal from "../../../../InformationModal";
import Loader from "../../../../Loader";
import "react-day-picker/dist/style.css";
import { utils } from "../../../../../utils/utils";

const WorkOrderNotesModal = ({ workOrder, onClose, onSubmit }) => {
  const [loading, setLoading] = useState();
  const [notes, setNotes] = useState(workOrder.notes);
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await workOrderApi.updateWorkOrder({ ...workOrder, notes });
      onSubmit();
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Updated Work Order",
        body: "Work Order updated successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false });
          onClose();
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Updated Work Order",
        body: "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Work Order Notes
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <div className="col">
                <Row>
                  <Col row={12}>
                    <Label>Notes</Label>
                    <Input
                      placeholder="Notes..."
                      maxLength="4096"
                      type="textarea"
                      rows="7"
                      value={notes || ""}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderNotesModal;
