import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Col, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";

import CustomCheckbox from "../../../../CustomCheckbox";

const QuoteItemModalSubcontractorRoles = ({
  selectedSubcontractorRoles,
  setSelectedSubcontractorRoles,
}) => {
  const [commonDataContext] = useCommonData();
  const [openSection, setOpenSection] = useState();

  const onItemClick = (subcontractorRole) => {
    setSelectedSubcontractorRoles({
      ...selectedSubcontractorRoles,
      [subcontractorRole.id]: !selectedSubcontractorRoles[subcontractorRole.id],
    });
  };

  const onSelectAllClick = () => {
    const allChecked =
      Object.keys(selectedSubcontractorRoles).filter(
        (sc) => selectedSubcontractorRoles[sc]
      ).length === commonDataContext.quoteItemSubcontractorRoles.length;
    const newSelectedSubcontractorRoles =
      commonDataContext.quoteItemSubcontractorRoles.reduce(
        (p, c) => ({
          ...p,
          [c.id]: !allChecked,
        }),
        {}
      );
    setSelectedSubcontractorRoles({
      ...newSelectedSubcontractorRoles,
    });
  };

  return commonDataContext.quoteItemSubcontractorRoles.length ? (
    <Col className="col-12 mt-3">
      <ListGroup>
        <ListGroupItem
          className={`d-flex justify-content-between align-items-center bg-lighter cursor-pointer border-bottom-${
            openSection ? 0 : 1
          }`}
          onClick={() => setOpenSection(!openSection)}
        >
          <div>
            <span>Subcontractor Roles</span>
          </div>
          <FontAwesomeIcon
            icon={openSection ? faChevronDown : faChevronUp}
            className="text-muted"
          />
        </ListGroupItem>
        <Collapse isOpen={openSection}>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center cursor-pointer"
            onClick={() => onSelectAllClick()}
          >
            <div className="flex-shrink-0">Select all</div>
            <CustomCheckbox
              checked={
                Object.keys(selectedSubcontractorRoles).filter(
                  (c) => selectedSubcontractorRoles[c]
                ).length ===
                commonDataContext.quoteItemSubcontractorRoles.length
              }
            />
          </ListGroupItem>
          {commonDataContext.quoteItemSubcontractorRoles.map(
            (subcontractorRole) => (
              <ListGroupItem
                key={subcontractorRole.id}
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => onItemClick(subcontractorRole)}
              >
                <div className="flex-shrink-0">{subcontractorRole.name}</div>
                <CustomCheckbox
                  checked={selectedSubcontractorRoles[subcontractorRole.id]}
                />
              </ListGroupItem>
            )
          )}
        </Collapse>
      </ListGroup>
    </Col>
  ) : null;
};

export default QuoteItemModalSubcontractorRoles;
