import React from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const QuoteRecapManagementSection = ({ quoteItem }) => {
  const [jobDetails] = useJobDetails();
  const itemsWithHours = quoteItem.quoteItemManagementRoles.filter(
    (quoteItemManagementRole) => quoteItemManagementRole.hours > 0
  );
  const fieldName = utils.getFieldNameToUseAsCostByHour(jobDetails.job);
  return (
    <Table className="col-12 mt-4 px-0 border rounded mb-0">
      <tbody>
        {itemsWithHours
          .sort((x, y) =>
            x.managementRole.code < y.managementRole.code ? -1 : 1
          )
          .map((quoteItemManagementRole) => {
            const hours = quoteItemManagementRole.hours || 0;
            const costByHour =
              quoteItemManagementRole.managementRole.costByHoursAndYear[
                fieldName
              ] || 0;
            const totalCost = hours * costByHour || 0;
            const saleByHour = costByHour * (1 + quoteItem.margin / 100) || 0;
            const totalSale =
              hours * costByHour * (1 + quoteItem.margin / 100) || 0;
            return (
              <tr key={quoteItemManagementRole.id}>
                <td className="py-0 text-truncate" width={"35%"}>
                  {`${quoteItemManagementRole.managementRole.code} - ${quoteItemManagementRole.managementRole.name}`}
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatHour(hours)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(costByHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalCost)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(saleByHour)}
                  </div>
                </td>
                <td className="py-0" width={"13%"}>
                  <div readOnly className="form-control border text-right m-1">
                    {utils.formatCurrency(totalSale)}
                  </div>
                </td>
              </tr>
            );
          })}
        <tr>
          <td className="font-weight-bold py-0" width={"35%"}>
            Total Office Labor
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatHour(
                itemsWithHours.reduce((p, c) => p + c.hours, 0)
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce((p, c) => p + c.hours, 0)
                  ? itemsWithHours.reduce(
                      (p, c) =>
                        p +
                        c.managementRole.costByHoursAndYear[fieldName] *
                          c.hours,
                      0
                    ) / itemsWithHours.reduce((p, c) => p + c.hours, 0)
                  : 0
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce(
                  (p, c) =>
                    p +
                    c.managementRole.costByHoursAndYear[fieldName] * c.hours,
                  0
                )
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce(
                  (p, c) =>
                    p +
                    c.managementRole.costByHoursAndYear[fieldName] *
                      (1 + quoteItem.margin / 100),
                  0
                )
              )}
            </div>
          </td>
          <td className="py-0" width={"13%"}>
            <div
              readOnly
              className="form-control border text-right m-1 font-weight-bold"
            >
              {utils.formatCurrency(
                itemsWithHours.reduce(
                  (p, c) =>
                    p +
                    c.hours *
                      c.managementRole.costByHoursAndYear[fieldName] *
                      (1 + quoteItem.margin / 100),
                  0
                )
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default QuoteRecapManagementSection;
