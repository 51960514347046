import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import JobSummaryReport from "./JobSummaryReport";

const JOB_SUMMARY_REPORT = "JOB_SUMMARY_REPORT";

const ReportsTab = () => {
  const [activeTab, setActiveTab] = useState(JOB_SUMMARY_REPORT);

  const toggle = (selected) => {
    const id = selected?.id || selected;
    if (activeTab !== id) {
      setActiveTab(id);
    }
  };

  return (
    <Col className="mt-3 col-12">
      <div className="d-flex align-items-start justify-content-between">
        <h3>Quote</h3>
      </div>
      <Row>
        <Col>
          <Nav
            tabs
            className="d-flex align-items-center job-details-nav cursor-pointer mt-3"
          >
            <div className="d-flex flex-grow-1">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === JOB_SUMMARY_REPORT,
                  })}
                  onClick={() => toggle(JOB_SUMMARY_REPORT)}
                >
                  Quote PDF
                </NavLink>
              </NavItem>
            </div>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={JOB_SUMMARY_REPORT}>
              <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  {activeTab === JOB_SUMMARY_REPORT ? (
                    <JobSummaryReport />
                  ) : null}
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Col>
  );
};

export default ReportsTab;
