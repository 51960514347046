import React, { useEffect, useState } from "react";
import Loader from "../../../Loader";
import DatePicker from "../../../DatePicker";
import CustomCheckbox from "../../../CustomCheckbox";
import InformationModal from "../../../InformationModal";
import ConfirmationModal from "../../../ConfirmationModal";
import { reviewProcessApi } from "../../../../services/reviewProcessServices";
import { sequenceApi } from "../../../../services/sequenceServices";
import { utils } from "../../../../utils/utils";
import {
  Button,
  Col,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const DATE_FORMAT = "YYYY-MM-DD";

const DetailingInfoModal = ({ jobId, onClose, onSubmit }) => {
  const [loading, setLoading] = useState();
  const [reviewProcess, setReviewProcess] = useState([]);
  const [description, setDescription] = useState();
  const [reviewProcessId, setReviewProcessId] = useState();
  const [revisionDate, setRevisionDate] = useState(new Date());
  const [notes, setNotes] = useState();

  const [informationModal, setInformationModal] = useState(utils.initInformationModal);
  const [confirmationModal, setConfirmationModal] = useState(utils.initConfirmationModal);

  const isFormFulled = () =>
    description !== undefined &&
    reviewProcessId !== undefined &&
    revisionDate !== undefined;

  const onConfirm = async () => {
    await sequenceApi.createSequence({
      jobId,
      description,
      reviewProcessId,
      revisionDate: utils.formatDate(revisionDate, DATE_FORMAT),
      notes,
    });
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal({
      isOpen: true,
      title: "Sequence Creation",
      body: "Sequence created successfully",
      onClose: () => {
        onClear();
        onSubmit();
      },
    });
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!isFormFulled()) return;
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await onConfirm();
        } catch (error) {
          setConfirmationModal(utils.initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
            onClose: () => setInformationModal(utils.initInformationModal)
          });
        }
      },
      onClose: () => {
        setConfirmationModal(utils.initConfirmationModal);
      },
      title: "Sequence Creation",
      body: `
        <div class="text-center">
          Are you sure you you want to create Sequence: ${description}?
        </div>
      `,
      confirmColor: "warning",
    });
  };

  const onClear = () => {
    setLoading();
    setReviewProcess([]);
    setDescription("");
    setReviewProcessId();
    setRevisionDate(new Date());
    setNotes("");
    setInformationModal(utils.initInformationModal);
    setConfirmationModal(utils.initConfirmationModal);
    onClose();
  };

  // Load review processes
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await reviewProcessApi.getAllReviewProcess();
        setLoading(false);
        setReviewProcess(data);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return loading ? (
    <Loader />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal(utils.initInformationModal)
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Detailing Sequence
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <FormGroup>
                <Label for="description">
                  <span>Description</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <Input
                  required
                  className="height-40p border"
                  placeholder="Enter description..."
                  type="text"
                  maxLength="255"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-12">
              <FormGroup>
                <Label for="reviewProcessId">
                  <span>Review Process</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <ListGroup>
                  {reviewProcess.map((item) => (
                    <ListGroupItem
                      key={item.id}
                      className="d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => setReviewProcessId(item.id)}
                    >
                      <span>{item.description}</span>
                      <CustomCheckbox checked={reviewProcessId === item.id} />
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <Input
                  required
                  type="hidden"
                  name="reviewProcessId"
                  value={reviewProcessId}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-12">
              <FormGroup>
                <Label for="revisionDate">
                  <span>REV 0 / REV A Needed</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <DatePicker
                  date={revisionDate}
                  required={true}
                  onClick={(date) => setRevisionDate(date)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-12">
              <FormGroup>
                <Label className="mb-0" for="subject">
                  Notes and Special Instructions
                </Label>
                <Input
                  maxLength="100000000000"
                  type="textarea"
                  id="notes"
                  name="notes"
                  placeholder="Enter sequence notes..."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows="5"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color={"primary"}
                type="submit"
                disabled={!isFormFulled()}
              >
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default DetailingInfoModal;
