import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { useAuth } from "../../../providers/authProvider";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import CustomPeriodSelector from "../../../components/CustomPeriodSelector";
import { utils } from "../../../utils/utils";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PeriodTypeSelector from "../../../components/PeriodTypeSelector";

const columns = {
  "Job Number": {
    type: "string",
  },
  Customer: {
    type: "string",
  },
  "Customer PO#": {
    type: "string",
  },
  Description: {
    type: "string",
  },
  "Product Type": {
    type: "string",
  },
  "Contract Date": {
    type: "string",
  },
  "Contract Amount": {
    type: "number",
  },
  "Shipped Complete Date": {
    type: "string",
  },
  "Est Shop Hours": {
    type: "string",
  },
  "Cost Est Shop Hours": {
    type: "string",
  },
  "Actual Shop Hours": {
    type: "string",
  },
  "Cost Actual Shop Hours": {
    type: "string",
  },
  "Est Eng Hours": {
    type: "string",
  },
  "Cost Est Eng Hours": {
    type: "string",
  },
  "Actual Eng Hours": {
    type: "string",
  },
  "Cost Actual Eng Hours": {
    type: "string",
  },
  "Job Weight (lbs) BR or Tekla": {
    type: "string",
  },
  "Labor Factor (LF) (Man Hours/Job Weight rounded to 3 decimal places": {
    type: "string",
  },
  "Cost/lb": {
    type: "string",
  },
  "Sale Price/lb": {
    type: "string",
  },
  "Job Total Cost": {
    type: "string",
  },
  "Actual Margin $": {
    type: "number",
  },
  "Actual Margin %": {
    type: "string",
  },
  "Estimated Margin $": {
    type: "string",
  },
  "Estimated Margin %": {
    type: "string",
  },
  "Actual Exceed Estimate": {
    type: "string",
  },
  Manager: {
    type: "string",
  },
  "Invoice Date": {
    type: "string",
  },
  "Invoiced Amount": {
    type: "string",
  },
  "Week": {
    type: "string",
  },
  "Month": {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [columns],
  },
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  slice: {
    rows: [
      {
        uniqueName: "Job Number",
      },
      {
        uniqueName: "Customer",
      },
      {
        uniqueName: "Customer PO#",
      },
      {
        uniqueName: "Description",
      },
      {
        uniqueName: "Product Type",
      },
      {
        uniqueName: "Contract Date",
      },
      {
        uniqueName: "Contract Amount",
      },
      {
        uniqueName: "Shipped Complete Date",
      },
      {
        uniqueName: "Est Shop Hours",
      },
      {
        uniqueName: "Cost Est Shop Hours",
      },
      {
        uniqueName: "Actual Shop Hours",
      },
      {
        uniqueName: "Cost Actual Shop Hours",
      },
      {
        uniqueName: "Est Eng Hours",
      },
      {
        uniqueName: "Cost Est Eng Hours",
      },
      {
        uniqueName: "Actual Eng Hours",
      },
      {
        uniqueName: "Cost Actual Eng Hours",
      },
      {
        uniqueName: "Job Weight (lbs) BR or Tekla",
      },
      {
        uniqueName: "Labor Factor (LF) (Man Hours/Job Weight rounded to 3 decimal places",
      },
      {
        uniqueName: "Cost/lb",
      },
      {
        uniqueName: "Sale Price/lb",
      },
      {
        uniqueName: "Job Total Cost",
      },
      {
        uniqueName: "Actual Margin $",
        grandTotals: true,
      },
      {
        uniqueName: "Actual Margin %",
      },
      {
        uniqueName: "Estimated Margin $",
      },
      {
        uniqueName: "Estimated Margin %",
      },
      {
        uniqueName: "Actual Exceed Estimate",
      },
      {
        uniqueName: "Manager",
      },
      {
        uniqueName: "Invoice Date",
      },
      {
        uniqueName: "Invoiced Amount",
      },
      {
        uniqueName: "Week",
      },
      {
        uniqueName: "Month",
      },
    ],
    measures: [
      {
        uniqueName: "Contract Amount",
        format: "currency",
      },
      {
        uniqueName: "Actual Margin $",
        format: "currency",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      title: "Job Book Report EVA",
      grandTotalsPosition: "bottom",
    },
  },
};

const TYPE_ANY = "TYPE_ANY";
const TYPE_CUSTOM = "TYPE_CUSTOM";
const CURRENCY_FIELDS_TO_FORMAT = [];
const DATE_FIELDS_TO_FORMAT = [
  "Contract Date",
  "Est. Ship Date",
  "Shipped Complete Date",
  "Update Ship Date",
];

const JobBookReportEva = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);

  const [periodType, setPeriodType] = useState({
    value: TYPE_CUSTOM,
    label: "Date Range",
  });

  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    const condition = {};
    if (periodType.value !== TYPE_ANY) {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .salesAndJobBookEva(condition)
      .then((result) => {
        result = utils.formatCurrencyToObjectsInArray(
          result,
          CURRENCY_FIELDS_TO_FORMAT
        );
        result = utils.formatDatesToObjectsInArray(
          result,
          DATE_FIELDS_TO_FORMAT
        );
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, ...result],
          });
        }
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    pivot,
    customEndDate,
    customStartDate,
    authContext.currentUser,
    refresh,
    periodType,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Job Book Report EVA</h3>
              <div className="d-flex align-items-center">
                {loading ? (
                  <div className="min-width-50">
                    <Loader size="sm" className="mr-3" />
                  </div>
                ) : null}
                <PeriodTypeSelector
                  periodType={periodType}
                  setPeriodType={setPeriodType}
                />
                {periodType.value !== TYPE_ANY ? (
                  <CustomPeriodSelector
                    defaultEndDate={customEndDate}
                    defaultStartDate={customStartDate}
                    onSubmit={(startDate, endDate) => {
                      setCustomStartDate(startDate);
                      setCustomEndDate(endDate);
                    }}
                  />
                ) : null}
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex ml-3"
                  color="secondary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  key={customStartDate + "-" + customEndDate}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utils.customizeToolbarReport(
                      toolbar,
                      pivot,
                      "job_book_report"
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default JobBookReportEva;
