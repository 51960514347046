import React, { useState } from "react";
import DatePicker from "../../DatePicker";
import { utils } from "../../../utils/utils";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { sequenceRevisionApi } from "../../../services/sequenceRevisionServices";
const DATE_FORMAT = "YYYY-MM-DD";

const CreateNewRevisionModal = ({
  showRevisionModal,
  onClose = () => {},
  beforeSubmit = () => {},
  afterSubmit = () => {},
  sequenceStatusId,
  sequenceId,
  revisionNeeded,
}) => {
  const [notes, setNotes] = useState(null);
  const [revisionName, setRevisionName] = useState("");
  const [revisionDate, setRevisionDate] = useState(new Date());
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!revisionName) return;
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await beforeSubmit();
          const revisionData = {
            sequenceId,
            name: revisionName,
            notes,
            sequenceStatusId,
          };

          if (revisionNeeded) {
            revisionData.revisionDate = utils.formatDate(
              revisionDate,
              DATE_FORMAT
            );
          }
          await sequenceRevisionApi.createRevision(revisionData);
          await afterSubmit();
          setInformationModal({
            isOpen: true,
            title: "Create New Revision",
            body: "Revision created successfully",
            onClose: () => {
              onClear();
            },
          });
        } catch (error) {
          onClear();
          setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
          });
        }
      },
      onClose: () => onClear(),
      title: "Create New Revision",
      body: `
        <div class="text-center">
          Are you sure you you want to create revision: ${revisionName}?
        </div>
      `,
      confirmColor: "warning",
    });
  };

  const onClear = () => {
    onClose();
    setNotes(null);
    setRevisionName("");
    setRevisionDate(new Date());
    setInformationModal(utils.initInformationModal);
    setConfirmationModal(utils.initConfirmationModal);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClear}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={onClear}
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={showRevisionModal}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Create New Revision
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <FormGroup>
                <Label for="revisionName">
                  <span>Revision Name</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <Input
                  required
                  className="height-40p border"
                  placeholder="Enter a revision name..."
                  type="text"
                  maxLength="255"
                  name="revisionName"
                  value={revisionName}
                  onChange={(e) => setRevisionName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          {revisionNeeded ? (
            <Row className="mt-3">
              <Col className="col-12">
                <FormGroup>
                  <Label for="revisionDate">
                    <span>Revision Needed</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <DatePicker
                    date={revisionDate}
                    required={true}
                    onClick={(date) => setRevisionDate(date)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
          <Row className="mt-3">
            <Col className="col-12">
              <FormGroup>
                <Label className="mb-0" for="subject">
                  Notes and Special Instructions
                </Label>
                <Input
                  maxLength="100000000000"
                  type="textarea"
                  id="notes"
                  name="notes"
                  placeholder="Enter revision notes..."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows="5"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClear}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit" disabled={!revisionName}>
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateNewRevisionModal;
