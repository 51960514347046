import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/jobs`;

export const jobsApi = {
  getJobsKPIs: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`${_endpoint}/count?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getJobs: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`${_endpoint}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getJobsById: (id) => {
    const request = {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    };
    return axios(`${_endpoint}/${id}`, request)
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createJob: (data) => {
    return axios(`${_endpoint}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateJob: (data) => {
    return axios(`${_endpoint}/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateQuoteTotalMargin: (data) => {
    return axios(`${_endpoint}/${data.id}/quote-total-margin`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  jobSummaryReport: (data) => {
    return axios(`${_endpoint}/pdf-summary-report/${data.jobId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendJobSummaryReport: (data) => {
    return axios(`${_endpoint}/send-summary-report/${data.jobId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendManHourSummaryReport: (data) => {
    return axios(`${_endpoint}/send-man-hours-summary-report/${data.jobId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  manHourSummaryReport: (data) => {
    return axios(`${_endpoint}/man-hour-summary-report/${data.jobId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  contractSpecificationReport: (data) => {
    return axios(`${_endpoint}/contract-specification-report/${data.jobId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendContractSpecificationReport: (data) => {
    return axios(
      `${_endpoint}/send-contract-specification-report/${data.jobId}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        method: "post",
        data,
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },

  workOrderReport: (data) => {
    return axios(`${_endpoint}/work-order-report/${data.jobId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
