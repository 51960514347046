import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  CustomInput,
} from "reactstrap";

import states from "../../../../utils/states";

const EditShipToModal = ({ orderAcknowledgment, onClose, onSubmit }) => {
  const [localOrderAcknowledgment, setLocalOrderAcknowledgment] = useState({
    ...orderAcknowledgment,
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    onSubmit(localOrderAcknowledgment);
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader close={closeBtn}>Edit Ship To Address</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="shippingAddress">Shipping Address</Label>
            <Input
              maxLength="50"
              type="text"
              name="shippingAddress"
              value={localOrderAcknowledgment.shippingAddress || ""}
              placeholder="Shipping Address..."
              onChange={(e) =>
                setLocalOrderAcknowledgment({
                  ...localOrderAcknowledgment,
                  shippingAddress: e.target.value,
                })
              }
            />
          </FormGroup>
          <Row>
            <Col className="col-6">
              <FormGroup>
                <Label for="shippingCity">Shipping City</Label>
                <Input
                  maxLength="50"
                  type="text"
                  name="shippingCity"
                  placeholder="Shipping City..."
                  value={localOrderAcknowledgment.shippingCity || ""}
                  onChange={(e) =>
                    setLocalOrderAcknowledgment({
                      ...localOrderAcknowledgment,
                      shippingCity: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup>
                <Label for="shippingState">Shipping State</Label>
                <CustomInput
                  id="sStateSelect"
                  type="select"
                  name="sStateSelect"
                  value={localOrderAcknowledgment.shippingState || ""}
                  onChange={(e) =>
                    setLocalOrderAcknowledgment({
                      ...localOrderAcknowledgment,
                      shippingState: e.target.value,
                    })
                  }
                >
                  <option value={""} disabled>Select a state..</option>
                  {states.map((state) => (
                    <option key={state.abbreviation} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <FormGroup>
                <Label for="shippingZipCode">Shipping Zip Code</Label>
                <Input
                  maxLength="50"
                  type="text"
                  name="shippingZipCode"
                  placeholder="Shipping Zip Code..."
                  value={localOrderAcknowledgment.shippingZipCode || ""}
                  onChange={(e) =>
                    setLocalOrderAcknowledgment({
                      ...localOrderAcknowledgment,
                      shippingZipCode: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup>
                <Label for="shippingPhoneNumber">Shipping Phone Number</Label>
                <Input
                  maxLength="50"
                  type="text"
                  name="shippingPhoneNumber"
                  placeholder="Shipping Phone Number..."
                  value={localOrderAcknowledgment.shippingPhoneNumber || ""}
                  onChange={(e) =>
                    setLocalOrderAcknowledgment({
                      ...localOrderAcknowledgment,
                      shippingPhoneNumber: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            className="d-flex align-items-center ml-2"
            color="secondary"
            onClick={onClose}
          >
            Discard
          </Button>
          <Button
            className="d-flex align-items-center"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditShipToModal;
