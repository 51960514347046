import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Container } from "reactstrap";
import Loader from "../../Loader";
import { utils } from "../../../utils/utils";
import AdvanceTable from "../../advanceTable/AdvanceTable";
import { sequenceApi } from "../../../services/sequenceServices";
import AdvanceTableWrapper from "../../advanceTable/AdvanceTableWrapper";
import AdvanceTablePagination from "../../advanceTable/AdvanceTablePagination";
import { useAuth } from "../../../providers/authProvider";

const DETAILER_CHECKER_ROLE_ID = 4;
const TABLE_OPTIONS = {
  sizePerPage: 25,
  page: 1,
  sortBy: "createdAt",
  direction: "asc",
  totalPages: 1,
};

const DEFAULT_COLUMNS = [
  {
    id: "jobNumber",
    accessor: "job",
    Header: "Job#",
    headerProps: { className: "text-truncate" },
    width: 100,
    Cell: (rowData) => {
      const { job } = rowData.row.original;
      return job?.jobNumber || "-";
    },
  },
  {
    id: "customerName",
    accessor: "job",
    Header: "Customer Name",
    headerProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { job } = rowData.row.original;
      return job?.customer?.name || "-";
    },
  },
  {
    id: "description",
    accessor: "description",
    disableSortBy: true,
    Header: "Description",
    headerProps: { className: "text-truncate" },
    width: 150,
  },
  {
    id: "revisionDate",
    accessor: "revisionDate",
    Header: "Rev Date",
    headerProps: { className: "text-truncate" },
    width: 130,
    Cell: (rowData) => {
      const { revisionDate } = rowData.row.original;
      return revisionDate ? utils.formatDate(revisionDate) : "-";
    },
  },
  {
    id: "notes",
    accessor: "notes",
    Header: "PM Notes",
    headerProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 250,
    Cell: (rowData) => {
      const { notes } = rowData.row.original;
      return notes || "-";
    },
  },
];

const DETAILER_CHECKER_COLUMNS = [
  {
    accessor: "updatedAt",
    Header: "Assigned At",
    headerProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 120,
    Cell: (rowData) => {
      const { updatedAt } = rowData.row.original;
      let dateString = "-";
      if (updatedAt) {
        dateString = utils.formatDate(updatedAt, "LT MM/DD/YY");
      }
      return dateString;
    },
  },
  {
    id: "detailerName",
    accessor: "detailer",
    Header: "Detailer",
    headerProps: { className: "text-truncate" },
    width: 125,
    Cell: (rowData) => {
      const sequence = rowData.row.original;
      const detailer = sequence?.detailer;
      return detailer ? `${detailer?.firstName} ${detailer?.lastName}` : "-";
    },
  },
  {
    id: "checkerName",
    accessor: "checker",
    Header: "Checker",
    headerProps: { className: "text-truncate" },
    width: 125,
    Cell: (rowData) => {
      const sequence = rowData.row.original;
      const checker = sequence?.checker;
      return checker ? `${checker?.firstName} ${checker?.lastName}` : "-";
    },
  },
];

const SequencesTable = ({
  columns = [],
  sequenceStatusId,
  sequencesTotal,
  setSequencesTotal,
  reloadTabs = [],
  tabName,
}) => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState();
  const [sequences, setSequences] = useState([]);
  const [tableOptions, setTableOptions] = useState(TABLE_OPTIONS);
  const isDetailerChecker =
    authContext.currentUser?.role?.id === DETAILER_CHECKER_ROLE_ID;

  // Load review sequences
  useEffect(() => {
    const loadSequences = async () => {
      try {
        if (reloadTabs.includes(tabName)) {
          setLoading(true);
          const sequences = await sequenceApi.getAllSequences({
            page: tableOptions.page - 1,
            pageSize: tableOptions.sizePerPage,
            sortBy: tableOptions.sortBy,
            direction: tableOptions.direction,
            statusId: sequenceStatusId,
          });
          setLoading(false);
          setSequences(sequences.data);
          setSequencesTotal(sequences.count);
        }
      } catch (err) {
        setLoading(false);
      }
    };
    loadSequences();
  }, [
    setSequencesTotal,
    sequenceStatusId,
    tableOptions.page,
    tableOptions.sizePerPage,
    tableOptions.sortBy,
    tableOptions.direction,
    reloadTabs,
    tabName,
  ]);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          tableOptions.sortBy === sortBy?.id &&
          tableOptions.direction === direction
        ) {
          return;
        }

        setTableOptions({
          ...tableOptions,
          sortBy,
          direction,
        });
      } else {
        setTableOptions({
          ...tableOptions,
          sortBy: null,
          direction: null,
        });
      }
    },
    [tableOptions]
  );

  return (
    <Container fluid className="px-0">
      <div className="w-100">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <AdvanceTableWrapper
            columns={
              isDetailerChecker
                ? [...DEFAULT_COLUMNS, ...DETAILER_CHECKER_COLUMNS, ...columns]
                : [...DEFAULT_COLUMNS, ...columns]
            }
            data={sequences || []}
            pageSize={tableOptions.sizePerPage}
            sortable
            onSort={onSort}
            defaultSort={{
              sortBy: tableOptions.sortBy,
              direction: tableOptions.direction,
            }}
          >
            <Card className="mb-2">
              <CardBody className="col-12 px-0 overflow-x-auto">
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0",
                  }}
                />
              </CardBody>
              {!isDetailerChecker ? (
                <CardFooter>
                  <AdvanceTablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50, 75, 100]}
                    totalCount={sequencesTotal}
                    pageCount={tableOptions.totalPages}
                    currentPage={tableOptions.page - 1}
                    onPageChange={(page) =>
                      setTableOptions({
                        ...tableOptions,
                        page,
                      })
                    }
                    pageSize={tableOptions.sizePerPage}
                    onPageSizeChange={(sizePerPage) =>
                      setTableOptions({
                        ...tableOptions,
                        sizePerPage,
                      })
                    }
                  />
                </CardFooter>
              ) : null}
            </Card>
          </AdvanceTableWrapper>
        )}
      </div>
    </Container>
  );
};

export default SequencesTable;
