import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  CustomInput,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Loader from "../../../Loader";
import InformationModal from "../../../InformationModal";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import { jobsApi } from "../../../../services/jobServices";

const YES = "Yes";
const NO = "No";

const EACH = "Each";
const LFT = "Lft";
const LOT = "Lot";

const JobSummaryModal = ({ onClose, onSubmit }) => {
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [job, setJob] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        ...job,
        hardwareIncluded: job.hardwareIncluded,
        leadTime: job.leadTime,
        notes: job.notes,
        revisionNumber: job.revisionNumber,
      };
      jobsApi.updateJob(data);
      onSubmit();
    } catch (err) {
      return setInformationModal({
        isOpen: true,
        title: "Update Job Contract Specification",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setJob(jobDetails.job);
  }, [jobDetails.job]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Update Sales Quotation
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <div className="col">
                <Row className="col-12 mb-3">
                  <Col row={3}>
                    <Label> Hardware Included? </Label>
                    <CustomInput
                      required
                      type="select"
                      id="hardwareIncluded"
                      value={job.hardwareIncluded || ""}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          hardwareIncluded: e.target.value,
                        })
                      }
                    >
                      <option value={""}>Select ...</option>
                      <option value={YES}>Yes</option>
                      <option value={NO}>No</option>
                    </CustomInput>
                  </Col>
                  <Col row={3}>
                    <Label>Lead Time</Label>
                    <span className="text-danger ml-2">*</span>
                    <Input
                      required
                      id="leadTime"
                      placeholder="Lead Time..."
                      value={job.leadTime || ""}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          leadTime: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col row={3}>
                    <Label> UOM? </Label>
                    <CustomInput
                      required
                      type="select"
                      id="uom"
                      value={job.uom || ""}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          uom: e.target.value,
                        })
                      }
                    >
                      <option value={""}>Select ...</option>
                      <option value={EACH}>Each</option>
                      <option value={LOT}>Lot</option>
                      <option value={LFT}>Lft</option>
                    </CustomInput>
                  </Col>
                  <Col row={3}>
                    <Label>Revision Number</Label>
                    <span className="text-danger ml-2">*</span>
                    <Input
                      required
                      id="revisionNumber"
                      placeholder="Revision Number..."
                      value={job.revisionNumber || ""}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          revisionNumber: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="col-12 mb-3">
                  <Col row={12}>
                    <Label>Notes</Label>
                    <Input
                      placeholder="Notes..."
                      maxLength="4096"
                      type="textarea"
                      rows={7}
                      value={job.notes || ""}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          notes: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default JobSummaryModal;
