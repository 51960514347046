import React, { useEffect, useState } from "react";

import { Col, CustomInput, Label, Row } from "reactstrap";
import { commonApi } from "../../../../services/commonServices";

const YES = "Yes";
const NO = "No";

const CSRForm = ({ jobContractSpecification, setJobContractSpecification }) => {
  const [materialsRequirements, setMaterialRequirements] = useState([]);
  const [shopDrawings, setShopDrawings] = useState([]);
  const [weldingCodes, setWeldingCodes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const materials = await commonApi.getMaterialRequirements();
      const shopDrawings = await commonApi.getShopDrawings();
      const weldingCodes = await commonApi.getWeldingCodes();
      setMaterialRequirements(materials);
      setShopDrawings(shopDrawings);
      setWeldingCodes(weldingCodes);
    };
    fetchData();
  }, []);

  return (
    <div className="col">
      <Row className="col-12 mb-3">
        <Col row={6}>
          <Label>
            <span>Contract Scope Matches Quote?</span>
          </Label>
          <CustomInput
            required
            type="select"
            id="contractScopeMatchesQuote"
            value={jobContractSpecification?.contractScopeMatchesQuote || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                contractScopeMatchesQuote: e.target.value,
              })
            }
          >
            <option value={""}>Select ...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
        <Col row={6}>
          <Label>Are Drawings Current?</Label>
          <CustomInput
            required
            type="select"
            id="drawingsCurrent"
            value={jobContractSpecification?.drawingsCurrent || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                drawingsCurrent: e.target.value,
              })
            }
          >
            <option value={""}>Select ...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
      </Row>
      <Row className="col-12 mb-3">
        <Col row={6}>
          <Label>
            <span>Are Specifications Current?</span>
          </Label>
          <CustomInput
            required
            type="select"
            id="specificationsCurrent"
            value={jobContractSpecification?.specificationsCurrent || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                specificationsCurrent: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
        <Col row={6}>
          <Label>Material Requirements</Label>
          <CustomInput
            required
            type="select"
            id="materialRequirements"
            value={jobContractSpecification?.materialRequirementId || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                materialRequirementId: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            {materialsRequirements.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </CustomInput>
        </Col>
      </Row>
      <Row className="col-12 mb-3">
        <Col row={6}>
          <Label>Customer Supplied Material?</Label>
          <CustomInput
            required
            type="select"
            id="connectionDesignRequired"
            value={jobContractSpecification?.customerSuppliedMaterial || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                customerSuppliedMaterial: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
        <Col row={6}>
          <Label>Shop Drawings</Label>
          <CustomInput
            required
            type="select"
            id="shopDrawings"
            value={jobContractSpecification?.shopDrawingId || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                shopDrawingId: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            {shopDrawings.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </CustomInput>
        </Col>
      </Row>
      <Row className="col-12 mb-3">
        <Col row={6}>
          <Label>Connection Design Required?</Label>
          <CustomInput
            required
            type="select"
            id="connectionDesignRequired"
            value={jobContractSpecification?.connectionDesignRequired || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                connectionDesignRequired: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
        <Col row={6}>
          <Label>Special Inspections Required?</Label>
          <CustomInput
            required
            type="select"
            id="specialInspectionsRequired"
            value={jobContractSpecification?.specialInspectionsRequired || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                specialInspectionsRequired: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
      </Row>
      <Row className="col-12 mb-3">
        <Col row={6}>
          <Label>Sequenced?</Label>
          <CustomInput
            required
            id="sequenced"
            type="select"
            value={jobContractSpecification?.sequenced || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                sequenced: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
        <Col row={6}>
          <Label>Is AISC Required?</Label>
          <CustomInput
            required
            id="aiscRequired"
            type="select"
            value={jobContractSpecification?.aiscRequired || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                aiscRequired: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            <option value={YES}>Yes</option>
            <option value={NO}>No</option>
          </CustomInput>
        </Col>
      </Row>
      <Row className="col-12 mb-3">
        <Col row={12}>
          <Label>Welding Code Required</Label>
          <CustomInput
            required
            type="select"
            id="shopDrawings"
            value={jobContractSpecification?.weldingCodeId || ""}
            onChange={(e) =>
              setJobContractSpecification({
                ...jobContractSpecification,
                weldingCodeId: e.target.value,
              })
            }
          >
            <option value={""}>Select...</option>
            {weldingCodes.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </CustomInput>
        </Col>
      </Row>
    </div>
  );
};

export default CSRForm;
