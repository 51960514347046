import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

export const reportsApi = {
  salesAndJobBook: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/reports/sales-job-book?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.apiHelper.handleError(err));
  },
  salesAndJobBookEva: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/reports/sales-job-book-eva?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.apiHelper.handleError(err));
  },
  annualSales: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/reports/annual-sales?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.apiHelper.handleError(err));
  },
  customerSummary: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/reports/customer-summary?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.apiHelper.handleError(err));
  },
  weeklySummary: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/reports/weekly-summary?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.apiHelper.handleError(err));
  },
};
