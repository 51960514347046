import React from "react";
import { Table } from "reactstrap";
import { utils } from "../../../../utils/utils";

const ShippingHandlingRow = ({
  shippingHandlingOption,
  cost,
  itemMargin,
  sale,
}) => {
  return (
    <tr>
      <td className="py-0" width={"40%"}>
        {shippingHandlingOption.name}
      </td>
      <td className="py-0" width={"15%"} />
      <td className="py-0" width={"15%"}>
        <div readOnly className="form-control border text-right m-1">
          {utils.formatCurrency(cost)}
        </div>
      </td>
      <td className="py-0" width={"15%"}>
        <div readOnly className="form-control border text-right m-1">
          {utils.formatPercent(itemMargin, 100)}
        </div>
      </td>
      <td className="py-0" width={"15%"}>
        <div readOnly className="form-control border text-right m-1">
          {utils.formatCurrency(sale)}
        </div>
      </td>
    </tr>
  );
};

const ShippingHandlingTotalRow = ({ items, margin }) => {
  const cost = items.reduce((total, item) => total + parseFloat(item.cost), 0);
  const sale = items.reduce(
    (total, item) => total + parseFloat(item.cost * (1 + margin / 100)),
    0
  );

  return (
    <tr>
      <td className="font-weight-bold" width={"40%"}>
        Total Shipping and Handling
      </td>
      <td className="py-0" width={"15%"} />
      <td className="py-0" width={"15%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(cost)}
        </div>
      </td>
      <td className="py-0" width={"15%"} />
      <td className="py-0" width={"15%"}>
        <div
          readOnly
          className="form-control border text-right m-1 font-weight-bold"
        >
          {utils.formatCurrency(sale)}
        </div>
      </td>
    </tr>
  );
};

const QuoteRecapShippingHandlings = ({ quoteItem }) => {
  const items = quoteItem.quoteItemShippingHandlings.filter(
    (quoteItemShippingHandling) => quoteItemShippingHandling.cost > 0
  );
  const sortedItems = items.sort((x, y) =>
    x.shippingHandlingOption.name < y.shippingHandlingOption.name ? -1 : 1
  );

  return (
    <Table className="col-12 px-0 border mt-4 rounded mb-0">
      <tbody>
        {sortedItems.map((quoteItemShippingHandling) => {
          const cost = quoteItemShippingHandling.cost || 0;
          const itemMargin =
            quoteItemShippingHandling.percent || quoteItem.margin;
          const sale =
            quoteItemShippingHandling.cost * (1 + quoteItem.margin / 100) || 0;
          return (
            <ShippingHandlingRow
              key={quoteItemShippingHandling.id}
              shippingHandlingOption={
                quoteItemShippingHandling.shippingHandlingOption
              }
              cost={cost}
              itemMargin={itemMargin}
              sale={sale}
            />
          );
        })}
        <ShippingHandlingTotalRow items={items} margin={quoteItem.margin} />
      </tbody>
    </Table>
  );
};

export default QuoteRecapShippingHandlings;
