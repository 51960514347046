import React, { useEffect, useState, useCallback } from "react";
import Loader from "../../../Loader";
import { utils } from "../../../../utils/utils";
import { Button, Col, Row, Collapse, Table } from "reactstrap";
import InformationModal from "../../../InformationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import DetailingInfoModal from "./DetailingInfoModal";
import { sequenceApi } from "../../../../services/sequenceServices";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import CreateNewRevisionModal from "../../detailing/CreateNewRevisionModal";
const DATE_FORMAT = "MM/DD/YY LT";
const WAITING_FOR_ASSIGNMENT_STATUS = 1;
const PAGE_NUMBER = 0;
const PAGE_SIZE = 99999;

const DetailingInfoTab = () => {
  const [sequences, setSequences] = useState([]);
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [sequenceSelected, setSequenceSelected] = useState();
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [detailingInfoModalOpen, setDetailingInfoModalOpen] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [selectedTab, setSelectedTab] = useState();

  const selectSequence = (sequenceId) => {
    if (sequenceId === sequenceSelected) {
      sequenceId = null;
    }
    setSequenceSelected(sequenceId);
  };

  const loadSequences = useCallback(async (jobId) => {
    try {
      setLoading(true);
      const sequences = await sequenceApi.getAllSequences({
        jobId,
        page: PAGE_NUMBER,
        pageSize: PAGE_SIZE,
      });
      setSequences(sequences.data);
      setLoading(false);
      return sequences.data;
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, []);

  // Load review processes
  useEffect(() => {
    loadSequences(jobDetails.job.id);
  }, [jobDetails.job.id, loadSequences]);

  const onClose = () => {
    setShowRevisionModal(false);
  };

  const afterSubmit = async () => {
    const sequences = await loadSequences(jobDetails.job.id);
    if (!selectedTab) {
      const sequence = sequences?.find(
        (sequence) => sequence.id === sequenceSelected
      );
      setSelectedTab(sequence?.revisions[0]?.id);
    }
  };

  return (
    <Col className="mt-3 col-12">
      <Row className="d-flex justify-content-between px-3 align-items-center">
        <h3 className="font-weight-bold">Detailing Sequences</h3>
        <div className="d-flex justify-content-end">
          <Button
            className="rounded"
            size="sm"
            onClick={() => {
              setDetailingInfoModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ml-2">Add Sequence</span>
          </Button>
        </div>
      </Row>
      <Row className="d-flex justify-content-between px-3 mt-4 align-items-center">
        {loading ? (
          <Loader size="sm" />
        ) : informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            onClose={() =>
              informationModal.onClose
                ? informationModal.onClose()
                : setInformationModal(utils.initInformationModal)
            }
          />
        ) : sequences.length ? (
          sequences.map((sequence, index) => (
            <div className="d-flex w-100 mt-2" key={`sequence-${sequence?.id}`}>
              <div className="ml-3 mr-0 flex-grow-1 overflow-x-auto">
                <div
                  onClick={() => {
                    selectSequence(sequence?.id);
                    if (sequence?.revisions?.length) {
                      setSelectedTab(sequence?.revisions[0]?.id);
                    } else {
                      setSelectedTab();
                    }
                  }}
                  className="d-flex justify-content-between align-middle bg-primary border border-primary rounded text-white py-2 px-3 m-0 cursor-pointer"
                >
                  <span>{sequence.description}</span>
                  <div className="px-0 col-3 d-flex align-items-center justify-content-end">
                    <FontAwesomeIcon
                      icon={
                        sequenceSelected === sequence?.id
                          ? faChevronDown
                          : faChevronRight
                      }
                      fixedWidth
                      className="my-1"
                    />
                  </div>
                </div>
                <Collapse
                  isOpen={sequenceSelected === sequence?.id}
                  className="my-2 mb-0"
                >
                  <Row
                    className={`m-0 w-100 justify-content-${
                      sequence?.revisions?.length ? "end" : "between"
                    }`}
                  >
                    {sequence?.revisions?.length ? null : (
                      <h5 className="m-0 ml-2 d-flex justify-content-end flex-column">
                        Detailing History
                      </h5>
                    )}
                    <Button
                      className="rounded"
                      size="sm"
                      onClick={() => {
                        setShowRevisionModal(true);
                      }}
                    >
                      <span>Create New Revision</span>
                    </Button>
                  </Row>
                  <Row className="w-100 m-0">
                    <Table className="mt-2">
                      <thead>
                        <tr className="bg-light border">
                          <th key="dateTime">Date / Time</th>
                          <th key="action">Action</th>
                          <th key="actionBy">Action By</th>
                        </tr>
                      </thead>
                      <tbody className={``}>
                        {sequence?.history ? (
                          sequence?.history?.map((row, i) => {
                            return (
                              <tr key={i} className={"border"}>
                                <td key={`date${row?.id}`}>
                                  {utils.formatDate(
                                    row?.createdAt,
                                    DATE_FORMAT
                                  )}
                                </td>
                                <td
                                  key={`action${row?.id}`}
                                  className={`${
                                    row?.sequenceRevision
                                      ? "font-weight-bold"
                                      : ""
                                  }`}
                                >
                                  {row?.sequenceRevision
                                    ? `Revision: ${row.sequenceRevision.name} Created`
                                    : row?.details}
                                </td>
                                <td key={`actionBy${row?.id}`}>
                                  {`${row?.user?.firstName} ${row?.user?.lastName}`}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              className="text-muted small text-center border"
                              colSpan={3}
                            >
                              No data to show
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Row>
                </Collapse>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 text-center small">No sequences to show</div>
        )}
      </Row>
      {detailingInfoModalOpen ? (
        <DetailingInfoModal
          jobId={jobDetails.job.id}
          onClose={() => setDetailingInfoModalOpen(false)}
          onSubmit={() => {
            setDetailingInfoModalOpen(false);
            loadSequences(jobDetails.job.id);
          }}
        />
      ) : (
        <CreateNewRevisionModal
          showRevisionModal={showRevisionModal}
          onClose={onClose}
          revisionNeeded={true}
          sequenceId={sequenceSelected}
          sequenceStatusId={WAITING_FOR_ASSIGNMENT_STATUS}
          afterSubmit={afterSubmit}
        />
      )}
    </Col>
  );
};

export default DetailingInfoTab;
