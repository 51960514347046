import React from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import Loader from "../../Loader";
import { useJobKPIs } from "../../../providers/jobsKPIsProvider";
import { utils } from "../../../utils/utils";
import Header from "../../Header";
import { useNavigate } from "react-router-dom";

const JobsCards = () => {
  const navigate = useNavigate();
  const [jobKPIsContext] = useJobKPIs();

  return (
    <Header className="row">
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">New RFQ's</div>
          </CardHeader>
          <CardBody
            className="pt-0 pb-3 d-flex align-items-center justify-content-center position-absolute w-100"
            style={{ bottom: 0, height: "70%" }}
          >
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/rfq/in-progress")}
                className="cursor-pointer text-xl font-500"
              >
                {utils.formatDecimal(jobKPIsContext.recent)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">Pending</div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/rfq/pending")}
                className="cursor-pointer text-xl font-500 h-100 d-flex align-items-center justify-content-center"
              >
                {utils.formatDecimal(jobKPIsContext.inProgress)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">In PM</div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/in-project-management")}
                className="cursor-pointer text-xl font-500 h-100 d-flex align-items-center justify-content-center"
              >
                {utils.formatDecimal(jobKPIsContext.wonThisMonth)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">In Progress</div>
          </CardHeader>
          <CardBody
            className="pt-0 pb-3 d-flex align-items-center justify-content-center position-absolute w-100"
            style={{ bottom: 0, height: "70%" }}
          >
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/in-progress")}
                className="cursor-pointer text-xl font-500"
              >
                {utils.formatDecimal(jobKPIsContext.lostThisMonth)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">
              Ready For Invoice
            </div>
          </CardHeader>
          <CardBody
            className="pt-0 pb-3 d-flex align-items-center justify-content-center position-absolute w-100"
            style={{ bottom: 0, height: "70%" }}
          >
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/ready-for-invoice")}
                className="cursor-pointer text-xl font-500"
              >
                {utils.formatDecimal(jobKPIsContext.inReadyForInvoice)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">PM Approved</div>
          </CardHeader>
          <CardBody
            className="pt-0 pb-3 d-flex align-items-center justify-content-center position-absolute w-100"
            style={{ bottom: 0, height: "70%" }}
          >
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/pm-approved")}
                className="cursor-pointer text-xl font-500"
              >
                {utils.formatDecimal(jobKPIsContext.inPMApproved)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg mb-4">
        <Card className="h-100">
          <CardHeader>
            <div className="pb-1 border-bottom text-center">Exec Approved</div>
          </CardHeader>
          <CardBody
            className="pt-0 pb-3 d-flex align-items-center justify-content-center position-absolute w-100"
            style={{ bottom: 0, height: "70%" }}
          >
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/exec-approved")}
                className="cursor-pointer text-xl font-500"
              >
                {utils.formatDecimal(jobKPIsContext.inExecApproved)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </Header>
  );
};

export default JobsCards;
