import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";
import {
  useJobDetails,
  ACTIONS,
  TABS,
} from "../../providers/jobDetailsProvider";
import { jobsApi } from "../../services/jobServices";
import ConvertToJobModal from "../../components/admin/jobs/ConvertToJobModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import ShippedJobModal from "../../components/ShippedJobModal";
import moment from "moment";
import { utils } from "../../utils/utils";
import { useAuth } from "../../providers/authProvider";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../providers/evaOverrideProvider";
import {
  ACTIONS as KPI_ACTIONS,
  useJobKPIs,
} from "../../providers/jobsKPIsProvider";
import DatePicker from "../../components/DatePicker";

const STATUS_RFQ = 1;
const STATUS_PENDING = 3;
const STATUS_IN_PROGRESS = 4;
const STATUS_COMPLETED = 5;
const STATUS_SHIPPED_COMPLETE = 2;
const STATUS_NOT_AWARDED_BID = 6;
const STATUS_NO_QUOTE = 7;
const STATUS_INVOICED = 9;
const STATUS_IN_PROJECT_MANAGEMENT = 10;
const STATUS_ARCHIVED = 11;
const STATUS_READY_FOR_INVOICE = 12;

const EDITABLE_EVA_JOB_STATUSES = [STATUS_COMPLETED, STATUS_INVOICED];

const JobActions = () => {
  const [jobDetails, setJobDetails] = useJobDetails();
  const [authContext] = useAuth();
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [, setJobKPIsContext] = useJobKPIs();

  const INVOICED = jobDetails.job.jobStatusLog.find(
    (log) => log.statusId === STATUS_INVOICED
  );
  const SHIPPED = jobDetails.job.jobStatusLog.find(
    (log) => log.statusId === STATUS_SHIPPED_COMPLETE
  );

  const isAdmin = utils.isAdmin(authContext.currentUser.role.id);

  const jobStatusValidation = EDITABLE_EVA_JOB_STATUSES.includes(
    jobDetails.job.statusId
  );

  const tabValidation =
    jobDetails.activeGeneralTab === TABS.ESTIMATED_VS_ACTUAL;

  const isLockedJob = jobDetails.job.lockDate;

  const [convertToJobModal, setConvertToJobModal] = useState(false);
  const [askForDateModal, setAskForDateModal] = useState(false);
  const [updatedShipDate, setUpdatedShipDate] = useState(new Date());

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const initShippedModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [shippedDateModal, setShippedDateModal] = useState(initShippedModal);
  const markAsLost = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_NOT_AWARDED_BID,
        });
        setInformationModal({
          isOpen: true,
          title: "Mark RFQ as lost",
          body: "RFQ marked as lost successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Mark RFQ as lost",
      body: `
            <div class="text-center">
                Do you confirm you want to mark this RFQ as Lost?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const markAsNoQuote = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_NO_QUOTE,
        });
        setInformationModal({
          isOpen: true,
          title: "Mark RFQ as No Quote",
          body: "RFQ marked as No Quote successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Mark RFQ as No Quote",
      body: `
            <div class="text-center">
                Do you confirm you want to mark this RFQ as No Quote?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const setJobToCompleted = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_COMPLETED,
        });
        setInformationModal({
          isOpen: true,
          title: "Mark Job as completed",
          body: "Job marked as completed successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Mark Job as completed",
      body: `
            <div class="text-center">
                Do you confirm you want to mark this Job as Completed?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const setJobToShipComplete = () => {
    setShippedDateModal({
      isOpen: true,
      onSubmit: async (shippedDate) => {
        setShippedDateModal(initShippedModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_SHIPPED_COMPLETE,
          shippedCompleteDate: moment(shippedDate).format("YYYY-MM-DD"),
        });
        setInformationModal({
          isOpen: true,
          title: "Mark Job as Ship Complete",
          body: "Job marked as ship completed successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setShippedDateModal(initShippedModal),
      title: "Mark Job as Ship Complete",
      body: ``,
      confirmColor: "danger",
    });
  };

  const setJobToReadyForInvoice = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_READY_FOR_INVOICE,
        });
        setConfirmationModal(initConfirmationModal);

        await updateJobKPIsContext();
        setInformationModal({
          isOpen: true,
          title: "Set Job to Ready For Invoice",
          body: "Job set to ready for invoice successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Set Job to Ready For Invoice",
      body: `
            <div class="text-center">
                Do you confirm you want to set this Job to ready for invoice?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const setJobToInvoiced = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_INVOICED,
        });

        setConfirmationModal(initConfirmationModal);
        await updateJobKPIsContext();

        setInformationModal({
          isOpen: true,
          title: "Set Job to Invoiced",
          body: "Job set to Invoiced successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Set Job to Invoiced",
      body: `
            <div class="text-center">
                Do you confirm you want to set this Job to Invoiced?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const setJobToInProgress = (event) => {
    event.preventDefault();
    setAskForDateModal(false);
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          updatedShipDate,
          statusId: STATUS_IN_PROGRESS,
        });
        setInformationModal({
          isOpen: true,
          title: "Set Job to In Progress",
          body: "Job set to In Progress successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setAskForDateModal(true);
      },
      title: "Set Job to In Progress",
      body: `
            <div class="text-center">
                Do you confirm you want to set this Job to In Progress?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const setJobToEstimating = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          statusId: STATUS_RFQ,
        });
        setInformationModal({
          isOpen: true,
          title: "Set Job to Estimating",
          body: "Job set to Estimating successfully.",
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Set Job to Estimating",
      body: `
            <div class="text-center">
                Do you confirm you want to set this Job to Estimating?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const onToggleEditReport = () => {
    if (evaOverrideContext.editEnabled) {
      setEvaOverrideContext({
        action: EVA_OVERRIDE_ACTIONS.INIT,
        payload: {
          timeAttendanceLaborCodeHoursOverride:
            jobDetails.job.timeAttendanceLaborCodeHoursOverride,
          teklaMaterialDataOverride: jobDetails.job.teklaMaterialDataOverride,
        },
      });
    } else {
      setEvaOverrideContext({
        action: EVA_OVERRIDE_ACTIONS.START_EDIT,
      });
    }
  };

  const lockEVA = async () => {
    await updateJobLockDate(moment().format("YYYY-MM-DD"));
  };

  const unlockEVA = async () => {
    await updateJobLockDate(null);
  };

  const updateJobLockDate = async (lockDate) => {
    const status = lockDate ? "locked" : "unlocked";
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await jobsApi.updateJob({
          ...jobDetails.job,
          lockDate: lockDate || null,
        });
        setInformationModal({
          isOpen: true,
          title: `Mark Job as ${status}`,
          body: `Job marked as ${status} successfully.`,
          onClose: () => {
            setJobDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Mark Job as ${status}`,
      body: `
            <div class="text-center">
                Do you confirm you want to mark this Job as ${utils.capitalize(
                  status
                )}?
            </div>
      `,
      confirmColor: "danger",
    });
  };

  const updateJobKPIsContext = async () => {
    setJobKPIsContext({
      action: KPI_ACTIONS.GET_DATA_LOADING,
      payload: true,
    });
    const kpis = await jobsApi.getJobsKPIs({});
    setJobKPIsContext({
      action: KPI_ACTIONS.GET_DATA_SUCCESS,
      payload: { kpis },
    });
  };

  return (
    <div className="d-flex align-items-center">
      {jobDetails.job.statusId === STATUS_RFQ ? (
        <Button
          size="sm"
          className="rounded mr-2"
          color="primary"
          onClick={markAsNoQuote}
        >
          No Quote
        </Button>
      ) : null}
      {jobDetails.job.statusId === STATUS_RFQ ||
      jobDetails.job.statusId === STATUS_PENDING ? (
        <div>
          <Button
            size="sm"
            className="rounded"
            color="danger"
            onClick={markAsLost}
          >
            Mark as Lost
          </Button>
          <Button
            size="sm"
            className="rounded ml-2"
            color="success"
            onClick={() => setConvertToJobModal(true)}
          >
            Convert to Job
          </Button>
        </div>
      ) : null}
      {SHIPPED && INVOICED && jobDetails.job.statusId !== STATUS_COMPLETED ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="success"
          onClick={() => setJobToCompleted()}
        >
          Complete Job
        </Button>
      ) : null}
      {isAdmin && jobStatusValidation && tabValidation && !isLockedJob ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="danger"
          onClick={lockEVA}
        >
          Lock EVA
        </Button>
      ) : isAdmin && jobStatusValidation && tabValidation && isLockedJob ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="warning"
          onClick={unlockEVA}
        >
          Unlock EVA
        </Button>
      ) : null}
      {isAdmin && jobStatusValidation && tabValidation && !isLockedJob ? (
        <div className="d-flex justify-content-end">
          <Button
            color="warning"
            className="rounded ml-2"
            onClick={onToggleEditReport}
            size="sm"
          >
            {evaOverrideContext.editEnabled
              ? "Discard EVA Changes"
              : "Edit EVA"}
          </Button>
        </div>
      ) : null}
      {(jobDetails.job.statusId === STATUS_IN_PROGRESS ||
        jobDetails.job.statusId === STATUS_INVOICED) &&
      !SHIPPED ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="success"
          onClick={() => setJobToShipComplete()}
        >
          Set to Shipped
        </Button>
      ) : null}
      {jobDetails.job.statusId === STATUS_IN_PROJECT_MANAGEMENT ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="success"
          onClick={() => setAskForDateModal(true)}
        >
          Set In Progress
        </Button>
      ) : null}
      {jobDetails.job.statusId !== STATUS_INVOICED &&
      jobDetails.job.statusId !== STATUS_RFQ &&
      jobDetails.job.statusId !== STATUS_IN_PROGRESS &&
      jobDetails.job.statusId !== STATUS_IN_PROJECT_MANAGEMENT &&
      jobDetails.job.statusId !== STATUS_COMPLETED &&
      jobDetails.job.statusId !== STATUS_ARCHIVED &&
      jobDetails.job.statusId !== STATUS_READY_FOR_INVOICE &&
      !INVOICED ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="warning"
          onClick={() => setJobToReadyForInvoice()}
        >
          Set Ready For Invoice
        </Button>
      ) : null}
      {jobDetails.job.statusId !== STATUS_INVOICED &&
      jobDetails.job.statusId !== STATUS_RFQ &&
      jobDetails.job.statusId !== STATUS_IN_PROGRESS &&
      jobDetails.job.statusId !== STATUS_IN_PROJECT_MANAGEMENT &&
      jobDetails.job.statusId !== STATUS_COMPLETED &&
      jobDetails.job.statusId !== STATUS_ARCHIVED &&
      jobDetails.job.statusId !==  STATUS_SHIPPED_COMPLETE &&
      !INVOICED ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="warning"
          onClick={() => setJobToInvoiced()}
        >
          Set Invoiced
        </Button>
      ) : null}
      {jobDetails.job.statusId === STATUS_ARCHIVED ? (
        <Button
          size="sm"
          className="rounded ml-2"
          color="success"
          onClick={() => setJobToEstimating()}
        >
          Unarchive
        </Button>
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
      {convertToJobModal ? (
        <ConvertToJobModal
          onSubmit={() => {
            setConvertToJobModal(false);
            setInformationModal({
              isOpen: true,
              title: "Convert RFQ to Job",
              body: "RFQ converted to Job successfully.",
              onClose: () => {
                setJobDetails({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          }}
          onClose={() => setConvertToJobModal(false)}
        />
      ) : null}
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
      {shippedDateModal.isOpen ? (
        <ShippedJobModal {...shippedDateModal} />
      ) : null}
      {askForDateModal ? (
        <Modal isOpen={true}>
          <Form onSubmit={setJobToInProgress}>
            <ModalHeader
              className="d-flex justify-content-between"
              close={() => setAskForDateModal(false)}
            >
              Set Job to In Progress
            </ModalHeader>
            <ModalBody>
              <Row className="mt-3">
                <Col className="col-12">
                  <FormGroup>
                    <Label for="revisionDate">
                      <span>Set Updated Ship Date</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <DatePicker
                      date={updatedShipDate}
                      required={true}
                      onClick={(date) => setUpdatedShipDate(date)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Col>
                <Row className="justify-content-between">
                  <Button
                    color={"secondary"}
                    onClick={() => setAskForDateModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button color={"danger"} type="submit">
                    Confirm
                  </Button>
                </Row>
              </Col>
            </ModalFooter>
          </Form>
        </Modal>
      ) : null}
    </div>
  );
};

export default JobActions;
