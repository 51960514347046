import React, { useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "reactstrap";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OrderAcknowledgmentAttachment = ({ attachment, onDelete }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDownload = async () => {
    try {
      window.open(attachment.url, "new");
    } catch (err) {}
  };

  return (
    <Col className="col-12">
      <div className="d-flex justify-content-end">
        {onDelete ? (
          <Button
            size="sm"
            color="danger"
            className="rounded mx-2"
            onClick={() => {
              onDelete(attachment);
            }}
          >
            <span className="font-weight-bold">Delete</span>
            <FontAwesomeIcon icon={faTrashAlt} className="ml-2" />
          </Button>
        ) : null}
        <Button
          color="tertiary"
          size="sm"
          className="rounded"
          onClick={() => onDownload()}
        >
          <span className="font-weight-bold">PDF</span>
          <FontAwesomeIcon icon={faDownload} className="ml-1" />
        </Button>
      </div>
      <Row>
        <Col className="flex-shrink-0 py-3">
          {!attachment ? (
            <div className="text-center text-muted mt-4">
              No data has been recorded
            </div>
          ) : (
            <>
              <Document
                className="d-flex justify-content-center"
                file={{
                  url: attachment.url,
                }}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Page className="border rounded" pageNumber={pageNumber} />
              </Document>
              <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                <Button
                  className="rounded"
                  onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  className="rounded"
                  onClick={() =>
                    setPageNumber(Math.min(pageNumber + 1, numPages))
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default OrderAcknowledgmentAttachment;
