import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { useQuoteItem, ACTIONS } from "../../../../providers/quoteItemProvider";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";
import QuoteItemEditOptionsModal from "./QuoteItemEditOptionsModal";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";

const QuoteItemShippingHandlings = ({
  quoteItemShippingHandlings,
  setQuoteItemShippingHandlings,
}) => {
  const [quoteItemContext, setQuoteItemContext] = useQuoteItem();
  const [jobDetails] = useJobDetails();

  const isJobLocked = jobDetails.job.lockDate;

  const [editOptionsModal, setEditOptionsModal] = useState();
  const [totalCost, setTotalCost] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  useEffect(() => {
    const quoteItemShippingHandlings =
      quoteItemContext.quoteItem.quoteItemShippingHandlings.reduce((p, c) => {
        p[c.id] = c;
        return p;
      }, {});
    setQuoteItemShippingHandlings(quoteItemShippingHandlings);
  }, [quoteItemContext.quoteItem, setQuoteItemShippingHandlings]);

  useEffect(() => {
    const totalCost = Object.values(quoteItemShippingHandlings).reduce(
      (p, c) => p + parseFloat(c.cost || 0),
      0
    );
    setTotalCost(totalCost);
  }, [quoteItemShippingHandlings]);

  const onFieldChange = (shippingHandlingOptionId, field, value) =>
    setQuoteItemShippingHandlings({
      ...quoteItemShippingHandlings,
      [shippingHandlingOptionId]: {
        ...quoteItemShippingHandlings[shippingHandlingOptionId],
        [field]: value || 0,
      },
    });

  return (
    <ListGroup>
      <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter">
        <small className="font-weight-bold text-muted">
          Shipping and Handling
        </small>
        {isJobLocked ? null : (
          <div className="d-flex">
            <Button
              className="rounded"
              size="sm"
              onClick={() => setEditOptionsModal(true)}
            >
              Edit Options
            </Button>
          </div>
        )}
      </ListGroupItem>
      {quoteItemContext.quoteItem.quoteItemShippingHandlings
        .sort((x, y) =>
          x.shippingHandlingOption.name < y.shippingHandlingOption.name ? -1 : 1
        )
        .map((shippingHandling) => {
          const cost =
            quoteItemShippingHandlings[shippingHandling.id]?.cost || "";
          return (
            <ListGroupItem
              key={shippingHandling.id}
              className="border-radius-0 d-flex justify-content-between align-items-center py-0"
            >
              <span>{shippingHandling.shippingHandlingOption.name}</span>
              <div className="width-150">
                <NumericFormat
                  required={true}
                  readOnly={isJobLocked}
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  className="form-control border text-right m-1"
                  value={cost}
                  placeholder={"Enter the cost.."}
                  thousandSeparator={true}
                  prefix={"$"}
                  onFocus={() =>
                    isJobLocked || onFieldChange(shippingHandling.id, "cost", 0)
                  }
                  onValueChange={(e) =>
                    onFieldChange(shippingHandling.id, "cost", e.value)
                  }
                />
              </div>
            </ListGroupItem>
          );
        })}
      <ListGroupItem className="d-flex flex-column py-0">
        <div className="border-radius-0 d-flex justify-content-between align-items-center">
          <span className="font-weight-bold">Total Cost</span>
          <div className="width-150">
            <div readOnly className="form-control border text-right m-1">
              {utils.formatCurrency(totalCost)}
            </div>
          </div>
        </div>
      </ListGroupItem>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : editOptionsModal ? (
        <QuoteItemEditOptionsModal
          title="Shipping & Handling"
          field="quoteItemShippingHandlings"
          idField="shippingHandlingOptionId"
          quoteItem={quoteItemContext.quoteItem}
          onSubmit={() => {
            setEditOptionsModal(false);
            setQuoteItemContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setEditOptionsModal(false)}
        />
      ) : null}
    </ListGroup>
  );
};

export default QuoteItemShippingHandlings;
