import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ManHourSummary from "./productionHandoff/ManHourSummary";
import CSRSummary from "./productionHandoff/CSRSummary";
import WorkOrderSummary from "./productionHandoff/workOrder/WorkOrderSummary";
import { useJobDetails } from "../../../providers/jobDetailsProvider";
import { utils } from "../../../utils/utils";

const MAN_HOUR_SUMMARY_TAB = "MAN_HOUR_SUMMARY";
const CSR_TAB = "CSR";
const WORK_ORDER_TAB = "WORK_ORDER_TAB";

const ProductionHandoffTab = () => {
  const [activeTab, setActiveTab] = useState(MAN_HOUR_SUMMARY_TAB);

  const [jobDetails] = useJobDetails();

  const toggle = (selected) => {
    const id = selected?.id || selected;
    if (activeTab !== id) {
      setActiveTab(id);
    }
  };

  return (
    <Col className="mt-3 col-12">
      <div className="d-flex align-items-start justify-content-between">
        <h3>Production Handoff</h3>
      </div>
      <Row>
        <Col>
          <Nav
            tabs
            className="d-flex align-items-center job-details-nav cursor-pointer mt-3"
          >
            <div className="d-flex flex-grow-1">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === MAN_HOUR_SUMMARY_TAB,
                  })}
                  onClick={() => toggle(MAN_HOUR_SUMMARY_TAB)}
                >
                  Man Hour Summary
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === CSR_TAB })}
                  onClick={() => toggle(CSR_TAB)}
                >
                  CS&R
                </NavLink>
              </NavItem>
              {utils.isTurnedIntoJob(jobDetails.job) ? (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === WORK_ORDER_TAB,
                    })}
                    onClick={() => toggle(WORK_ORDER_TAB)}
                  >
                    Work Order
                  </NavLink>
                </NavItem>
              ) : null}
            </div>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={MAN_HOUR_SUMMARY_TAB}>
              <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  {activeTab === MAN_HOUR_SUMMARY_TAB ? (
                    <ManHourSummary />
                  ) : null}
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={CSR_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  {activeTab === CSR_TAB ? <CSRSummary /> : null}
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={WORK_ORDER_TAB}>
              <Card className="box-shadow-none border-left border-bottom border-right">
                <CardBody>
                  {activeTab === WORK_ORDER_TAB ? <WorkOrderSummary /> : null}
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Col>
  );
};

export default ProductionHandoffTab;
