import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Row } from "reactstrap";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";
import ConfirmationModal from "../../../ConfirmationModal";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import OrderAcknowledgmentList from "./OrderAcknowledgmentList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderAcknowledgmentCreation from "./OrderAcknowledgmentCreation";
import OrderAcknowledgmentEdition from "./OrderAcknowledgmentEdition";
import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";

const OrderAcknowledgmentTab = () => {
  const archiveOrderAcknowledgment = useCallback(
    (orderAcknowledgment, isDelete = false) => {
      const ordersAcknowledgmentNumber = `${orderAcknowledgment.jobId}.${orderAcknowledgment.number}`;
      const message = isDelete
        ? "Delete"
        : !orderAcknowledgment.archived
        ? "Unarchive"
        : "Archive";
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            if (isDelete) {
              await orderAcknowledgmentApi.delete(orderAcknowledgment);
            } else {
              const orderAcknowledgmentData = {
                ...orderAcknowledgment,
              };
              delete orderAcknowledgmentData.quoteItems;
              delete orderAcknowledgmentData.attachments;
              await orderAcknowledgmentApi.update(orderAcknowledgmentData);
            }
            setConfirmationModal(utils.initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: `${message} Order Acknowledgment`,
              body: `Order Acknowledgment ${message.toLocaleLowerCase()}d successfully`,
              onClose: () => {
                setInformationModal(utils.initInformationModal);
                setComponentToDisplay(
                  <OrderAcknowledgmentList
                    setOrdersAcknowledgmentCount={setOrdersAcknowledgmentCount}
                    setOrderAcknowledgment={setOrderAcknowledgmentSelected}
                    archiveOrderAcknowledgment={archiveOrderAcknowledgment}
                    refresh={ordersAcknowledgmentNumber}
                  />
                );
              },
            });
          } catch (err) {
            setConfirmationModal(utils.initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
              onClose: () => setInformationModal(utils.initInformationModal),
            });
          }
        },
        onClose: () => {
          setConfirmationModal(utils.initConfirmationModal);
        },
        title: `${message} Order Acknowledgment`,
        body: `
        <div class="text-center">
          Are you sure you want to ${message.toLocaleLowerCase()} Order Acknowledgment: ${ordersAcknowledgmentNumber}?
        </div>
      `,
        confirmColor: "warning",
      });
    },
    []
  );

  const [orderAcknowledgmentSelected, setOrderAcknowledgmentSelected] =
    useState();
  const [ordersAcknowledgmentCount, setOrdersAcknowledgmentCount] = useState(0);
  const [showDiscard, setShowDiscard] = useState();
  const [componentToDisplay, setComponentToDisplay] = useState(
    <OrderAcknowledgmentList
      setOrdersAcknowledgmentCount={setOrdersAcknowledgmentCount}
      setOrderAcknowledgment={setOrderAcknowledgmentSelected}
      archiveOrderAcknowledgment={archiveOrderAcknowledgment}
    />
  );
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const setDefaults = useCallback(
    (refresh) => {
      setShowDiscard();
      setComponentToDisplay(
        <OrderAcknowledgmentList
          setOrdersAcknowledgmentCount={setOrdersAcknowledgmentCount}
          setOrderAcknowledgment={setOrderAcknowledgmentSelected}
          archiveOrderAcknowledgment={archiveOrderAcknowledgment}
          refresh={refresh}
        />
      );
    },
    [archiveOrderAcknowledgment]
  );

  useEffect(() => {
    if (orderAcknowledgmentSelected) {
      setShowDiscard(true);
      setComponentToDisplay(
        <OrderAcknowledgmentEdition
          orderAcknowledgment={orderAcknowledgmentSelected}
          archiveOrderAcknowledgment={archiveOrderAcknowledgment}
          onClose={setDefaults}
        />
      );
    } else {
      setComponentToDisplay(
        <OrderAcknowledgmentList
          setOrdersAcknowledgmentCount={setOrdersAcknowledgmentCount}
          setOrderAcknowledgment={setOrderAcknowledgmentSelected}
          archiveOrderAcknowledgment={archiveOrderAcknowledgment}
        />
      );
    }
  }, [orderAcknowledgmentSelected, archiveOrderAcknowledgment, setDefaults]);

  return (
    <Col className="mt-3 col-12">
      <Row className="d-flex justify-content-between px-3 align-items-center">
        <h3 className="font-weight-bold">Order Acknowledgment</h3>
        <div className="d-flex justify-content-end">
          <Button
            className="rounded"
            size="sm"
            onClick={() => {
              setShowDiscard(true);
              setComponentToDisplay(
                <OrderAcknowledgmentCreation
                  ordersAcknowledgmentCount={ordersAcknowledgmentCount}
                  orderAcknowledgment={orderAcknowledgmentSelected}
                  onClose={setDefaults}
                />
              );
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ml-2">Add Item</span>
          </Button>
          {showDiscard ? (
            <Button
              className="rounded ml-2"
              color="warning"
              size="sm"
              onClick={setDefaults}
            >
              <span>Discard</span>
            </Button>
          ) : null}
        </div>
      </Row>
      <Row className="d-flex justify-content-between px-3 mt-4 align-items-center">
        <Col className="col-12">{componentToDisplay}</Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal(utils.initInformationModal)
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default OrderAcknowledgmentTab;
