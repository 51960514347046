import Loader from "../../../Loader";
import React, { useEffect, useState, useCallback } from "react";
import { utils } from "../../../../utils/utils";
import { Row, Table, Button, Badge } from "reactstrap";
import InformationModal from "../../../InformationModal";
import { faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";

const PAGE_NUMBER = 0;
const PAGE_SIZE = 99999;

const OrderAcknowledgmentList = ({
  refresh,
  setOrdersAcknowledgmentCount,
  setOrderAcknowledgment,
  archiveOrderAcknowledgment,
}) => {
  const [ordersAcknowledgment, setOrdersAcknowledgment] = useState([]);
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );

  const loadOrdersAcknowledgment = useCallback(
    async (jobId) => {
      try {
        setLoading(true);
        const ordersAcknowledgment = await orderAcknowledgmentApi.getAll({
          jobId,
          page: PAGE_NUMBER,
          pageSize: PAGE_SIZE,
        });
        setOrdersAcknowledgment(ordersAcknowledgment.data);
        const maxId = ordersAcknowledgment.data.length
          ? Math.max(...ordersAcknowledgment.data.map((oa) => oa.number))
          : 0;
        setOrdersAcknowledgmentCount(maxId);
        setLoading(false);
        return ordersAcknowledgment.data;
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    },
    [setOrdersAcknowledgmentCount]
  );

  // Load Orders Acknowledgment
  useEffect(() => {
    loadOrdersAcknowledgment(jobDetails.job.id);
  }, [jobDetails.job.id, loadOrdersAcknowledgment, refresh]);

  return (
    <div>
      <Row className="d-flex justify-content-between px-3 align-items-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Table className="col-12 px-0 border rounded mb-0">
            <thead className="small">
              <tr className="text-muted bg-lighter">
                <th key="ack">Order Ack #</th>
                <th key="amount">Amount</th>
                <th key="description">Description</th>
                <th key="actions" className="text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className={``}>
              {ordersAcknowledgment.length ? (
                ordersAcknowledgment.map((row, i) => {
                  return (
                    <tr key={i} className="border">
                      <td key={`ack${row?.id}`}>
                        {`${jobDetails.job.id}.${row.number}`}
                        {row.archived ? (
                          <Badge className="ml-2" color="danger">
                            Archived
                          </Badge>
                        ) : null}
                      </td>
                      <td key={`amount${row?.id}`}>{utils.formatCurrency(row.amount)}</td>
                      <td key={`description${row?.id}`}>{row.pO}</td>
                      <td
                        key={`actions${row?.id}`}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <Button
                          color={"primary"}
                          size="sm"
                          className="rounded"
                          onClick={() => {
                            setOrderAcknowledgment(row);
                          }}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                        <Button
                          color={"danger"}
                          size="sm"
                          className="rounded ml-2"
                          onClick={() => {
                            archiveOrderAcknowledgment(row, true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="small text-center border" colSpan={5}>
                    No items to show
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal(utils.initInformationModal)
          }
        />
      ) : null}
    </div>
  );
};

export default OrderAcknowledgmentList;
