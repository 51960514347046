import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../utils/apiHelper";

export const evaOverrideApi = {
  updateTAOverride: (data) => {
    return axios(`/api/time-attendance-override/`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateTeklaDataOverride: (data) => {
    return axios(`/api/tekla-override/`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
