import React, { useState } from "react";
import { utils } from "../../../../utils/utils";
import { NumericFormat } from "react-number-format";
import InformationModal from "../../../InformationModal";
import { awsApi } from "../../../../services/awsServices";
import { faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../../ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";
import QuoteItemsList from "./QuoteItemsList";

const OrderAcknowledgmentCreation = ({
  ordersAcknowledgmentCount,
  onClose,
}) => {
  const [jobDetails] = useJobDetails();

  const [amount, setAmount] = useState("");
  const [pO, setPO] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const [notes, setNotes] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [quoteItems, setQuoteItems] = useState([]);
  const number = ordersAcknowledgmentCount + 1;
  const ordersAcknowledgmentNumber = `${jobDetails.job.id}.${number}`;

  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const onConfirm = async () => {
    if (attachments && attachments.length) {
      const attachmentsUploaded = await uploadAttachments();
      if (attachmentsUploaded) {
        await createOrderAcknowledgment(attachmentsUploaded);
      }
    } else {
      await createOrderAcknowledgment(null);
    }
  };

  const createOrderAcknowledgment = async (attachments) => {
    await orderAcknowledgmentApi.create({
      jobId: jobDetails.job.id,
      number,
      amount,
      pO,
      salesTax,
      notes,
      quoteItems,
      attachments,
    });
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal({
      isOpen: true,
      title: "Order Acknowledgment Creation",
      body: "Sequence created successfully",
      onClose: () => {
        onClear();
      },
    });
  };

  const uploadAttachments = async () => {
    try {
      const filesMapping = {};
      const attachmentsArray = attachments.map((file) => {
        filesMapping[file.name] = file;
        return {
          fileName: file.name,
        };
      });
      const attachmentUrls = await orderAcknowledgmentApi.uploadAttachments({
        attachments: attachmentsArray,
      });
      for (let index = 0; index < attachmentUrls.length; index++) {
        const dataToUpload = {
          fileUrl: attachmentUrls[index].url,
          url: attachmentUrls[index].signedRequest,
          file: (attachmentUrls[index].file =
            filesMapping[attachmentUrls[index].fileName]),
        };
        attachmentUrls[index].file =
          filesMapping[attachmentUrls[index].fileName];
        await awsApi.putDocumentsToS3(dataToUpload);
      }
      return attachmentUrls;
    } catch (error) {
      setConfirmationModal(utils.initConfirmationModal);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error to upload attachements.",
        onClose: () => {
          setInformationModal(utils.initInformationModal);
        },
      });
    }
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!quoteItems.length) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Select at least one quote item.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await onConfirm();
        } catch (err) {
          setConfirmationModal(utils.initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
            onClose: () => setInformationModal(utils.initInformationModal),
          });
        }
      },
      onClose: () => {
        setConfirmationModal(utils.initConfirmationModal);
      },
      title: "Order Acknowledgment Creation",
      body: `
        <div class="text-center">
          Are you sure you you want to create Order Acknowledgment: ${ordersAcknowledgmentNumber}?
        </div>
      `,
      confirmColor: "warning",
    });
  };

  const onClear = () => {
    setAmount("");
    setPO("");
    setInformationModal(utils.initInformationModal);
    setConfirmationModal(utils.initConfirmationModal);
    onClose();
  };

  return (
    <div>
      <Row>
        <h4 className="w-100 text-center">New Order Acknowledgment</h4>
        <h4 className="w-100 mb-2">Order#: {ordersAcknowledgmentNumber}</h4>
        <QuoteItemsList
          quoteItemsSelected={quoteItems}
          setQuoteItemsSelected={setQuoteItems}
          setContractAmount={setAmount}
        />
        <Form onSubmit={doSubmit} className="w-100">
          <Row className="mt-2">
            <Col className="col-4 p-0">
              <FormGroup className="col-12">
                <Label for="pO">
                  <span>Customer PO#</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <Input
                  required={true}
                  maxLength="50"
                  className="height-40p border"
                  placeholder="Enter PO#..."
                  type="text"
                  name="pO"
                  value={pO || ""}
                  onChange={(e) => setPO(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="col-4 p-0">
              <FormGroup className="col-12">
                <Label for="amount">
                  <span>Contract Amount</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <NumericFormat
                  required={true}
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  name="amount"
                  className="height-40p border rounded form-control"
                  value={amount || ""}
                  placeholder={"Enter the amount..."}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={(e) => setAmount(e.value)}
                />
              </FormGroup>
            </Col>
            <Col className="col-4 p-0">
              <FormGroup className="col-12">
                <Label for="salesTax">
                  <span>Sales Tax</span>
                </Label>
                <NumericFormat
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  name="salesTax"
                  className="height-40p border rounded form-control"
                  value={salesTax || 0}
                  thousandSeparator={true}
                  suffix={"%"}
                  onValueChange={(e) => setSalesTax(e.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <FormGroup className="d-flex flex-column mt-2">
                <Label for="notes">
                  <span>Notes/Clarifications/Exclusions</span>
                </Label>
                <Input
                  className=" border"
                  placeholder="Enter Notes/Clarifications/Exclusions..."
                  type="textarea"
                  rows={7}
                  maxLength="4096"
                  name="notes"
                  value={notes || ""}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="w-100 m-0 mb-3">
            <h5 className="m-0 w-100 font-weight-normal">Attachments</h5>
            <div className="bg-secondary text-white mt-2 rounded">
              <Label
                for="attachments"
                className="m-0 px-2 py1 font-size-75 cursor-pointer"
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="ml-2">Upload Docs</span>
              </Label>
              <Input
                className="d-none"
                type="file"
                id="attachments"
                name="attachments"
                multiple
                accept=".pdf"
                onChange={(e) => {
                  const currentAttachments = attachments;
                  const newAttachments = Array.from(e.target.files);
                  setAttachments(currentAttachments.concat(newAttachments));
                }}
              />
            </div>
          </Row>
          <Row>
            <ListGroup className="w-100">
              {attachments.map((file, index) => (
                <ListGroupItem
                  className="border-0 pt-0 pb-2"
                  key={`attachment-${index}`}
                >
                  <span className="mr-2">{file.name}</span>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faWindowClose}
                    className="align-self-center text-danger rounded cursor-pointer"
                    onClick={() => {
                      const newAttachments = attachments.filter(
                        (attachment) => attachment.name !== file.name
                      );
                      setAttachments(newAttachments);
                    }}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
          </Row>
          <div>
            <Button className="w-100" color={"secondary"} type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal(utils.initInformationModal)
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default OrderAcknowledgmentCreation;
