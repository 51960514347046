import React, { useState } from "react";
import { Container, Button } from "reactstrap";
import { utils } from "../../../utils/utils";
import SequencesTable from "./SequencesTable";
import InformationModal from "../../InformationModal";
import ConfirmationModal from "../../ConfirmationModal";
import SequenceDetailsModal from "./SequenceDetailsModal";
import { sequenceApi } from "../../../services/sequenceServices";

const WAITING_FOR_CHECKING_STATUS = 4;
const CHECKING_IN_PROGRESS_STATUS = 5;
const WAITING_FOR_CHECKING_TAB = "WAITING_FOR_CHECKING_TAB";

const WaitingForChecking = ({
  sequencesTotal,
  setSequencesTotal,
  reloadTabs,
  setReloadTabs,
  tabName,
}) => {
  const [checkerId, setCheckerId] = useState();
  const [detailerId, setDetailerId] = useState();
  const [detailModal, setDetailModal] = useState(false);
  const [sequenceSelected, setSequenceSelected] = useState();
  const [sequenceStatusId, setSequenceStatusId] = useState();
  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );

  const columns = [
    {
      accessor: "sendToCheckingButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 130,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"success"}
            onClick={() => {
              changeSequenceStatus(
                rowData.row.original,
                CHECKING_IN_PROGRESS_STATUS
              );
            }}
          >
            Start Checking
          </Button>
        );
      },
    },
    {
      id: "seeJobDetailsButton",
      accessor: "seeJobDetailsButton",
      Header: "",
      headerProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 125,
      Cell: (rowData) => {
        return (
          <Button
            className="btn-sm rounded px-1 py-0"
            color={"primary"}
            onClick={() => {
              const sequence = rowData.row.original;
              const detailer = sequence.detailer;
              const checker = sequence.checker;
              setSequenceSelected(sequence);
              setDetailerId(
                detailer ? `${detailer.firstName} ${detailer.lastName}` : "-"
              );
              setCheckerId(
                checker ? `${checker.firstName} ${checker.lastName}` : "-"
              );
              setSequenceStatusId(sequence.sequenceStatusId);
              setDetailModal(true);
            }}
          >
            See Job Details
          </Button>
        );
      },
    },
  ];

  const changeSequenceStatus = async (sequence, sequenceStatusId) => {
    try {
      const startChecking = sequenceStatusId === CHECKING_IN_PROGRESS_STATUS;
      onClear();
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          try {
            setReloadTabs([]);
            await sequenceApi.updateSequence(sequence.id, {
              ...sequence,
              sequenceStatusId,
            });
            onClear();
            setInformationModal({
              isOpen: true,
              title: startChecking ? "Start Checking" : "Sequence Update",
              body: startChecking
                ? "Checking started successfully"
                : "Sequence updated successfully.",
            });
            setReloadTabs([
              WAITING_FOR_CHECKING_TAB,
              utils.detailingTabs[sequenceStatusId],
            ]);
          } catch (error) {
            onClear();
            setInformationModal({
              isOpen: true,
              title: "Error",
              body: "There was an error with your request.",
            });
          }
        },
        onClose: () => onClear(),
        title: startChecking ? "Start Checking" : "Sequence Update",
        body: startChecking
          ? `
          <div class="text-center">
            Are you sure you are ready to begin checking this job?
          </div>
        `
          : `
        <div class="text-center">
          Do you confirm you want to update the sequence ${sequence.job.jobNumber}?
        </div>
      `,
        confirmColor: "warning",
      });
    } catch (error) {
      onClear();
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onClear = async () => {
    setDetailerId();
    setCheckerId();
    setSequenceSelected();
    setDetailModal(false);
    setSequenceStatusId();
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal(utils.initInformationModal);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    changeSequenceStatus(sequenceSelected, sequenceStatusId);
  };

  return (
    <Container fluid className="px-0">
      <SequencesTable
        columns={columns}
        sequenceStatusId={WAITING_FOR_CHECKING_STATUS}
        setSequencesTotal={setSequencesTotal}
        sequencesTotal={sequencesTotal}
        reloadTabs={reloadTabs}
        tabName={tabName}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => setInformationModal(utils.initInformationModal)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : detailModal ? (
        <SequenceDetailsModal
          sequence={sequenceSelected}
          detailModal={detailModal}
          onSubmit={onSubmit}
          onClear={onClear}
          readOnly={true}
          checkerId={checkerId}
          detailerId={detailerId}
          enableUpdate={true}
          statusId={sequenceStatusId}
          setStatusId={setSequenceStatusId}
        />
      ) : null}
    </Container>
  );
};

export default WaitingForChecking;
