import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const CurrencyInput = ({ placeholder, value, onChange, required }) => {
  const currencyMask = createNumberMask({
    prefix: "$",
    allowDecimal: true,
    decimalSymbol: ".",
    thousandsSeparatorSymbol: ",",
    allowNegative: false,
    allowLeadingZeroes: true,
    decimalLimit: 2,
  });

  const handleChange = (e) => {
    const replacedValue = e.target.value.replace(/[^0-9.]/g, "");
    const numericValue = replacedValue ? parseFloat(replacedValue) : "";
    onChange({ numericValue, rawValue: e.target.value });
  };

  return (
    <MaskedInput
      mask={currencyMask}
      className="form-control"
      placeholder={placeholder}
      guide={false}
      value={value}
      onChange={handleChange}
      required={required}
    />
  );
};

export default CurrencyInput;
