import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  CustomInput,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import CustomerContactCreationModal from "./CustomerContactsModal";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../ConfirmationModal";
import { customersApi } from "../../../services/customerServices";
import states from "../../../utils/states";

const CustomerModal = ({ customerId, onClose, onSubmit }) => {
  const [customer, setCustomer] = useState({});
  const [customerContacts, setCustomerContacts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [creationMode, setCreationMode] = useState();

  // Load Customer
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await customersApi.getCustomers({ id: customerId });
        setLoading(false);
        setCustomer(result);
        setCustomerContacts([...result.contacts]);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    if (customerId) {
      fetchData();
    }
  }, [customerId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (customer.id) {
      try {
        const response = await customersApi.updateCustomer({
          ...customer,
          contacts: customerContacts,
        });
        if (response.boundToJobsContacts) {
          const associatedContacts = response.boundToJobsContacts;
          const errorMessage =
            associatedContacts.length === 1
              ? `${associatedContacts[0].firstName} ${associatedContacts[0].lastName} cannot be deleted because is associated to a job.`
              : `The following contacts cannot be deleted because are associated to jobs: 
                                        ${associatedContacts
                                          .map((contact, index) => {
                                            if (index === 0) {
                                              return `${contact.firstName} ${contact.lastName}`;
                                            } else if (
                                              index ===
                                              associatedContacts.length - 1
                                            ) {
                                              return ` and ${contact.firstName} ${contact.lastName}`;
                                            } else {
                                              return `, ${contact.firstName} ${contact.lastName}`;
                                            }
                                          })
                                          .join("")}`;
          setLoading(false);
          const contacts = customerContacts.map((contact) => ({
            ...contact,
            deleted: false,
          }));
          setCustomerContacts(contacts);
          return setInformationModal({
            isOpen: true,
            title: "Update Customer",
            body: errorMessage,
          });
        } else {
          setLoading(false);
          onSubmit();
        }
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update Customer",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    } else {
      const contacts = customerContacts.filter(
        (customerContact) => !customerContact.deleted
      );
      try {
        await customersApi.createCustomer({
          ...customer,
          contacts,
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Create Customer",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  const onDeleteContact = (customerContact, index) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        const contacts = customerContacts.map((contact) => {
          if (contact.id === customerContact.id) {
            return {
              ...contact,
              deleted: true,
            };
          } else {
            return contact;
          }
        });
        setCustomerContacts(contacts);
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Customer Contact",
      body: `
                <div class="text-center">
                    Do you confirm you want to remove this contact?
                </div>
          `,
      confirmColor: "warning",
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : creationMode ? (
    <CustomerContactCreationModal
      onClose={() => setCreationMode(false)}
      onSubmit={(customerContact) => {
        setCreationMode(false);
        setCustomerContacts([...customerContacts, customerContact]);
      }}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader close={closeBtn}>
          {customer.id ? "Edit" : "Create"} Customer
        </ModalHeader>
        <ModalBody>
          <Row>
            <FormGroup className="col-12">
              <Label for="name">
                <span>Customer Name</span>
                <span className="text-danger ml-2">*</span>
              </Label>
              <Input
                maxLength="50"
                type="text"
                placeholder="Customer Name..."
                name="name"
                value={customer.name || ""}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    name: e.target.value,
                  })
                }
                required
              />
            </FormGroup>
          </Row>
          <Row>
            <Col className="col-6">
              <FormGroup>
                <Label for="billingAddress">
                  <span>Billing Address</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <Input
                  maxLength="50"
                  type="text"
                  name="billingAddress"
                  placeholder="Billing Address..."
                  value={customer.billingAddress || ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      billingAddress: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <Row>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="billingCity">
                      <span>Billing City</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <Input
                      maxLength="50"
                      type="text"
                      name="billingCity"
                      placeholder="Billing City..."
                      value={customer.billingCity || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          billingCity: e.target.value,
                        })
                      }
                      required
                    />
                  </FormGroup>
                </Col>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="billingState">
                      <span>Billing State</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <CustomInput
                      id="bStateSelect"
                      type="select"
                      name="bStateSelect"
                      required
                      value={customer.billingState || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          billingState: e.target.value,
                        })
                      }
                    >
                      <option value={""}>Select a state..</option>
                      {states.map((state) => (
                        <option key={state.abbreviation} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="billingZipCode">
                      <span>Billing Zip Code</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <Input
                      maxLength="50"
                      type="text"
                      name="billingZipCode"
                      placeholder="Billing Zip Code..."
                      value={customer.billingZipCode || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          billingZipCode: e.target.value,
                        })
                      }
                      required
                    />
                  </FormGroup>
                </Col>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="billingPhoneNumber">
                      <span>Billing Phone Number</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <Input
                      maxLength="50"
                      type="text"
                      name="billingPhoneNumber"
                      placeholder="Billing Phone Number..."
                      value={customer.billingPhoneNumber || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          billingPhoneNumber: e.target.value,
                        })
                      }
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="col-6">
              <FormGroup>
                <Label for="shippingAddress">Shipping Address</Label>
                <Input
                  maxLength="50"
                  type="text"
                  name="shippingAddress"
                  value={customer.shippingAddress || ""}
                  placeholder="Shipping Address..."
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      shippingAddress: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <Row>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="shippingCity">Shipping City</Label>
                    <Input
                      maxLength="50"
                      type="text"
                      name="shippingCity"
                      placeholder="Shipping City..."
                      value={customer.shippingCity || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          shippingCity: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="shippingState">Shipping State</Label>
                    <CustomInput
                      id="sStateSelect"
                      type="select"
                      name="sStateSelect"
                      value={customer.shippingState || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          shippingState: e.target.value,
                        })
                      }
                    >
                      <option value={""}>Select a state..</option>
                      {states.map((state) => (
                        <option key={state.abbreviation} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="shippingZipCode">Shipping Zip Code</Label>
                    <Input
                      maxLength="50"
                      type="text"
                      name="shippingZipCode"
                      placeholder="Shipping Zip Code..."
                      value={customer.shippingZipCode || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          shippingZipCode: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="col-6">
                  <FormGroup>
                    <Label for="shippingPhoneNumber">
                      Shipping Phone Number
                    </Label>
                    <Input
                      maxLength="50"
                      type="text"
                      name="shippingPhoneNumber"
                      placeholder="Shipping Phone Number..."
                      value={customer.shippingPhoneNumber || ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          shippingPhoneNumber: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table className="col-12 px-0 border rounded mb-0 text-center">
            <thead className="small">
              <tr className="bg-lighter">
                <th colSpan={6}>Customer Contacts</th>
              </tr>
              <tr className="text-muted">
                <th>First Name</th>
                <th>Last Name</th>
                <th>Title</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customerContacts
                .filter((customerContact) => !customerContact.deleted)
                .map((customerContact, i) => (
                  <tr key={i}>
                    <td>{customerContact.firstName}</td>
                    <td>{customerContact.lastName}</td>
                    <td>{customerContact.title}</td>
                    <td>{customerContact.email}</td>
                    <td>{customerContact.phoneNumber}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="cursor-pointer text-danger"
                        onClick={() => onDeleteContact(customerContact, i)}
                      />
                    </td>
                  </tr>
                ))}
              {!customerContacts.length ? (
                <tr>
                  <td colSpan={6}>No contacts added</td>
                </tr>
              ) : null}
              <tr>
                <td colSpan={6}>
                  <Button
                    size="sm"
                    className="rounded"
                    color="success"
                    onClick={() => setCreationMode(true)}
                  >
                    Add Contact
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            className="d-flex align-items-center ml-2"
            color="secondary"
            onClick={onClose}
          >
            Close
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              className="d-flex align-items-center"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomerModal;
