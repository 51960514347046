import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Container } from "reactstrap";
import AdvanceTable from "../../advanceTable/AdvanceTable";
import { sequenceRevisionApi } from "../../../services/sequenceRevisionServices";
import AdvanceTableWrapper from "../../advanceTable/AdvanceTableWrapper";
import AdvanceTablePagination from "../../advanceTable/AdvanceTablePagination";
import { utils } from "../../../utils/utils";
import {
  ACTIONS,
  useSequenceRevisions,
} from "../../../providers/sequenceRevisionsProvider";

const DEFAULT_COLUMNS = [
  {
    id: "jobNumber",
    accessor: "job",
    Header: "Job#",
    headerProps: { className: "text-truncate" },
    width: 100,
    Cell: (rowData) => {
      const { job } = rowData.row.original?.sequence;
      return job?.jobNumber || "-";
    },
  },
  {
    id: "customerName",
    accessor: "job",
    Header: "Customer Name",
    headerProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { job } = rowData.row.original?.sequence;
      return job?.customer?.name || "-";
    },
  },
  {
    id: "description",
    accessor: "description",
    disableSortBy: true,
    Header: "Description",
    headerProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { sequence } = rowData.row.original;
      return sequence?.description || "-";
    },
  },
  {
    id: "revisionDate",
    accessor: "revisionDate",
    Header: "Rev Date",
    headerProps: { className: "text-truncate" },
    width: 130,
    Cell: (rowData) => {
      const { revisionDate } = rowData.row.original;
      return revisionDate ? utils.formatDate(revisionDate) : "-";
    },
  },
  {
    id: "notes",
    accessor: "notes",
    Header: "PM Notes",
    headerProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 250,
    Cell: (rowData) => {
      const { notes } = rowData.row.original;
      return notes || "-";
    },
  },
  {
    accessor: "name",
    Header: "Revision",
    headerProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 250,
    Cell: (rowData) => {
      const { name } = rowData.row.original;
      return name || "-";
    },
  },
];

const SequenceRevisionsTable = ({
  columns = [],
  sequencesTotal,
  setSequencesTotal,
  reloadTabs = [],
  tabName,
}) => {
  const [sequenceRevisionsContext, setSequenceRevisionsContext] =
    useSequenceRevisions();
  const [loading, setLoading] = useState();

  // Load review sequence revisions
  useEffect(() => {
    const loadSequenceRevisions = async () => {
      try {
        if (reloadTabs.includes(tabName)) {
          setLoading(true);
          const revisions = await sequenceRevisionApi.getAllRevisions({
            page: sequenceRevisionsContext.page - 1,
            pageSize: sequenceRevisionsContext.sizePerPage,
            sortBy: sequenceRevisionsContext.sortBy,
            direction: sequenceRevisionsContext.direction,
          });
          await setSequenceRevisionsContext({
            action: ACTIONS.GET_REVISIONS_SUCCESS,
            payload: { revisions },
          });
          setLoading(false);
          setSequencesTotal(revisions.count);
        }
      } catch (err) {
        setLoading(false);
      }
    };
    loadSequenceRevisions();
  }, [
    setSequenceRevisionsContext,
    setSequencesTotal,
    sequenceRevisionsContext.page,
    sequenceRevisionsContext.sizePerPage,
    sequenceRevisionsContext.sortBy,
    sequenceRevisionsContext.direction,
    reloadTabs,
    tabName,
  ]);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          sequenceRevisionsContext.sortBy === sortBy?.id &&
          sequenceRevisionsContext.direction === direction
        ) {
          return;
        }
        setSequenceRevisionsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setSequenceRevisionsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      setSequenceRevisionsContext,
      sequenceRevisionsContext.direction,
      sequenceRevisionsContext.sortBy,
    ]
  );

  return (
    <Container fluid className="px-0">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={[...DEFAULT_COLUMNS, ...columns]}
          data={sequenceRevisionsContext?.revisions?.data || []}
          pageSize={sequenceRevisionsContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: sequenceRevisionsContext.sortBy,
            direction: sequenceRevisionsContext.direction,
          }}
        >
          <Card className="mb-2">
            <CardBody className="col-12 px-0 overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                rowsPerPageOptions={[10, 15, 25, 50, 75, 100]}
                totalCount={sequencesTotal}
                pageCount={sequenceRevisionsContext?.revisions?.totalPages}
                currentPage={sequenceRevisionsContext.page - 1}
                onPageChange={(page) =>
                  setSequenceRevisionsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={sequenceRevisionsContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setSequenceRevisionsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
    </Container>
  );
};

export default SequenceRevisionsTable;
