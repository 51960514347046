import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const initialState = {
  revisions: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  search: "",
  sortBy: "createdAt",
  direction: "desc",
};

const ACTIONS = {
  SORT: "SORT",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_REVISIONS_SUCCESS: "GET_REVISIONS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_REVISIONS_SUCCESS: {
      const { payload } = data;
      const { revisions } = payload;

      return {
        ...state,
        revisions,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    default:
      throw new Error();
  }
};

const SequenceRevisionsContext = createContext(initialState);

const SequenceRevisionsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SequenceRevisionsContext.Provider value={stateAndDispatch}>
      {children}
    </SequenceRevisionsContext.Provider>
  );
};

export const useSequenceRevisions = () => useContext(SequenceRevisionsContext);

export { SequenceRevisionsContext, SequenceRevisionsProvider, ACTIONS, MAX_PAGE_SIZE };
