import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";
import { apiHelper } from "../utils/apiHelper";

const _endpoint = `/api/sequences`;

export const sequenceApi = {
  getAllSequences: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`${_endpoint}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createSequence: (data) => {
    return axios(_endpoint, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "post",
      data
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateSequence: (id, data) => {
    return axios(`${_endpoint}/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      method: "put",
      data
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
