import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useCommonData } from "../../../../providers/commonDataProvider";

import { quoteItemsApi } from "../../../../services/quoteItemServices";
import { utils } from "../../../../utils/utils";
import CustomCheckbox from "../../../CustomCheckbox";

import InformationModal from "../../../InformationModal";

import Loader from "../../../Loader";

const QuoteItemEditOptionsModal = ({
  onClose,
  onSubmit,
  quoteItem,
  title,
  field,
  idField,
}) => {
  const [commonDataContext] = useCommonData();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    const selectedItems = quoteItem[field].reduce((p, c) => {
      p[c[idField]] = true;
      return p;
    }, {});
    setSelectedItems(selectedItems);
  }, [field, quoteItem, idField]);

  const doSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const items = Object.keys(selectedItems).filter((c) => selectedItems[c]);
    try {
      const data = utils.removeArrays(quoteItem);
      await quoteItemsApi.updateQuoteItem({
        ...data,
        [field]: items,
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onItemClick = (item) => {
    setSelectedItems({
      ...selectedItems,
      [item.id]: !selectedItems[item.id],
    });
  };

  const onSelectAllClick = () => {
    const allChecked =
      Object.keys(selectedItems).filter((sc) => selectedItems[sc]).length ===
      commonDataContext[field].length;
    const newSelectedCategories = commonDataContext[field].reduce(
      (p, c) => ({
        ...p,
        [c.id]: !allChecked,
      }),
      {}
    );
    setSelectedItems({
      ...newSelectedCategories,
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true}>
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Edit {title} Options
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <ListGroup>
                  <ListGroupItem className="d-flex justify-content-start align-items-center bg-lighter">
                    <span>{title}</span>
                    <span className="text-danger ml-2">*</span>
                  </ListGroupItem>
                  {commonDataContext[field].length > 1 && (
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => onSelectAllClick()}
                    >
                      <div className="flex-shrink-0">Select all</div>
                      <CustomCheckbox
                        checked={
                          Object.keys(selectedItems).filter(
                            (c) => selectedItems[c]
                          ).length === commonDataContext[field].length
                        }
                      />
                    </ListGroupItem>
                  )}
                  {commonDataContext[field].map((item) => (
                    <ListGroupItem
                      key={item.id}
                      className="d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => onItemClick(item)}
                    >
                      <div className="flex-shrink-0">
                        {item.code
                          ? `${item.code} - ${item.name}`
                          : `${item.name}`}
                      </div>
                      <CustomCheckbox checked={selectedItems[item.id]} />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default QuoteItemEditOptionsModal;
